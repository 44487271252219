@media screen and (max-width: 767px) {
    .header-user-name {
        display: none;
    }

    // .right-nav-buttons button {
    //     display: none;

    // }

    .user-profile {
        display: flex !important;
    }

    .main-tabs {
        position: static !important;
    }

    .truck-list-tabcontainer {
        .MuiAppBar-root {
            height: auto !important;
            flex-wrap: wrap;

            .MuiTabs-root {
                height: auto !important;
                flex-wrap: wrap;

            }
        }
    }

    .vbn-qty {
        margin: 10px 0;
    }

    #google_translate_element {
        * {
            width: 30px;
            overflow: hidden;
        }
    }

    .goog-te-combo {
        width: 30px !important;
        opacity: 0;

    }

    .skiptranslate {
        color: #000 !important;
        font-size: 30px !important;
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        // &::before {
        //     content: "\e949";
        // }
    }

    .main-tabs .MuiTabs-scroller .MuiTabs-flexContainer .tab-items {
        min-width: auto !important;
        padding: 3px 20px !important;
    }

    .stock-outer header {
        align-items: flex-start !important;
        flex-wrap: wrap-reverse !important;

        .searchbar-area {
            margin: 0 !important;
        }
    }

    .dialog-content-area {
        width: 270px !important;
    }

    .vessel-details-item-img {
        height: 80px !important;
    }

    .vbn-noti {
        justify-content: space-between !important;
    }

    .vbn-qty {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .vbn-opt {
        margin-top: 16px !important;
    }

    .vbn-options {
        margin: 0 !important;
        width: 100% !important;
    }

    .vbn-info>span {
        margin: 0 30px 0 0 !important;
    }

    .vbn-info div {
        width: 100% !important;
    }

    .trade-box {
        border-right: none !important;
    }

    .trade-value {
        background-color: #F8F9FA;
        padding: 10px;
        border-radius: 10px;
    }

    .company-profile-section {

        padding-bottom: 0;
    }
    .custom-card-ftr{
        position: static;
    }
}