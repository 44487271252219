.p-datepicker {
    border-radius: 10px;
    z-index: 1300 !important;

    .p-datepicker-group-container {
        .p-datepicker-header {

            .p-datepicker-prev,
            .p-datepicker-next {
                background-color: #f3f3f3;
                border-radius: 4px;

                &:hover {
                    background-color: #e3e3e3;
                }

                &:focus {
                    box-shadow: none;
                }

                span {
                    color: $mainDarkBlue;
                }
            }

            .p-datepicker-title {
                .p-datepicker-month {
                    padding: 5px 8px;
                    border: 1px solid #F3F3F3;
                    border-radius: 4px;
                    margin: 0 5px;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .p-datepicker-year {
                    padding: 5px 8px;
                    border: 1px solid #F3F3F3;
                    border-radius: 4px;
                    margin: 0 5px;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .p-datepicker-calendar-container {
            table {
                margin: 0;
                td {
                    padding: 0px;
                    span{
                        width: 38px;
                        height: 38px;
                    }

                    &.p-datepicker-today>span {
                        background-color: transparent !important;
                       

                        // position: relative;
                        &::after {
                            content: '';
                            position: absolute;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background-color: $mainDarkBlue;
                            bottom: -2px;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                    }

                    &>span.p-highlight {
                        background-color: $mainDarkBlue !important;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background-color: #f3f3f3 !important;
    color: $mainDarkBlue;
    border-radius: 4px;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    box-shadow: none;
    background-color: $mainDarkBlue !important;
    color: #fff;
}
.p-datepicker table td > span:focus{
    box-shadow: none;
}