.main-container-section {
  .MuiTabs-fixed {
    display: flex;
    align-items: center;
  }

  .MuiTab-root {
    min-width: 160px;
    min-height: auto;

    span {
      color: #03256c !important;
      font-weight: 500;
    }

    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);

    background: rgba(6, 190, 225, 0.1) !important;

    &.Mui-selected {
      span {
        color: #fff !important;
      }

      clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
      background: #1768ac !important;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .section-header {
    font-size: 22px;
    font-weight: 600;
  }

  .p-buttonset {
    .p-component:nth-child(2) {
      border-left: none;
      border-right: none;
    }
  }

  .container-body {
    background: #ffffff;
    border-radius: 10px;
    height: calc(100vh - 137px);
    overflow: auto;
    padding: 15px;
    margin-top: 10px;

    .custom-input-file-button {
      margin-top: 13px !important;

      &::before {
        border: none !important;
        border-bottom: 1px solid #B0B3C8 !important;
        border-radius: 0 !important;
      }
    }

    .list-card {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      border-radius: 11px;
      padding: 20px;
      margin-bottom: 25px;
      position: relative;

      .container-number {
        position: absolute;
        left: 20px;
        top: -15px;
        height: 30px;
        border-radius: 6px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        background-color: #2541b2;
      }



      .vbn-info {
        span {
          margin-left: 0;
          margin-right: 20px;
        }
      }

      .vbn-footer {
        .user-img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          flex-shrink: 0;
        }
      }
    }
  }

  .vbn-info div {
    width: auto;
  }

  .p-buttonset {
    .p-dropdown {
      width: 175px;
      border-radius: 6px;
      background: none;
      border: 1px solid #03256c;
    }
  }
}

.PrivateTabIndicator-colorSecondary-122  {
background-color: #06bee1 !important;
}

.ac-header {
  font-weight: 500;
  color: #000;
}

.conatiner-no-tag {
  background: #e0f3f8;
  border-radius: 20px;
  color: #3a588f;
  padding: 5px 12px;
  display: inline-block;
}

.conatiner-tab-body {

  // height: calc(100% - 150px);
  // overflow: auto;
  .p-accordion {
    .p-accordion-tab {
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 15px;

      .p-disabled {
        opacity: 1 !important;

        .p-accordion-header-link {
          background: transparent !important;
          border: none !important;
          flex-direction: row-reverse;
          padding: 0;
        }

        .p-accordion-toggle-icon {
          cursor: not-allowed !important;

          &::before {
            color: #bebebe;
          }
        }
      }
    }

    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
      background: transparent !important;
      border: none !important;
      flex-direction: row-reverse;
      padding: 0;
      color: #000;

      &:focus {
        box-shadow: none !important;
      }
    }

    .p-accordion-header:not(.p-highlight):not(.p-disabled) .p-accordion-header-link {
      background: transparent !important;
      border: none !important;
      flex-direction: row-reverse;
      padding: 0;

      &:focus {
        box-shadow: none !important;
      }
    }

    .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
      background-color: #f3f3f3;
      color: #03256c;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      margin-right: 0px;
      flex-shrink: 0;
    }

    .p-accordion-header-text {
      width: 100%;
      font-weight: normal;
    }

    .truck-status {
      color: #343434;
      background-color: rgba(255, 153, 0, 0.3);
      border-radius: 6px;
      padding: 7px 15px;
    }

    .weight-diff {
      padding: 7px 20px;
      border-radius: 25px;
      background-color: rgba(244, 67, 54, 0.3);
      color: #f44336;
    }

    .p-accordion-content {
      border: none;
      padding: 20px 0 0px;
      border-top: 1px solid #e5e5e5 !important;
      margin-top: 15px;

      .trip-user-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
        margin-right: 20px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .driver-info-area {
        margin-bottom: 16px;

        .driver-info-label {
          color: #858585;
          margin-bottom: 10px;
        }

        .driver-info-detail {
          color: #202124;
          // margin-bottom: 10px;
        }

        .weight-paper {
          background-color: #eae8ff;
          border-radius: 5px;
          padding: 7px;
          font-size: 13px;

          .wp-icon {
            width: 20px;
            height: 20px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }

          label {
            cursor: pointer;

            input {
              display: none;
            }
          }
        }
      }
    }
  }

  .border-ac-left-green {
    .p-accordion-tab {
      border-left: 10px solid #2ecb71 !important;
    }
  }

  .border-ac-left-orange {
    .p-accordion-tab {
      border-left: 10px solid #f1b13c !important;
    }
  }

  .border-ac-left-red {
    .p-accordion-tab {
      border-left: 10px solid #ff6257 !important;
    }
  }
}

.add-edit-container .container-body {
  height: calc(100vh - 162px);
}

.Container-dashboard {
  height: calc(100vh - 104px);
  overflow: auto;
  margin-top: 11.5px;

  .cotainer-card {
    margin-bottom: 15px;
    background: #ffffff;
    box-shadow: 0px 0px 31px 2px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    width: 100%;
    padding: 20px;

    .section-header {
      font-size: 20px;
      font-weight: 600;
    }

    .ship-pdf {
      cursor: pointer;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #03256c;
      border-radius: 5px;

      i {
        font-size: 25px;
      }
    }

    .searchbar-area input {
      width: 120px;
    }

    .icon-card-filter {
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
    }

  }

  .shipment-list {
    .shipment-each-list {
      display: flex;
      border-bottom: 1px solid #dadbd8;
      padding: 10px 0;
      gap: 10px;

      .shipment-icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background: #f5f5f5;
        border-radius: 6px;

        i {
          font-size: 30px;
        }
      }

      .shipment-details {
        h3 {
          margin: 0;
          font-weight: 600;
          font-size: 16px;
        }

        div {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #343434;
          margin: 4px 0;
        }
      }

    }
  }

  .container-dashboard-button {
    display: flex;
    justify-content: space-between;
    width: 250px;

    .container-dashboard-delete {
      border: 1px solid #e10606 !important;
      border-radius: 4px;
      padding: 4px 30px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.list-card .tag-list {
  display: inline-flex;
  gap: 10px;
  width: auto;

  div {
    border-radius: 3px;
    padding: 5px;
    width: auto;
    color: #fff;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
  }

  .tag-1 {
    background: #2541b2;
  }

  .tag-2 {
    background: #ba68c8;
  }

  .tag-3 {
    background: #0094b0;
  }
}

.shipment-checkbox {
  label.MuiFormControlLabel-root {
    margin: 0 !important;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    padding: 0;
    color: $mainDarkBlue;
  }

  .MuiCheckbox-root {
    padding: 0 !important;
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
  }
}

/*-- Shipment Detail --*/

.shipment-detail {
  height: calc(100vh - 137px);

  h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 0px;
    /* identical to box height */
    padding-left: 5px;
    padding-bottom: 20px;
    color: #202124;
  }

  .main-detail {
    padding-bottom: 5px;

    .main-detail-label {
      padding-bottom: 2px;

      label {
        font-family: 'Roboto';
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;

      }
    }

    .main-detail-value {
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        padding-top: 5px;
        color: #202124;

      }
    }
  }
}

.shipping-info {
  background: #F8F9FD;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
}

.container-info {
  box-sizing: border-box;
  border: 1px solid #B0B3C8;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px 10px;

  .container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      padding-bottom: 0px;
      padding-left: 0px;
    }
  }

  .vessel-code {
    background: rgba(23, 104, 172, 0.22);
    border-radius: 4px;

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      /* identical to box height */
      padding: 10px 5px;
      text-align: center;
      color: #03256C;

    }
  }
}

.attachment-info {
  box-sizing: border-box;
  padding: 20px 0px;

  .attachment-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
      padding-bottom: 0px;
      padding-left: 0px;
    }
  }

  .attachment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .attachment-box {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #03256C;
    color: #03256C;
    border-radius: 5px;
    padding: 20px;
    width: 65%;
    text-align: center;

    i {
      font-size: 55px;
    }
  }

  .attachment-box-img {
    box-sizing: border-box;
    border: 1px solid #03256C;
    border-radius: 5px;
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .attachment-name {
    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */
      margin-top: 10px;
      color: #415AA9;
    }
  }
}

.vessel-text {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #3A588F !important;

  span {
    font-weight: 600 !important;
  }
}

.container-list {
  .container-list-item {
    background: #F9F9F9;
    border-radius: 4px;
    margin-bottom: 15px;
    width: 96% !important;
    position: relative;

    .container-details {
      position: relative;
      z-index: 1;
    }

    .container-title {
      display: flex;
      // align-items: center;
      padding: 3px 20px;

      i {
        font-size: 21px;
        color: #06bee1;
      }

      .container-no,
      .vehicle-no,
      .driver-name {
        padding-left: 9px;
      }
    }

    .container-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 24px;
        color: #03256C;
      }
    }

    .weight-icon {
      i {
        font-size: 18px;
        color: #03256c;
      }
    }

    .weight-text {
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        margin-left: 5px;
        color: #03256C;

      }
    }

    .transportation-type {
      background: #06bee1;
      border-radius: 30px;
      padding: 3px 17px;
      margin-top: -6px;
      display: flex;

      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #FFFFFF;
      }
    }

    .card-svg {
      position: absolute;
      top: 0;
      right: 0;

      svg {
        height: 175px;
        width: 155px;
      }
    }

    .container-title-main {
      padding: 15px 10px 0px 10px;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    color: #03256C;
    z-index: 1;
    font-size: 26px;
  }

  .slick-prev {
    left: -11px;
    z-index: 1;
  }

  .slick-next {
    right: 7px;
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: #03256C;
  }

  .slick-dots {
    bottom: -14px;
  }

  .container-no {
    label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      display: flex;
      align-items: center;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: #202124;
    }
  }

  .vehicle-no {
    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */


      color: #000000;

    }
  }
}

.shipment-sidebar {
  box-sizing: border-box;

  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 20px;

  .booking-ref {
    background: #F8F9FD;
    border-radius: 5px;
    padding: 15px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      font-size: 17px;
      color: #03256C;
    }

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;

      color: #343434;
    }

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;

      color: #7A7878;
    }
  }

  .invoice-box {
    display: flex;
    align-items: center;
    // margin-top: 20px;

    .invoice-detail {
      padding-left: 12px;

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        color: #202124;
      }
    }
  }

  .invoice-date {
    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin: 0;

      color: #202124;
    }
  }

  .invoice-box-empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 80px;
      color: #03256C;
    }

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin: 0;
      width: 70%;
      color: #202124;
    }
  }

  .shipping-box-empty {
    i {
      color: #F09A4A;
    }
  }

  .invoice-sub-detail {
    margin-top: 13px;

    .sub-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 6px;

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        color: #202124;
      }
    }
  }

  .bl-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bl-info {
      display: flex;
      align-items: center;
    }

    .bl-detail {
      padding-left: 12px;

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #202124;
      }
    }

    .bl-type {
      background: #F44336;
      border-radius: 30px;
      padding: 3px 17px;
      align-self: start;

      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        color: #FFFFFF;
      }
    }
  }

  .attachment-area {
    label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: #7A7878;
      padding: 10px 0px 0px 0px;
    }

    // .attachment-list {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;

    .attachment-item {
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
      border-radius: 4px;
      margin-top: 10px;

      img {
        width: 80px;
        height: 80px;
      }
    }

    // }
  }
}

.color-box {
  border-radius: 8px;
  padding: 8px;
  width: 70%;
  border: none;
}

.dropdown-job {

  .black {
    .p-dropdown-label {
      color: #161616;
      font-size: 16px;
      padding-left: 15px;
      width: 70px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    .p-dropdown-trigger-icon {
      color: #161616;
      font-size: 12px;
    }
  }

  .white {
    .p-dropdown-label {
      color: #FFFFFF;
      font-size: 16px;
      padding-left: 15px;
      width: 70px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    .p-dropdown-trigger-icon {
      color: #FFFFFF;
      font-size: 12px;
    }
  }

  .p-dropdown {
    border-radius: 4px;
    // border: none;
    width: 100px;

    .p-dropdown-trigger {
      width: 0px;
      padding-right: 20px;
    }
  }
}

.dashboard-check {
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background: transparent;
  }

  .MuiCheckbox-colorSecondary:hover {
    background: transparent;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #2541b2;
  }
}

.staff-title-badge {
  position: absolute;
  left: 80px;
  top: -15px;
  height: 30px;
  border-radius: 6px !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  width: fit-content !important;
  /* background-color: #2541b2; */

}

.card-count {
  background: #273A3D;
  border-radius: 6px;
  // padding: 12px 15px;
  color: #FFFFFF;

  p,
  a {
    color: #FFFFFF;
    margin: 0;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    text-decoration: none;
    white-space: nowrap;
    padding: 12px 15px;
  }

  .count {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 10px;
    padding: 2px 15px;
  }

  .view-footer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    box-shadow: 0px 30px 75px #FFFFFF;

    i {
      margin-left: 8px;
    }

    a {
      text-decoration: none;
    }

    a:hover {
      color: #FFFFFF;
    }
  }
}

.export-job-dashboard {
  height: calc(100vh - 99px);
  overflow: auto;
}

.export-job-track-table {
  .p-datatable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  .p-datatable-scrollable-header {
    background: none;
  }

  .p-datatable-scrollable-body {
    overflow-y: auto !important;
  }

  .main-table table th {
    min-width: unset !important;
    width: unset !important;
  }

  .main-table {
    padding: 10px 5px !important;

    thead {
      // border-bottom: none;

      th {

        // font-family: 'Roboto' !important;
        // font-style: normal !important;
        // font-weight: 400 !important;
        // font-size: 18px !important;
        // line-height: 21px !important;
        // /* identical to box height */

        // color: #7A7878 !important;
        // // width: 250px !important;
        // padding: 0 !important;

      }

      th:nth-child(1) {
        width: 20% !important;
      }

      th:nth-child(2) {
        width: 20% !important;
      }

      th:nth-child(3) {
        width: 40% !important;
      }

      th:nth-child(4) {
        width: 35% !important;
      }

      th:nth-child(5) {
        width: 22% !important;
      }

      th:nth-child(6) {
        width: 22% !important;
      }

      th:nth-child(7) {
        width: 6% !important;
      }

      th:nth-child(8) {
        width: 8% !important;
      }

      th:nth-child(9) {
        width: 10% !important;
      }

      th:nth-child(10) {
        width: 7% !important;
      }

      th:nth-child(11) {
        width: 10% !important;
      }
    }

    tbody {
      tr {
        td {
          white-space: unset;
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }

        td:nth-child(1) {
          width: 20% !important;
        }

        td:nth-child(2) {
          width: 20% !important;
        }

        td:nth-child(3) {
          width: 40% !important;
        }

        td:nth-child(4) {
          width: 35% !important;
        }

        td:nth-child(5) {
          width: 22% !important;
        }

        td:nth-child(6) {
          width: 22% !important;
        }

        td:nth-child(7) {
          width: 6% !important;
        }

        td:nth-child(8) {
          width: 8% !important;
        }

        td:nth-child(9) {
          width: 10% !important;
        }

        td:nth-child(10) {
          width: 7% !important;
        }

        td:nth-child(11) {
          width: 10% !important;
        }

        // td {
        //     font-family: 'Roboto' !important;
        //     font-style: normal !important;
        //     font-weight: 500 !important;
        //     font-size: 18px !important;
        //     line-height: 21px !important;
        //     /* identical to box height */

        //     color: #202124 !important;
        //     // width: 250px !important;
        //     border: none !important;
        // }

        // td:not(:first-child) {
        //     padding: 5px !important;
        // }

        // td:first-child {
        //     padding-left: 10px !important;
        //     padding-right: 0;
        //     padding-top: 5px !important;
        // }
      }

      // tr:hover {
      //     background: #F3F3F3;
      // }
    }
  }
}

.export-job-heading {
  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    margin: 0;

    color: #343434;
  }
}

.export-job-container {
  background: #FFFFFF;
  box-shadow: 0px 0px 31px 2px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  margin-top: 10px;
  padding: 15px;



  .export-job-track-chart {
    // canvas {
    //   content: '';
    //   width: inherit;
    //   height: inherit;
    //   border: 45px solid rgba(211,211,211,.3);
    //   border-bottom: none;
    //   border-top-left-radius: 175px;
    //   border-top-right-radius: 175px;
    // }
  }

  .export-job-stats-title {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      // white-space: nowrap;
      letter-spacing: 0.02em;
      margin: 0;
      color: #0A0A1F;
    }
  }

  .circle-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f1b13c;
  }

  .export-job-stats-total,
  .export-job-stats-si,
  .export-job-stats-gate,
  .export-job-stats-delete {
    background: #FCCEBF;
    border-radius: 8px;
    position: relative;
    margin-top: 12px;
    padding: 12px 17px;
    width: 100%;

   

    .export-job-stats-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;



      .latest-badge {
        padding: 2px 15px;
        background: #F56B3F;
        border-radius: 20px;

        span {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          margin: 0;
          letter-spacing: 0.01em;

          color: #FFFFFF;

        }
      }
    }

    .export-job-count {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 20px;

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        margin: 0;
        letter-spacing: 0.02em;

        color: #0A0A1F;
      }

      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        margin: 0;

        letter-spacing: 0.02em;

        color: #2DC433;

        opacity: 0.5;
      }
    }

    .export-job-count-label {
      margin-top: 5px;

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin: 0;

        letter-spacing: 0.02em;

        color: #0A0A1F;

        opacity: 0.5;
      }
    }

    .view-more {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 20px;

      a {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height */
        text-decoration: none;
        letter-spacing: 0.02em;

        color: #4F5678;
      }

      i {
        color: #09245b;
        font-size: 12px;
      }
    }
  }

  .export-job-stats-gate {
    background: rgba(228, 235, 248, 0.36);
  }

  .export-job-stats-si {
    background: rgba(228, 235, 248, 0.36);
  }

  .export-job-stats-delete {
    background: #FCCEBF;
  }

  .export-job-stats-total {
    background: rgba(228, 235, 248, 0.36);
  }

  .stats-card-icon {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .stats-card-optional {
    right: 20px;
    bottom: 15px;
  }

  .stats-card-si {
    right: 20px;
    bottom: 0;
  }

  .export-job-table {
    .row-color-red {
      background: #FDC5C5;
      border-left: 5px solid #FF4B4B;
    }

    .row-color-orange {
      background: #FFF3DC;
      border-left: 5px solid #F1B13C;
    }

    .row-color-green {
      background: #BAFDD7;
      border-left: 5px solid #2ECB71;
    }
  }
}

.color-orange {
  color: #F1B13C !important;
}

// .draft-logo {
//   width: 200px;
//   height: 200px;
//   border-radius: 50%;
//   border: none;
// }

// .bl-draft-edit-icon {
//   position: relative;
// }

// .icon-editImage {
//   padding: 10px 10px;
//   background-color: #000000;
//   color: #FFFFFF;
//   border-radius: 50%;
//   font-size: 16px;
//   position: absolute;
//     top: -40px;
//     left: 137px;
// }
.BLDraft-table {
  box-sizing: border-box;

  border: 1px solid #7A7878;
  border-radius: 6px;

  .table-add-btn {
    height: 25px;
    width: 25px;

    i {
      font-size: 13px;
    }
  }

  .p-datatable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  .main-table table th {
    min-width: unset !important;
    width: unset !important;
  }

  .main-table {


    thead {
      border-bottom: none;

      th {

        font-family: 'Roboto' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 21px !important;
        /* identical to box height */

        color: #7A7878 !important;
        // width: 250px !important;
        padding: 0 !important;

      }

      th:nth-child(1) {
        width: 12% !important;
      }

      th:nth-child(2) {
        width: 30% !important;
      }

      th:nth-child(3) {
        width: 10% !important;
      }

      th:nth-child(4) {
        width: 11% !important;
      }

      th:nth-child(5) {
        width: 12% !important;
      }

      th:nth-child(6) {
        width: 6% !important;
      }
    }

    tbody {
      tr {
        td {
          font-family: 'Roboto' !important;
          font-style: normal !important;
          font-weight: 500 !important;
          font-size: 18px !important;
          line-height: 21px !important;
          /* identical to box height */

          color: #202124 !important;
          // width: 250px !important;
          border: none !important;
        }

        td:not(:first-child) {
          padding: 5px !important;
        }

        td:first-child {
          padding-left: 10px !important;
          padding-right: 0;
          padding-top: 5px !important;
        }
      }

      // tr:hover {
      //     background: #F3F3F3;
      // }
    }
  }



}

// // / /new deshboard desi

// .container-body {
//   .card {
//     width: 100%;
//     background: #ffffff;
//     border: 1px solid #E9F1FD;
//     box-shadow: 0px 4px 31px 2px rgba(0, 0, 0, 0.02);
//     border-radius: 10px 10px 0px 0px;
//     margin-top: 22px;
//     position: relative;

//     .job-number {
//       background: linear-gradient(83.45deg, #1768AC -16.14%, #1768AC 91.57%);
//       border-radius: 4px;
//       position: absolute;
//       top: -15px;
//       left: 24px;
//       height: 30px;
//     }

//     .job-number p {
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 500;
//       font-size: 14px;
//       line-height: 16px;
//       // display: flex;
//       // align-items: center;
//       // text-align: center;
//       padding: 8px 10px;
//       color: #FFFFFF;

//     }

//     .vbn-info {
//       width: 54%;
//       margin-top: 33px;
//       margin-right: -130px;
//     }

//     .span-icon {
//       margin: 0px;
//       margin-left: 22px;
//       color: #1768AC;
//     }

//     .complete-button span {
//       margin: 0px !important;
//     }

//     .complete-button {
//       display: flex;
//       justify-content: end;
//       margin-right: 54px;

//     }

//     .vesselName {
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 500;
//       font-size: 18px;
//       line-height: 21px;
//       /* identical to box height */

//       // display: flex;
//       // align-items: center;

//       color: #202124;
//     }

//     .p-text-truncate {
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 16px;
//       display: flex;
//       align-items: center;
//       text-align: center;

//       color: #3A588F;
//     }

//     .vesselUserName {
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 500;
//       font-size: 15px;
//       line-height: 18px;
//       /* identical to box height */

//       display: flex;
//       align-items: center;

//       color: #343434;
//     }

//     .completed-btn {
//       background: #2ECB71;
//       border-radius: 4px;
//       padding: 10px 8px 9px 8px;
//       color: #ffffff;
//       margin-left: 16px;
//     }


//     button.clearingButtons {
//       background: #2541B2;
//       border-radius: 3px;
//       color: #ffffff;
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 400;
//       font-size: 10px;
//       // line-height: 12px;
//       // display: flex;
//       // align-items: center;
//       // text-align: center;
//       // text-transform: capitalize;
//       border: none;
//       padding: 4px 10px 4px 10px;
//     }

//     button.forwardingButtons {
//       background: #BA68C8;
//       border-radius: 3px;
//       color: #ffffff;
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 400;
//       font-size: 10px;
//       border: none;
//       padding: 4px 10px 4px 10px;
//       margin-left: 6px;
//     }

//     button.transportationButtons {
//       background: #0094B0;
//       border-radius: 3px;
//       color: #ffffff;
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 400;
//       font-size: 10px;
//       border: none;
//       padding: 4px 10px 4px 10px;
//       margin-left: 6px;
//     }

//     button.clearingLastButtons {
//       background: #4F5678;
//       border-radius: 3px;
//       color: #ffffff;
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 400;
//       font-size: 10px;
//       border: none;
//       padding: 4px 10px 4px 10px;
//       margin-left: 6px;
//     }

//     .gate-cutoff-text p {
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 400;
//       font-size: 15px;
//       line-height: 18px;
//       /* identical to box height */

//       display: flex;
//       align-items: center;


//       color: #3A588F;
//     }

//     .completed-btn p {
//       color: #ffffff;
//     }

//     i.icon-correct {
//       background: #03256C;
//       color: #ffffff;
//       border-radius: 3px;
//       padding: 5px 4px 5px 4px;
//     }

//     p.p-pl-2 {
//       font-family: 'Roboto';
//       font-style: normal;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 16px;
//       display: flex;
//       align-items: center;
//       text-align: center;
//       color: #3A588F;
//     }

//     .action-staff-btn {
//       // padding: 8px 7px 8px 7px;
//      height: 28px;
//      border: none;
//      width: 28px;
//       background: #5B729D;
//       border-radius: 50px;
//       color: #ffffff;
//       margin-left: 8px;
//     }

//     .port-address {
//       margin-left: 63px;
//     }

//     .icons {
//       margin-left: 98px;
//     }

//     .icon-addressContainer {
//       background: #E9F1FD;
//       // box-shadow: 0px 4px 31px 2px rgb(0 0 0 / 2%);

//       padding: 17px 0px 5px 26px;
//       margin-top: 17px;


//       // align-items: center;
//     }

//   }



// }

.container-body {
  padding: 0px !important;

  .infinite-scroll-component {
    padding: 17px;
  }

  .number-identity{
      position: absolute;
      top: -15px;
      left: 15px;
    }
  
    .jobNumber {
      padding: 5px 10px;
      background: linear-gradient(84.55deg, #1768AC 1.82%, #1768AC 92.87%);
      border-radius: 4px;
     
  
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        text-align: center;
        color: #FFFFFF;
        margin: 0;
      }
    }
  
    .booking-badge {
      background-color: #3a588f;
      border-radius: 4px;
      padding-inline: 12px;
      padding-block: 5px;
      // position: absolute;
      // top: -15px;
      // left: 50px;
  
      p {
        color: #FFFFFF;
        margin: 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
      }
    }
  
  .user-profile {
    img {
      width: 40px;
    }
  }

  .container-item {
    margin-bottom: 25px;
  }

  .container-header {
    background: #FFFFFF;
    border: 1px solid #E9F1FD;
    box-shadow: 0px 4px 31px 2px rgba(0, 0, 0, 0.02);
    border-radius: 10px 10px 0px 0px;
    padding: 14px 15px;
    padding-top: 20px;
  }

  .container-header-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .icon-box {
    background: #F8F9FA;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    i {
      color: #1768AC;
      font-size: 20px;

    }
  }

  .item-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    margin: 0;
    color: #202124;
  }

  .item-subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
    color: #3A588F;
    // max-width: 85%;
  }

  .icon-action-box {
    width: 32px;
    height: 35px;
    background: #F3F3F3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #03256C;
      font-size: 16px;
    }
  }

  .attachment-count {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    background: #ff0000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      margin: 0;
      color: #FFFFFF;
    }
  }

  .service-badge {
    background: #2541B2;
    border-radius: 3px;
    padding: 0px 9px;

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      margin: 0px;
      text-align: center;
      text-transform: capitalize;

      color: #FFFFFF;

    }
  }

  .forwarding {
    background: #BA68C8;
  }

  .transportation {
    background: #0094B0;
  }

  .nomination {
    background: #4F5678;
  }

  .gap-7 {
    gap: 7px;
  }

  .dropdown-job{
    .p-dropdown{
      width: 100%;
      border: #B0B0B0;
    }
    .p-dropdown-label{
      width: 100%;
      font-size: 14px;
      padding-inline: 12px;
      padding-block: 10px;
    }
    // .p-dropdown-trigger{
    //   padding-inline: 15px;
    // }
  }

  .icon-pending-clock {
    font-size: 16px;
    color: #1768AC;
  }

  .footer-item {
    i.icon-info {
      font-size: 14px;
      color: #1768AC
    }

    i.icon-Right-arrow {
      font-size: 10px;
      color: #1768AC
    }

    i.icon-location-fill {
      font-size: 14px;
      color: #1768AC
    }

    
  }

  .container-footer {
    background: #E9F1FD;
    box-shadow: 0px 4px 31px 2px rgba(0, 0, 0, 0.02);
    border-radius: 0px 0px 10px 10px;
    padding: 8px 24px;
  }

  .action-export-item {
    width: 30px;
    height: 30px;
    background: #1768AC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      color: #FFFFFF;
    }
  }

  .action-export-item:hover {
    background: #1768AC2f;

    i {
      color: #161616;
    }
  }

  .w-33 {
    width: 33.33%;
  }
}

.track-switch {
  .p-inputswitch {
    height: 25px;
    width: 50px !important;
  }

  .p-inputswitch-slider {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 12px 25px;
  }

  .p-inputswitch-slider::before {
    background-color: #FFFFFF;
    top: 4.5px;
    margin: 0;
    left: 6px;
    height: 15px;
    width: 15px;
  }

  .p-inputswitch-checked .p-inputswitch-slider:before {
    top: 4.5px;
    left: 10px !important;
  }
}

.track-card-view {
  // background-color: rgba(23, 104, 172, 0.08);
  background: rgba(203, 215, 255, 0.15);
  border-left: 6px solid #2541B2;

  // box-shadow: 0px 8px 24px rgba(22, 22, 22, 0.05);
  padding-inline: 15px;
  padding-block: 12px;
  border-radius: 6px;
  margin-top: 10px;


  .p-avatar {
    width: 50px;
    height: 50px;
    background: #4A6497;
    color: #FFFFFF;
  }

  .user-profile {
    display: flex;
    align-items: center;
    gap: 8px;

    .p-avatar-text {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .user-detail {
    .username {
      font-family: 'Roboto';
      font-style: normal;
      font-size: 16px;
      margin: 0;
      font-weight: 500;
      color: #202124;
      // line-height: 18px;
    }

    .user-phone {
      font-family: 'Roboto';
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      // line-height: 18px;
      padding-top: 4px;
      margin: 0;
      color: #7A7878;
    }
  }

  .location-info {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-inline: 10px;
    padding-block: 5px;
    margin-top: 15px;
    // margin-left: 5px;
    background-color: rgba(23, 104, 172, 0.08);
    border-radius: 8px;

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-size: 14px;
      // line-height: 18px;
      // padding-top: 4px;
      font-weight: 400;
      margin: 0;
      color: #343434;
    }
  }

  .track-time-badge span {
    background: #424A65;
    padding-inline: 15px;
    padding-block: 2px;
    border-radius: 25px;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
  }


}

.track-map {
  position: relative;
  width: 100%;
  height: calc(100vh - 150px);
}

.attendance-container {
  .p-tabview .p-tabview-panels {
    background: transparent;
    padding: 0px;
    border: none;
  }
}

// .ShiftContainer {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   border-radius: 8px;
//   padding: 12px 17px;
//   margin: 10px 0px;

//   width: 100%;
//   border-left: 6px solid red;
//   background: rgba(228, 235, 248, 0.36);

//   .ShiftName {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 30px;
//     color: #202124;

//   }

//   .Time {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 13px;
//     line-height: 30px;
//     color: #03256C;
//   }

//   .ShiftStart-time {
//     display: flex;
//     gap: 30px;
//   }

//   .ShiftIcon-container {
//     margin: 0px 10px;
//     display: flex;
//     align-items: flex-start;

//     .icon {
//       padding: 8px 8px;
//       margin-right: 6px;
//       background-color: #F1F1F1;
//       border-radius: 4px;
//     }
//   }
// }

// ul.p-tabview-nav {
//   margin: 10px 0px;

// }

// .button-container {
//   text-align: end;

//   .button {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 23px;
//     background-color: #282d6a;
//     padding: 10px;
//   }
// }

// .AttendanceTable-container {

//   .attendance {
//     color: #202124;
//     width: 100px;
//     border: none;
//     background: none;
//     border-spacing: 0;

//   }

//   .mainBody {
//     color: #202124;
//     width: 210px;
//     border: none;
//     background: none;


//   }
// }

.export-job-attendance-table {
  .p-datatable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: unset;
    overflow-x: auto;
  }

  .p-datatable-scrollable-header {
    background: none;
  }

  .p-datatable-scrollable-body {
    overflow-y: auto !important;
  }

  .main-table table th {
    // min-width: unset !important;
    width: unset !important;
    max-width: 100% !important;
  }

  .main-table {
    padding: 10px 5px !important;

    thead {
      // border-bottom: none;

      th {

        // font-family: 'Roboto' !important;
        // font-style: normal !important;
        // font-weight: 400 !important;
        // font-size: 18px !important;
        // line-height: 21px !important;
        // /* identical to box height */

        // color: #7A7878 !important;
        // // width: 250px !important;
        // padding: 0 !important;

      }

      th:nth-child(1) {
        min-width: 170px !important;
      }

      th:nth-child(2) {
        min-width: 250px !important;
      }

      th:nth-child(3) {
        min-width: 250px !important;
      }

      th:nth-child(4) {
        min-width: 200px !important;
      }

      th:nth-child(5) {
        min-width: 250px !important;
      }

      th:nth-child(6) {
        min-width: 200px !important;
      }

      // th:nth-child(7) {
      //   min-width: 6% !important;
      // }

      // th:nth-child(8) {
      //   min-width: 8% !important;
      // }

      // th:nth-child(9) {
      //   min-width:10% !important;
      // }

      // th:nth-child(10) {
      //   min-width:7% !important;
      // }

      // th:nth-child(11) {
      //   width: 10% !important;
      // }
    }

    tbody {
      tr {
        td {
          white-space: unset;
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }

        td:nth-child(1) {
          min-width: 170px !important;
        }
  
        td:nth-child(2) {
          min-width: 250px !important;
        }
  
        td:nth-child(3) {
          min-width: 250px !important;
        }
  
        td:nth-child(4) {
          min-width: 200px !important;
        }
  
        td:nth-child(5) {
          min-width: 250px !important;
        }
  
        td:nth-child(6) {
          min-width: 200px !important;
        }

        // td:nth-child(7) {
        //   width: 6% !important;
        // }

        // td:nth-child(8) {
        //   width: 8% !important;
        // }

        // td:nth-child(9) {
        //   width: 10% !important;
        // }

        // td:nth-child(10) {
        //   width: 7% !important;
        // }

        // td:nth-child(11) {
        //   width: 10% !important;
        // }

        // td {
        //     font-family: 'Roboto' !important;
        //     font-style: normal !important;
        //     font-weight: 500 !important;
        //     font-size: 18px !important;
        //     line-height: 21px !important;
        //     /* identical to box height */

        //     color: #202124 !important;
        //     // width: 250px !important;
        //     border: none !important;
        // }

        // td:not(:first-child) {
        //     padding: 5px !important;
        // }

        // td:first-child {
        //     padding-left: 10px !important;
        //     padding-right: 0;
        //     padding-top: 5px !important;
        // }
      }

      // tr:hover {
      //     background: #F3F3F3;
      // }
    }
  }
}

.attendance-status-table {
  margin-top: 10px;
  .p-datatable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: unset;
    overflow-x: auto;
  }

  .p-datatable-scrollable-header {
    background: none;
  }

  .p-datatable-scrollable-body {
    overflow-y: auto !important;
  }

  .main-table table th {
    // min-width: unset !important;
    width: unset !important;
    max-width: 100% !important;
  }

  .main-table table th span{
    font-weight: 600;
  }

  .main-table {
    padding: 0px !important;

    thead {
      // border-bottom: none;

      th {

        // font-family: 'Roboto' !important;
        // font-style: normal !important;
        // font-weight: 400 !important;
        // font-size: 18px !important;
        // line-height: 21px !important;
        // /* identical to box height */

        // color: #7A7878 !important;
        // // width: 250px !important;
        // padding: 0 !important;
        min-width: 180px !important;
      }

      th:nth-child(1) {
        min-width: 200px !important;
      }

      // th:nth-child(2) {
      //   min-width: 180px !important;
      // }

      // th:nth-child(3) {
      //   min-width: 250px !important;
      // }

      // th:nth-child(4) {
      //   min-width: 200px !important;
      // }

      // th:nth-child(5) {
      //   min-width: 250px !important;
      // }

      // th:nth-child(6) {
      //   min-width: 200px !important;
      // }

      // th:nth-child(7) {
      //   min-width: 6% !important;
      // }

      // th:nth-child(8) {
      //   min-width: 8% !important;
      // }

      // th:nth-child(9) {
      //   min-width:10% !important;
      // }

      // th:nth-child(10) {
      //   min-width:7% !important;
      // }

      // th:nth-child(11) {
      //   width: 10% !important;
      // }
    }

    tbody {
      tr {
        td {
          white-space: unset;
          padding-top: 5px !important;
          padding-bottom: 5px !important;
          min-width: 180px !important;
        }

        td:nth-child(1) {
          min-width: 200px !important;
        }
  
        // td:nth-child(2) {
        //   min-width: 250px !important;
        // }
  
        // td:nth-child(3) {
        //   min-width: 250px !important;
        // }
  
        // td:nth-child(4) {
        //   min-width: 200px !important;
        // }
  
        // td:nth-child(5) {
        //   min-width: 250px !important;
        // }
  
        // td:nth-child(6) {
        //   min-width: 200px !important;
        // }

        // td:nth-child(7) {
        //   width: 6% !important;
        // }

        // td:nth-child(8) {
        //   width: 8% !important;
        // }

        // td:nth-child(9) {
        //   width: 10% !important;
        // }

        // td:nth-child(10) {
        //   width: 7% !important;
        // }

        // td:nth-child(11) {
        //   width: 10% !important;
        // }

        // td {
        //     font-family: 'Roboto' !important;
        //     font-style: normal !important;
        //     font-weight: 500 !important;
        //     font-size: 18px !important;
        //     line-height: 21px !important;
        //     /* identical to box height */

        //     color: #202124 !important;
        //     // width: 250px !important;
        //     border: none !important;
        // }

        // td:not(:first-child) {
        //     padding: 5px !important;
        // }

        // td:first-child {
        //     padding-left: 10px !important;
        //     padding-right: 0;
        //     padding-top: 5px !important;
        // }
      }

      // tr:hover {
      //     background: #F3F3F3;
      // }
    }
  }
}

.attendance-td{
  .p-dropdown{
    width: 150px;
    // border: none;
    border-radius: 4px;
    border-color: #B0B3C8;
  }
}

.attendance-value-dropdown{
  .p-dropdown-label{
    border: none !important;
    font-size: 14px;
    padding-inline: 8px; 
  }
  .p-dropdown-trigger{
    width: unset;
    padding-inline: 5px;
  }
  .p-dropdown-trigger-icon{
    font-size: 12px;
  }
}

.shift-list{
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
}

.shift-card-item{
  background-color: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(22, 22, 22, 0.1);
  border-radius: 4px;
  border-left: 4px solid #1768AC;
  padding: 8px 12px;
  width: 20%;
  .shift-item-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }
  }
  .shift-action{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .action-shift-btn{
    background: #F3F3F3;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .action-shift-btn:hover{
    background-color: #1768AC;
    i{
      color: #FFFFFF;
    }
  }
  .shift-item-footer{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 12px;
    p{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin: 0;
    }
    i{
      font-size: 10px;
    }
  }
}

.shift-status-table{
  margin-top: 25px;
  h1{
    font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      margin: 0;
  }
  p{
    font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin: 0;
  }
}

.dropdown-shift{
  .p-multiselect{
    width: 130px;
    border-radius: 4px;
    border-color: #B0B3C8;
  }
  .p-multiselect-label{
    border: none !important;
    font-size: 14px;
    padding-inline: 8px; 
  }
  .p-multiselect-trigger{
    width: unset;
    padding-inline: 5px;
  }
  .p-multiselect-trigger-icon{
    font-size: 12px;
  }
}

.shift-block{
  width: 200px;
}

.shift-container{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.shift-footer.add-btn1{
  width: 100px;
  background-color: #1768AC !important;
}

.header-shift{
  border-bottom: 1px solid #2541b2;
  padding-bottom: 8px;
  p{
    font-weight: 600;
    color: #7A7878;
    font-family: 'Poppins';
      font-style: normal;
  }
}

.shift-table-item{
  padding-block: 10px;
}

.shift-table-item:nth-child(odd) {
  background-color: #f3f3f3;
}

// CSS OF TRIP DETAILS 

.trip-detail-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #06BEE1;
  padding-left: 27px;
}

.trip-detail-text {
  padding-bottom: 5px;
  border-bottom: 2px solid #06BEE1;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
}

.checkpoint-container {
  box-sizing: border-box;
  border: 1px solid #B0B3C8;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px 10px;
}

.checkpoint-item {
  // margin-bottom: 20px;
  padding-left: 10px;
}

.checkpoint-place {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #343434;
}

.checkpoint-date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  padding-top: 2px;
  color: #343434;
}

.departure-block {
  background: #D6E1F0;
  border-radius: 5px;
  padding: 5px 11px;

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    margin: 0;

    color: #03256C;
  }

  i {
    font-size: 14px;
    color: #03256C;
  }
}

.summary-box {
  display: flex;
  flex-wrap: wrap;
  /* width: 70%; */
  justify-content: center;
}

.summary-rate-box {
  width: 48%;
  box-sizing: border-box;
  background: #F8F9FD;
  border-radius: 10px;
  padding: 10px 15px;
  margin-block: 5px;

  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    color: #7A7878;
  }

  .amount {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    padding-top: 5px;
    color: #2DC433;
  }

  .icon-summary-box {
    width: 25px;
    height: 25px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.heading-truck {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  color: #202124;
}

.truckNoBadge {
  background: #F3F3F3;
  border-radius: 21px;
  padding: 8px 15px;

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin: 0;

    color: #33535F;

  }
}

.truck-info {
  margin-top: 25px;

  .icon-box {
    background: #B7FFBA;
    border-radius: 7px;
    padding: 7px;
  }

  .truck-body-info {
    p {

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin: 0;

      color: #000000;
    }

    span {
      color: #616161;
    }
  }
}

.transporter-info {
  background: #F6F6F2;
  border-radius: 3px;
  padding: 8px 11px;
  margin-block: 25px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .transporter-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin: 0;

    color: #000000;

  }

  .transporter-subName {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    // padding-top: 4px;
    color: #616161;
  }
}

.vehicle-expense {
  border: 1.5px solid #F3F3F3;
  border-radius: 4px;
  padding: 14px 25px;

  .icon-box {
    width: 40px;
    height: 40px;
    background: #FFE5BF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .truck-expense-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    margin: 0;
    padding-top: 14px;
    color: #343434;
    text-align: center;
  }

  .truck-expense-subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    margin: 0;
    padding-top: 4px;
    color: #202124;
    text-align: center;
  }
}

.driver-info {
  .driver-box {
    margin-bottom: 10px;

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    background: #FCF9F3;
    border-radius: 13px;
    padding: 12px 25px;
  }

  .driverName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
    color: #202124;
  }

  .driverPhone {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    padding-top: 4px;
    color: #06BEE1;
  }
}