.vessel-agent-container {
    // margin-top: -42px;

    .header-action {
        position: relative;
        z-index: 1;
    }

    .vessel-agent-dashboard {
        background-color: $primaryWhite;
        height: calc(100vh - 195px);
        overflow: auto;
        border-radius: 8px;
        box-shadow: 0px 2px 10px 8px rgba(0, 0, 0, 0.02);

        p,div{
            text-transform: capitalize;
        }

        .token-list-area {
            border: 1px solid;
            border-top: 0px;
            border-radius: 5px;
            border-color: rgba(228, 235, 248, 0.35);
            margin-top: 14px;
            margin-inline: 10px;

            // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
            // background-color: rgba(203, 215, 255, 0.3);
            .token-list {
                background-color: #F8F9FD;
                position: relative;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;

                .status-badge {
                    position: absolute;
                    right: -8px;
                    top: -8px;

                    i {
                        font-size: 18px;
                    }
                }
            }

            .token-no {
                background-color: #1768AC;
                color: #FFFFFF;
                text-transform: uppercase;
                i {
                    color: #FFFFFF;
                }
            }

            .card-btns {
                background-color: #FFFFFF;
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08)
            }
        }

        .vessel-sidebar {
            // CSS OF TRIP DETAILS 

            .trip-detail-text {
                font-family: 'Roboto';
                font-style: normal;
                text-transform: capitalize;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                text-transform: uppercase;
                color: #06BEE1;
                padding-left: 27px;
            }

            .trip-detail-text {
                padding-bottom: 5px;
                border-bottom: 2px solid #06BEE1;
                border-radius: 10px 10px 0px 0px;
                cursor: pointer;
            }

            .checkpoint-container {
                box-sizing: border-box;
                border: 1px solid #B0B3C8;
                border-radius: 10px;
                margin-bottom: 15px;
                padding: 20px 10px;
            }

            .checkpoint-item {
                // margin-bottom: 20px;
                padding-left: 10px;
            }

            .checkpoint-place {
                font-family: 'Roboto';
                font-style: normal;
                text-transform: capitalize;
                font-weight: 400;
                font-size: 14px;
                margin: 0;
                color: #343434;
            }

            .checkpoint-date {
                font-family: 'Roboto';
                font-style: normal;
                text-transform: capitalize;
                font-weight: 400;
                font-size: 12px;
                margin: 0;
                padding-top: 2px;
                color: #343434;
            }

            .departure-block {
                background: #D6E1F0;
                border-radius: 5px;
                padding: 5px 11px;

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 400;
                    font-size: 13px;
                    margin: 0;

                    color: #03256C;
                }

                i {
                    font-size: 14px;
                    color: #03256C;
                }
            }

            .summary-box {
                display: flex;
                flex-wrap: wrap;
                /* width: 70%; */
                justify-content: center;
            }

            .summary-rate-box {
                width: 48%;
                box-sizing: border-box;
                background: #F8F9FD;
                border-radius: 10px;
                padding: 10px 15px;
                margin-block: 5px;

                .title {
                    font-family: 'Roboto';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 400;
                    font-size: 14px;
                    margin: 0;
                    color: #7A7878;
                }

                .amount {
                    font-family: 'Roboto';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 16px;
                    margin: 0;
                    padding-top: 5px;
                    color: #2DC433;
                }

                .icon-summary-box {
                    width: 25px;
                    height: 25px;
                    background-color: #FFFFFF;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }

            .heading-truck {
                font-family: 'Poppins';
                font-style: normal;
                text-transform: capitalize;
                font-weight: 500;
                font-size: 20px;
                margin: 0;
                color: #202124;
            }

            .truckNoBadge {
                background: #F3F3F3;
                border-radius: 21px;
                padding: 8px 15px;

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 16px;
                    margin: 0;
                    line-height: 18px;
                    color: #33535F;

                }
            }

            .truck-info {
                margin-top: 25px;

                .icon-box {
                    background: #E8F8FF;
                    border-radius: 7px;
                    // padding: 7px;
                    height: 38px;
                    width: 38px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    i{
                        font-size: 18px;
                        color: #06BEE1;
                    }
                }

                .truck-body-info {
                    p {

                        font-family: 'Roboto';
                        font-style: normal;
                        text-transform: capitalize;
                        font-weight: 400;
                        font-size: 14px;
                        margin: 0;

                        color: #000000;
                    }

                    span {
                        color: #616161;
                    }
                }
            }

            .transporter-info {
                background: #F6F6F2;
                border-radius: 3px;
                padding: 8px 11px;
                margin-block: 25px;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                i{ width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #FFFFFF;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                }

                .transporter-name {
                    font-family: 'Roboto';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 400;
                    font-size: 14px;
                    margin: 0;

                    color: #000000;

                }

                .transporter-subName {
                    font-family: 'Roboto';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 400;
                    font-size: 12px;
                    margin: 0;
                    // padding-top: 4px;
                    color: #616161;
                }
            }

            .vehicle-expense {
                border: 1.5px solid #F3F3F3;
                border-radius: 4px;
                padding: 14px 25px;

                .icon-box {
                    width: 40px;
                    height: 40px;
                    background: #FFE5BF;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                }

                .truck-expense-title {
                    font-family: 'Roboto';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 15px;
                    margin: 0;
                    padding-top: 14px;
                    color: #343434;
                    text-align: center;
                }

                .truck-expense-subtitle {
                    font-family: 'Roboto';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 400;
                    font-size: 15px;
                    margin: 0;
                    padding-top: 4px;
                    color: #202124;
                    text-align: center;
                }
            }

            .driver-info {
                .driver-box {
                    margin-bottom: 10px;

                    img {
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                    }

                    i{
                        font-size: 19px;
                    }

                    background: #FCF9F3;
                    border-radius: 13px;
                    padding: 12px 25px;
                }

                .driverName {
                    font-family: 'Poppins';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 1;
                    margin: 0;
                    color: #202124;
                }

                .driverPhone {
                    font-family: 'Poppins';
                    font-style: normal;
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 14px;
                    margin: 0;
                    padding-top: 4px;
                    color: #06BEE1;
                }
            }
        }
    }
}