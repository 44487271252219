@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;

  &:focus {
    box-shadow: none;
  }

  button {
    &:focus {
      // box-shadow: none;
      outline: 0 none !important;
      outline-offset: 0 !important;
      box-shadow: none !important;
      border: none !important;
    }
  }
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 6.5px;
  height: 7px;
  border: none !important;

  &:hover {
    width: 10px;
    height: 10px;
  }
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #d5d5d5;
  border-radius: 50px;
}

.loading-component {
  position: fixed;
  z-index: 1999;
  top: 0px;
  left: 0px;
  width: 100vw;
  left: 100vh;
}

.loader-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader-05 {
  width: 40px;
  height: 40px;
  border: 0.2em solid transparent;
  border-top-color: white;
  border-radius: 50%;
  animation: 1s loader-05 linear infinite;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: -0.2em;
    left: -0.2em;
    border: 0.2em solid white;
    border-radius: 50%;
  }
}

@keyframes loader-05 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

img {
  width: 100%;
}

.makeStyles-footer-6 {
  display: none;
}

.reset-text {
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 22px;
  padding: 0 35px;
}

.primary-color {
  color: $primaryColor;
}

.primary-btn {
  background: $primaryColor !important;
  border-radius: 4px !important;
  padding: 4px 30px !important;
  display: inline-block !important;
  color: #fff !important;
  // box-shadow: none !important;

  &:hover {
    background-color: #0ca1bd !important;
  }

  &:focus {
    // box-shadow: none !important;
  }

  @media screen and (max-width: 767px) {
    padding: 4px 10px !important;
  }
}

.primary-btn1 {
  border: 1px solid $primaryColor !important;
  border-radius: 4px !important;
  padding: 4px 30px !important;
  display: inline-block !important;
  color: #000 !important;
  // box-shadow: none !important;
  background-color: transparent !important;

  &:hover {
    border: 1px solid #09859d !important;
    background-color: transparent;
  }

  &:focus {
    // box-shadow: none !important;
  }

  @media screen and (max-width: 767px) {
    padding: 4px 10px !important;
  }
}

.green-primary-btn1 {
  background: #2dc433 !important;
  border-radius: 4px !important;
  padding: 4px 30px !important;
  display: inline-block !important;
  color: #fff !important;
  // box-shadow: none !important;

  &:hover {
    background-color: #27e42e !important;
  }
}

.red-primary-btn {
  border: 1px solid #f44336 !important;
  border-radius: 4px !important;
  padding: 4px 30px !important;
  display: inline-block !important;
  color: #f44336 !important;
  box-shadow: none !important;
  background-color: transparent !important;

  &:hover {
    color: #ff1605 !important;
    border: 1px solid #ff1605 !important;
    background-color: transparent;
  }
}

.upload-btn {
  background: #fc873c !important;
  border-radius: 4px !important;
  padding: 4px 20px !important;
  display: inline-block !important;
  color: #fff !important;
  box-shadow: none !important;
  cursor: pointer;
}

.upload-btn1 {
  color: #fc873c !important;
  border-radius: 4px !important;
  padding: 4px 20px !important;
  display: inline-block !important;
  box-shadow: none !important;
  border: 1px solid #fc873c !important;

  &:focus {
    box-shadow: none;
  }
}

.action-btn1 {
  background: #eef2fa !important;
  color: #03256c !important;
  border-radius: 8px !important;
  padding: 4px 30px !important;
  display: inline-block !important;
  box-shadow: none !important;
}

.round-btn {
  border-radius: 50%;
}

.primary-btn2 {
  background: $primaryDarkColor !important;
  border-radius: 4px !important;
  padding: 4px 30px !important;
  display: inline-block !important;
  color: #fff !important;

  &:focus {
    // box-shadow: none !important;
  }

  @media screen and (max-width: 767px) {
    padding: 4px 10px !important;
  }
}

.primary-btn3 {
  border: 1px solid $primaryDarkColor !important;
  border-radius: 4px !important;
  padding: 4px 30px !important;
  display: inline-block !important;
  color: #000 !important;
  // box-shadow: none !important;
  background-color: transparent !important;

  &:hover {
    border: 1px solid #09859d !important;
    background-color: transparent;
  }

  &:focus {
    // box-shadow: none !important;
  }

  @media screen and (max-width: 767px) {
    padding: 4px 10px !important;
  }
}

.secondary-btn {
  border: 1px solid $primaryColor;
  border-radius: 50px;
  padding: 8px 30px;
  display: inline-block;
  color: #000;

  span {
    color: #000;
  }

  @media screen and (max-width: 767px) {
    padding: 8px 10px !important;
  }
}

.add-port-btn {
  background: #eae8ff !important;
  border-radius: 4px !important;
  display: inline-block !important;
  padding: 8px 0px !important;
  margin-left: 15px;
  font-size: 14px !important;

  a {
    color: $primaryDarkColor !important;
    padding: 8px 20px !important;
  }
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 25px;
  color: #fff;
  font-size: 10px;
  text-transform: capitalize !important;
  min-width: 102px;

  span {
    margin: 0 3px;
  }

  .icon-close {
    cursor: pointer;
  }
}

.custom-card-head {
  font-size: 16px;
  color: $primaryColor;
  padding: 0 10px;
  font-weight: 600;
}

.custom-card-ftr {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  padding-right: 75px;
}

.right-nav-buttons {
  button {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.top-icon-btn {
  span {
    display: flex;
    align-items: center;
    align-self: center;
  }
}

.MuiFormGroup-root {
  .MuiFormControlLabel-root {
    .Mui-checked {
      .MuiSvgIcon-root {
        path {
          color: #00bcd4;
        }
      }
    }
  }
}

.sell-rate {
  label.MuiFormControlLabel-root {
    margin: 0 !important;
    padding-bottom: 6px;
    width: 30px;
    position: relative;
    height: 50px;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    padding: 0;
    color: $mainDarkBlue;
    position: absolute;
    left: 0px;
    top: 40px;
  }

  .MuiCheckbox-root {
    padding: 0 !important;
    position: absolute;
    left: 0px;
    top: 15px;
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
  }
}

.CDate {
  label.MuiFormControlLabel-root {
    margin: 0 !important;
    padding-bottom: 6px;
    width: 22px;
    position: relative;
    height: 50px;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    padding: 0;
    color: $mainDarkBlue;
    position: absolute;
    left: 0px;
    top: 15px;
  }

  .MuiCheckbox-root {
    padding: 0 !important;
    position: absolute;
    left: 0px;
    top: 15px;
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
  }
}


.CDate1 {
  label.MuiFormControlLabel-root {
    margin: 0 !important;
    padding-bottom: 6px;
    width: 30px;
    position: relative;
    height: 50px;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    padding: 0;
    color: $mainDarkBlue;
    position: absolute;
    left: 0px;
    bottom: -7px;
  }

  .MuiCheckbox-root {
    padding: 0 !important;
    position: absolute;
    left: 0px;
    bottom: -7px;
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
  }
}

.add-icon-btn {
  z-index: 9;
}

.filter-btn {
  width: 35px;
  height: 35px;
  min-width: unset !important;
  background-color: $secondaryColor !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  margin-left: 8px !important;
  // box-shadow: none !important;
  outline: none !important;
  border: none !important;

  i {
    color: #fff;
    flex-shrink: 0;
    font-size: 17px;
  }

  &:focus {
    // box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
  }

  &:hover {
    background-color: #303f9f !important;

    outline: none !important;
    border: none !important;
  }

  * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
  }
}

.add-btn {
  width: 35px;
  height: 35px;
  min-width: unset !important;
  background-color: $secondaryColor !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  margin-left: 8px !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  cursor: pointer;
  color: #fff;

  i {
    color: #fff;
    flex-shrink: 0;
    font-size: 17px;
  }

  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
  }

  &:hover {
    background-color: #303f9f !important;

    outline: none !important;
    border: none !important;
  }
}

.add-btn1 {
  width: auto;
  height: 35px;
  min-width: unset !important;
  background-color: $secondaryColor !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  margin-left: 8px !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  cursor: pointer;
  color: #fff;
  padding: 0 10px;
  white-space: nowrap;

  i {
    color: #fff !important;
    flex-shrink: 0 !important;
    font-size: 14px !important;
    margin-right: 8px !important;
    width: auto !important;
  }

  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
  }

  &:hover {
    background-color: #303f9f !important;

    outline: none !important;
    border: none !important;
  }
}

.searchbar-area {
  height: 35px;
  border-radius: 6px;
  border: 1px solid #03256c !important;
  background: transparent;
  display: flex;
  align-items: center;
  margin-left: 8px !important;

  // &>div {
  //     border: 1px solid hsl(228, 66%, 42%) !important;
  //     box-shadow: none !important;
  //     outline: none !important;

  //     &:focus {
  //         border: 1px solid hsl(228, 66%, 42%) !important;
  //         box-shadow: none !important;
  //         outline: none !important;

  //     }

  //     &:focus-within {
  //         border: 1px solid hsl(228, 66%, 42%) !important;
  //         box-shadow: none !important;
  //         outline: none !important;
  //     }
  // }

  .searchbar,
  .MuiOutlinedInput-root {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  input {
    width: 175px;
    border: none;
    padding: 6px !important;
    background: transparent !important;
    min-height: 21px;

    &:focus {
      box-shadow: none !important;
    }
  }

  // &:hover {
  //     border: 1px solid hsl(228, 66%, 42%) !important;

  //     box-shadow: none !important;
  //     outline: none !important;
  // }

  &:focus-within {
    border: 1px solid hsl(228, 66%, 42%) !important;
    box-shadow: none !important;
    outline: none !important;
  }

  // .MuiInputBase-root {
  //     padding: 0;
  //     border: none !important;

  //     &:hover {
  //         border: none !important;
  //         outline: none !important;
  //         box-shadow: none !important;

  //     }
  // }

  i {
    font-size: 20px;
    color: #000;
    margin: 4px;
    text-align: center;
  }
}



.secondary-btn1 {
  box-shadow: 3px 1px 2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
  background-color: $primaryColor;
  border-radius: 50px;
  padding: 9px 30px;
  display: inline-block;
  color: #fff;

  a {
    color: #fff;
  }

  &:hover {
    background-color: #fff;
    color: $primaryColor;
  }
}

.mainDarkBlue {
  color: $mainDarkBlue !important;
}

.color-white {
  color: $primaryWhite !important;
}

.primary-gray {
  color: $primarygray;
}

.color-disable {
  color: #c4c4c4;
}

.color-orange {
  color: #ff9800;
}

.color-green {
  color: #2ecb71;
}

.bg-orange {
  background-color: #ff9800;
}

.bg-light-orange {
  background-color: #FFDCA9 !important;
}

.bg-none {
  background-color: transparent !important;
}

.disable-icon-bg {
  background-color: rgba(196, 196, 196, 0.15);
}

.green-icon-bg {
  background-color: rgba(46, 203, 113, 0.2);
}

.orange-icon-bg {
  background-color: rgba(255, 152, 0, 0.2);
}

.bg-green {
  background-color: #2ecb71 !important;
}

.bg-red {
  background-color: #f44336 !important;
}

.bg-blue {
  background-color: #06bee1 !important;
}

.bg-purple {
  background-color: #77048b !important;
}

.bg-brown {
  background-color: #d0856c !important;
}

.primary-border {
  border: 1px solid $primaryColor;
}

.primary-dark-border {
  border: 1px solid $primaryDarkColor !important;
}

.b-radius-10 {
  border-radius: 10px;
}

.fw-300 {
  font-weight: 300;
}

.border-right {
  border-right: 1px solid $primaryColor;
}

.dark-blue-color {
  color: $mainDarkBlue !important;
}

.light-color {
  color: #7a7878 !important;
}

.color-black {
  color: #000;
}

.text-small {
  font-size: 14px;
}

.dark-blue-border {
  border: 1px solid $mainDarkBlue;
}

.border-dark-right {
  border-right: 1px solid $mainDarkBlue;
}

.border-dark-blue {
  border: 1px solid $mainDarkBlue !important;
}

.border-bottom-light {
  border-bottom: 1px solid #f3f3f3;
}

.br-e5e5e5 {
  border-right: 1px solid #e5e5e5;
}

hr {
  border-top: 1px solid #e5e5e5 !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px;
}

.fs-22 {
  font-size: 22px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 500;
}

.p-pt-12px {
  padding-top: 12px !important;
}

.p-pt-10px {
  padding-top: 10px !important;
}

.p-min-w-auto {
  min-width: auto !important;
}

.p-w-auto {
  width: auto !important;
}

.p-h-auto {
  height: auto !important;
}

.p-mw-100 {
  min-width: 100% !important;
}

.p-mh-100 {
  min-height: 100% !important;
}

.p-w-100 {
  width: 100% !important;
}

.p-h-100 {
  height: 100% !important;
}

.p-w-50 {
  width: 50% !important;
}

.p-h-50 {
  height: 50% !important;
}

.p-overflow-visible {
  overflow: visible !important;
}

.green-icon {
  color: #2ecb71;
  background: #2ecb7126;
}

.pink-icon {
  color: #c35363;
  background: #e1a7d826;
}

.yellow-icon {
  color: #ff9800;
  background: #ff980026;
}

.red-icon {
  color: #f44336;
  background: #f4433626;
}

.purple-icon {
  color: #72447d;
  background: #72447d26;
}

.blue-icon {
  color: #06bee1;
  background: #2ecb7126;
}

@media screen and (max-width: 991px) {
  .p-h-lg-100 {
    height: 100vh;
  }
}

.p-overflow-hidden {
  overflow: hidden !important;
}

.p-overflow-auto {
  overflow: auto !important;
}

.border-seprate {
  border-bottom: 1px solid #e5e5e5;
}

.border-seprate-top {
  border-top: 1px solid #e5e5e5;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}

.p-position-relative {
  position: relative;
}

.float-left {
  float: left;
}

.text-decore {
  text-decoration: underline !important;
}

.company-check label {
  margin-right: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// ================jivo chat======================

.label_b03f._bottom_d4c6 {
  background: #1768ac !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.leafCont_b4e9,
.copy_6c21 {
  display: none !important;
}

// =============password==============
.password-strength-meter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 20px;
  // progress{
  //     display: inline-block;
  // }
  // .password-strength-meter-label{
  //     display: inline-block;

  // }
}

.makeStyles-footer-18 {
  display: none;
}

#google_translate_element {
  display: flex;
  align-items: center;
  height: 46px;
  align-self: center;

  .goog-te-combo {
    background-color: transparent !important;
    margin: 7px 0 !important;
  }
}

.Sidebar-whiteBackground-12 {
  background-color: #343434 !important;
  color: #fff !important;
}

.Sidebar-blackBackground-10 {
  background-color: #1c588b !important;
  color: #fff !important;
}

.Sidebar-blueBackground-11 {
  background-color: #1c588b !important;
  color: #fff !important;
}

.Sidebar-blue-50 {
  color: #fff !important;
}

.border-primaryDarkColor {
  border-color: $primaryDarkColor !important;
}

.bgcolor-white {
  background-color: #fff !important;
}

.pl-10px {
  padding-left: 10px;
}

.mb-1px {
  margin-bottom: 1px !important;
}

.mb-2px {
  margin-bottom: 5px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.px-10 {
  padding: 0 10px !important;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.header-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;

  .header-user-name {
    color: $mainDarkBlue;
    padding-left: 10px;
    font-weight: 500;
    font-size: 18px;
  }
}

.MuiPaper-root {
  box-shadow: none !important;
  overflow-x: hidden !important;
}

.btn-icon {
  background-color: transparent !important;
  padding: 0px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  margin: 0px;

  i {
    color: $primaryColor;
  }
}

.MuiFormControl-marginNormal {
  margin: 0 !important;
}

// card custom design

.d-hotline {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50px !important;
}

//custom autocomplete design
.custom-autocomplete {
  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
  }
}

// =============notification container===========

.noti-container {
  width: 500px;
  padding-top: 20px;
  position: fixed;
  right: -13px;
  background-color: transparent !important;
  top: 0px;
  padding: 5px !important;
  padding-top: 15px !important;
  border-radius: 13px !important;

  &>div {
    width: 100%;
    border-radius: 13px !important;
    box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.25) !important;
    background-color: #fff;
    padding: 0px !important;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      position: absolute;
      top: 5px;
      right: 20px;
      transform: rotate(45deg);
    }
  }

  ul {
    padding: 12px 12px 0 !important;
  }

  .input-lable {
    font-size: 14px;
    color: #a4a6ab;
  }

  .noti-input {
    input {
      width: 100% !important;
      padding: 6px !important;
    }
  }

  .noti-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid $primaryColor;
    padding: 12px !important;
  }

  .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    position: static;
    border: 1px solid $mainDarkBlue;
    border-radius: 5px;

    &::before {
      content: "";
      border: none !important;
    }

    &::after {
      content: "";
      border: none !important;
    }
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none !important;
  }
}

// style={{ height: "320px", overflowX: "hidden", overflowY: "auto" }}
.lock-user-name {
  font-weight: 600;
  text-align: center;
}

.input-icon {
  color: $primaryDarkColor;
  padding: 8px 15px 0;
}

.lock-user {
  width: 100px;
  overflow: hidden;
  margin: auto;
  height: 100px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.p-overlaypanel-content {
  padding: 0 !important;
}

// ================================
.user-profile-section {
  .user-profile {
    font-size: 20px;
    font-weight: 600;
  }

  .MuiFormControl-fullWidth {
    padding-top: 22px !important;
  }

}

.p-progressbar {
  height: 8px;

  .p-progressbar-value {
    background-color: $mainDarkBlue;
  }

  .p-progressbar-label {
    display: none;
  }
}

.company-profile-section {
  padding-bottom: 50px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 97%;

  .user-profile {
    font-size: 20px;
    font-weight: 600;
  }

  .p-accordion {
    .p-accordion-tab {
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 15px;

      .p-disabled {
        opacity: 1 !important;

        .p-accordion-header-link {
          background: transparent !important;
          border: none !important;
          flex-direction: row-reverse;
          padding: 0;
        }

        .p-accordion-toggle-icon {
          cursor: not-allowed !important;

          &::before {
            color: #bebebe;
          }
        }
      }

      .accordion-header {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        margin: 0;
        color: #000000;
      }

      .default-tag {
        background: #4F5678;
        color: #FFFFFF;
        font-size: 13px;
      }

      .reject-tag {
        background: #ff6257;
        color: #FFFFFF;
        font-size: 13px;
      }

      .waiting-tag {
        background: #f1b13c;
        color: #FFFFFF;
        font-size: 13px;
      }
    }

    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
      background: transparent !important;
      border: none !important;
      flex-direction: row-reverse;
      padding: 0;
      color: #000;

      &:focus {
        box-shadow: none !important;
      }
    }

    .p-accordion-header:not(.p-highlight):not(.p-disabled) .p-accordion-header-link {
      background: transparent !important;
      border: none !important;
      flex-direction: row-reverse;
      padding: 0;

      &:focus {
        box-shadow: none !important;
      }
    }

    .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
      background-color: #f3f3f3;
      color: #03256c;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      margin-right: 0px;
      flex-shrink: 0;
    }

    .p-accordion-header-text {
      width: 100%;
      font-weight: normal;
    }

    .truck-status {
      color: #343434;
      background-color: rgba(255, 153, 0, 0.3);
      border-radius: 6px;
      padding: 7px 15px;
    }

    .weight-diff {
      padding: 7px 20px;
      border-radius: 25px;
      background-color: rgba(244, 67, 54, 0.3);
      color: #f44336;
    }

    .p-accordion-content {
      border: none;
      padding: 20px 0 0px;
      padding-top: 0px;
      border-top: 1px solid #e5e5e5 !important;
      margin-top: 15px;

      .trip-user-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
        margin-right: 20px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .driver-info-area {
        margin-bottom: 16px;

        .driver-info-label {
          color: #858585;
          margin-bottom: 10px;
        }

        .driver-info-detail {
          color: #202124;
          // margin-bottom: 10px;
        }

        .weight-paper {
          background-color: #eae8ff;
          border-radius: 5px;
          padding: 7px;
          font-size: 13px;

          .wp-icon {
            width: 20px;
            height: 20px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }

          label {
            cursor: pointer;

            input {
              display: none;
            }
          }
        }
      }
    }
  }

  .border-ac-left-blue {
    .p-accordion-tab {
      border-left: 10px solid #0087CA !important;
    }

  }

  .border-ac-left-green {
    .p-accordion-tab {
      border-left: 10px solid #2ecb71 !important;
    }
  }

  .border-ac-left-orange {
    .p-accordion-tab {
      border-left: 10px solid #f1b13c !important;
    }
  }

  .border-ac-left-red {
    .p-accordion-tab {
      border-left: 10px solid #ff6257 !important;
    }
  }
}

.p-position-static {
  position: static !important;
}

.side-mini-area {
  height: calc(100% - 105px);
  overflow-y: auto;
  overflow-x: hidden;
}

.mylocation {
  height: calc(100vh - 161px) !important;
  box-shadow: 0px 2px 10px 8px rgba(0, 0, 0, 0.02);
  background-color: #fff !important;
  border-radius: 10px;

  .custom-card-ftr {
    position: static !important;
    padding: 10px 10px 0 !important;
  }

  label {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  }

  .formitem-location {
    .MuiFormControl-fullWidth {
      margin: 0 !important;
      padding-top: 25px !important;

      p {
        display: none;
      }
    }
  }
}

.form-custom-label {
  color: #aaaaaa !important;
  font-size: 11px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.42857;
}

.custom-input-file-button {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 17px;

  &::before {
    content: "Browse File";
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    color: $primaryDarkColor;
    border: 1px solid $mainDarkBlue;
    width: calc(100% - 16px);
    position: absolute;
    height: 23px;
  }

  .icon-file {
    position: absolute;
    right: 10px !important;
    bottom: 50% !important;
    transform: translateY(50%);
  }

  .file-count {
    background-color: $mainDarkBlue;
    color: $primaryWhite;
    border-radius: 30px;
    padding: 2px 7px;
    position: absolute;
    right: 40px !important;
    bottom: 50% !important;
    transform: translateY(50%);
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    width: 100%;
    opacity: 0;
    z-index: 10;
    height: 23px;
    position: absolute;
  }

  .MuiInput-underline {

    &::before,
    &::after {
      content: none !important;
    }
  }
}

.custom-input-image-button {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 17px;

  &::before {
    content: "Browse Images";
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    color: $primaryDarkColor;
    border: 1px solid $mainDarkBlue;
    width: calc(100% - 16px);
    position: absolute;
    height: 23px;
  }

  .icon-file {
    position: absolute;
    right: 10px !important;
    bottom: 50% !important;
    transform: translateY(50%);
  }

  .file-count {
    background-color: $mainDarkBlue;
    color: $primaryWhite;
    border-radius: 30px;
    padding: 2px 7px;
    position: absolute;
    right: 40px !important;
    bottom: 50% !important;
    transform: translateY(50%);
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    width: 100%;
    opacity: 0;
    z-index: 10;
    height: 23px;
    position: absolute;
  }

  .MuiInput-underline {

    &::before,
    &::after {
      content: none !important;
    }
  }
}

.mymap {
  height: 100%;
  border-radius: 10px !important;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 10px 8px rgba(221, 159, 159, 0.02);

  .gm-fullscreen-control {
    border-radius: 6px !important;
  }

  .gm-bundled-control {
    bottom: unset !important;
    top: 50px;

    .gm-svpc {
      border-radius: 6px !important;
    }

    .gmnoprint {
      top: 50px !important;
      border-radius: 6px !important;
      overflow: hidden;
    }
  }

  .gmnoprint {
    border-radius: 6px !important;

    .gm-style-mtc {
      &:first-child {
        border-radius: 6px 0 0 6px !important;

        button {
          border-radius: 6px 0 0 6px !important;
        }
      }

      &:last-child {
        border-radius: 0 6px 6px 0 !important;

        button {
          border-radius: 0 6px 6px 0 !important;
        }
      }
    }
  }

  .locate-btn {
    position: absolute;
    bottom: 10px;
    left: 10px;

    button {
      padding: 0;
      width: 40px;
      height: 40px;
      background-color: $mainDarkBlue;
      border-radius: 5px;
      color: #fff;
    }
  }
}

.p-paginator.p-component {
  border: none !important;
  border-radius: 4px;
  background-color: transparent !important;
  justify-content: flex-start !important;
}

.p-paginator {
  .p-paginator-current {
    display: none !important;
  }

  .p-paginator-icon {
    color: #7f97f9;
  }

  .p-paginator-page-start,
  .p-paginator-page-start,
  .p-paginator-page {
    display: none;
  }

  .p-highlight {
    display: inline-flex !important;
    background-color: #f8f8fa !important;

    border: none !important;
    color: #000 !important;
  }

  .p-paginator-first,
  .p-paginator-next,
  .p-paginator-prev,
  .p-paginator-last {
    background-color: #f8f8fa !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .p-paginator-first {
    border-radius: 4px 0 0 4px !important;
  }

  .p-paginator-last {
    border-radius: 0 4px 4px 0 !important;
  }

  .p-dropdown {
    background-color: #f8f8fa !important;
    border-radius: 4px;
    border: none !important;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
  }
}

.dialog-sm {
  .MuiDialog-paper {
    width: 600px;
  }
}

.nav-dropdown-outer {
  &>div {
    top: 60px !important;
    left: -115px !important;

    // overflow: hidden;
    @media screen and (max-width: 480px) {
      left: 0px !important;
    }
  }

  .nav-drop-ul {
    max-height: 371px;
    overflow: auto;
    min-height: 85px;
  }

  .nav-drop-container {
    box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.25) !important;
    border-radius: 15px;

    // background-color: #fff;
    // padding: 0px !important;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      position: absolute;
      top: -10px;
      right: 20px;
      transform: rotate(45deg);

      @media screen and (max-width: 480px) {
        right: 120px;
      }
    }
  }
}

.request-drop {
  .nav-drop-container {
    &::before {
      @media screen and (max-width: 480px) {
        right: 140px;
      }

      @media screen and (max-width: 360px) {
        right: 175px;
      }
    }
  }
}

.noti-drop-container {
  &::before {
    content: "";
    width: 18px;
    height: 18px;
    background-color: #fff;
    position: absolute;
    top: -9px;
    right: 15px;
    transform: rotate(45deg);
  }
}

.table-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;

  &>div {
    margin-right: 7px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 5px;

    i {
      color: #03256c !important;
      font-size: 17px;

      // &:focus {
      //     background-color: #202124;
      // }
    }

    &:active {
      background-color: #2541b2;
      color: #fff;

      i {
        color: #fff !important;
      }
    }
  }

  .table-add-icon {
    background-color: #2541b2;
    border-radius: 50px;

    .icon-add {
      color: #ffffff !important;
    }
  }
}

// .MuiInputBase-formControl {
//     border: 1px solid #03256C !important;

// }

td .MuiFormControlLabel-labelPlacementStart {
  margin: 0 !important;
  flex-direction: row !important;
}

.p-tooltip {
  .p-tooltip-text {
    background-color: #202124;
    border-radius: 6px;
    color: $primaryWhite;
    font-weight: 400;
    font-size: 13px;
    padding: 5px 15px;
  }
}

.badge {
  &.badge-custom {
    background-color: $primaryColor;
    border-radius: 25px;
    color: $primaryWhite;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 8px;
  }
}

.new-tag {
  padding: 0 7px;
  font-size: 12px;
  border-radius: 5px;
  background-color: #2ecb71;
  color: #fff !important;
  margin-left: 5px;
}

.profile-btn {
  .MuiMenu-paper {
    padding: 5px;
    border-radius: 15px;
    box-shadow: 0px 2px 8px 3px #00000024 !important;
  }
}

.goog-te-gadget {
  height: 46px;
  // display: none;
  // display: flex;
  // align-items: center;
  // overflow: hidden;
  // display: none;
}

// .skiptranslate {
//     position: relative;

//     &::after {
//         content: '';
//         position: absolute;
//         width: 100px;
//         height: 20px;
//         background-color: #fff;
//     }
// }

.p-row-toggler:focus {
  box-shadow: none !important;
}

.sub-login-btn {
  @media screen and (max-width: 1350px) {
    flex-direction: column;
  }
}

.edit-vessel-section .rdtPicker {
  position: absolute !important;
  top: -285px !important;
  box-shadow: 0px 0px 10px #00000033 !important;

  &::before,
  &::after {
    top: unset !important;
    bottom: -5px;
    transform: rotate(180deg);
  }
}

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: $mainDarkBlue !important;
}

.new-info-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.custom-input {
  input {
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-radius: 0px;
  }
}

.orange-tag {

  background: rgba(255, 152, 0, 0.3);
  border: 1px solid #FF9800;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  color: #343434;
  padding: 7px 15px;
}

.token-bagde {
  background-color: #ff9800;
  border-radius: 50px;
  padding: 4px 13px;
  color: #ffffff;
  font-size: 12px;
  margin-top: 4px;
  width: fit-content;
  font-weight: 500;
}

.special-vessel-input {
  margin-top: -25px;
}

// .p-dropdown-panel {
//     background: #FFFFFF !important;
//     box-shadow: 0px 1px 8px -2px rgba(0, 0, 0, 0.25) !important;
//     border-radius: 13px !important;
//     border: none !important;
//     top: 375px !important;

//     // min-width: 55px !important;
//     .p-dropdown-items-wrapper {
//         position: relative;
//         z-index: 2;
//         background: #FFFFFF !important;
//         border-radius: 13px !important;

//     }

//     &::after {
//         content: '';
//         width: 20px;
//         height: 20px;
//         position: absolute;
//         bottom: -9px;
//         left: 20px;
//         transform: rotate(45deg);
//         background-color: #fff;
//         z-index: 1;
//         box-shadow: 0px 1px 8px -2px rgba(0, 0, 0, 0.25) !important;

//     }

//     .p-dropdown-item {
//         overflow: hidden;
//         text-align: center;
//         color: #000000;

//         &.p-highlight {
//             background-color: transparent !important;
//             color: #1768AC !important;
//         }
//     }
// }

// .notification-overlaypanel{
//     top: 121px !important;
// }
.MuiOutlinedInput-adornedEnd {
  padding-right: 0 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #03256c !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-12 {
  font-size: 12px !important;
}

.MuiRadio-colorSecondary:hover {
  background-color: rgba(3, 36, 108, 0.04) !important;
}

.add-truck-form {
  padding: 10px;
  height: calc(100vh - 250px);
  overflow: auto;
}

#g-recaptcha>div {
  margin: auto;
}

.Mui-error {
  margin-bottom: 0 !important;
}

.special-input-lable {
  .add-detail-lable {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #aaaaaa !important;
    font-weight: 400;
  }
}

.vessel-cancel-icon {
  line-height: 1;

  i {
    font-size: 16px;
  }
}

.vessel-status-msg {
  .icon {
    i {
      font-size: 18px !important;
    }

    .vessel-status-text {
      line-height: 1px;
    }
  }
}

.MuiInputLabel-formControl {
  color: #aaaaaa !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;

  &.Mui-error {
    color: #f44336 !important;
  }

  .makeStyles-underlineError-385 {
    border-color: #f44336 !important;
  }
}

.p-calendar {
  .p-inputtext {
    // padding-left: 0px !important;

    &::placeholder {
      color: #aaaaaa !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      font-family: "Roboto" !important;
    }
  }
}

.custom-p-dropdown {
  padding-top: 6px;
  padding-bottom: 0px;

  .p-dropdown {
    box-shadow: none !important;
    border-radius: 0px;
    border-bottom: 1px solid #d2d2d2 !important;
    width: 100%;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    height: 32px;
  }

  .p-dropdown-label {
    padding-left: 0px;
  }

  .p-autocomplete {
    box-shadow: none !important;
    border-radius: 0px;
    border-bottom: 1px solid #d2d2d2 !important;
    width: 100%;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    height: 32px;

    .p-button {
      background-color: transparent;
      border: 0px;

      .p-button-icon {
        color: #848484;
      }

      &:focus {
        box-shadow: none;

      }
    }

    .p-inputtext {
      width: 100%;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-bottom: 1px solid #a6a6a6;
      border: 0px;

      &:hover {
        border-bottom: 1px solid #a6a6a6;
        border: 0px;



      }
    }
  }
}

.custom-field-label {
  font-size: 12px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
  font-family: "Roboto";
}

.p-invalid {
  color: "red";
  font-weight: 400;
}

.p-dropdown-panel {
  z-index: 1301 !important;
}

.manual-item {
  background-color: #fff5e9 !important;
  color: #f8a200 !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 8px;
}

.automatic-item {
  background-color: #e9fbe0 !important;
  color: #5bbe2d !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 8px;
}

.p-buttonset {
  border-radius: 4px;

  .p-button {
    background-color: transparent !important;
    padding: 3px;

    span {
      border-radius: 4px;
      padding: 3px 8px;
      min-width: 80px;
      background-color: $primaryDarkColor;
      color: #fff;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:first-child {
      border-right: 0px solid !important;
    }

    &:last-child {
      border-left: 0px solid !important;
    }
  }

  .p-button-outlined {
    span {
      background-color: transparent !important;
      color: $primaryDarkColor;
    }
  }
}

.p-inputtext:enabled:focus {
  box-shadow: none;
}

.select-date {
  .icon-DateTime {
    font-size: 22px;
    position: absolute;
    right: 0;
    bottom: 7px;
  }
}

.p-float-label {
  label {
    // border: none;
    border-bottom: none !important;
    display: flex;
    align-items: center;
    color: $labelColor;
    font-size: 15px;
    font-weight: 400;
    font-family: "Rubik", sans-serif;
    top: 38% !important;
  }

  .p-dropdown {
    border: none;
    border-bottom: 1px solid $inputBorderColor;
    border-radius: 0 !important;
    box-shadow: none !important;
    margin-top: 10px;
    height: 38px;

    &.p-inputwrapper-focus~label,
    &.p-inputwrapper-filled~label {
      top: 8px !important;
      font-size: 12px;
      color: $labelColor;
    }

    .p-dropdown-label {
      padding: 8px 0px;
      line-height: 21px;
      font-size: 16px;
      font-family: "Rubik", sans-serif;
    }

    .p-placeholder {
      color: #979797;
      font-weight: 300;
      font-size: 14px;
    }

    .p-dropdown-trigger-icon {
      color: #979797;
      font-size: 13px;
    }
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
  }

  .bar:before,
  .bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background-color: $lightPurpleColor;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  .p-inputwrapper-focus~.bar:before,
  .p-inputwrapper-focus~.bar:after {
    width: 50%;
  }

  // .p-inputwrapper-filled ~ .bar:before,
  // .p-inputwrapper-filled ~ .bar:after {
  //     width: 50%;
  // }

  /* animations */
  @-webkit-keyframes inputHighlighter {
    from {
      background: #4285f4;
    }

    to {
      width: 0;
      background: transparent;
    }
  }

  @-moz-keyframes inputHighlighter {
    from {
      background: #4285f4;
    }

    to {
      width: 0;
      background: transparent;
    }
  }

  @keyframes inputHighlighter {
    from {
      background: #4285f4;
    }

    to {
      width: 0;
      background: transparent;
    }
  }
}

.google-places-autocomplete {
  position: static;

  .google-places-autocomplete__suggestions-container {
    width: 330px;
  }
}

.MuiDialog-paper {
  position: static !important;
}

.login-alert {
  .MuiDialog-container {
    height: 100% !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 650px;
    height: 400px;
    flex-direction: row;
    align-items: center;
  }

  .login-alert-container {
    overflow: hidden;

    .login-alert-img {
      width: 80%;
      height: 300px;
      margin: auto;

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .login-alert-details {
      .alert-icon {
        width: 70px;
        height: 70px;
        margin: auto;

        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      h1 {
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        margin: 0;
      }

      p {
        text-align: center;
        margin: 25px 0;
      }
    }
  }

  #formArea {
    animation: formArea 4s infinite;
    transform: translate(10px, 10px);
  }

  @keyframes formArea {
    0% {
      transform: translate(0px);
    }


    100% {
      transform: translate(10px, 10px);
    }
  }

  #gear1 {
    animation: gear1 3s -1.2s infinite ease-in-out;
    transform-origin: center;
    transform-box: fill-box;
  }

  @keyframes gear1 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  #plant1,
  #plant3,
  #plant5 {
    animation: wave1 3s -1.2s infinite ease-in-out;
    transform-origin: bottom;
    transform-box: fill-box;
  }

  @keyframes wave1 {
    0% {
      transform: rotateZ(-0deg) translateZ(1px);
    }

    25% {
      transform: rotateZ(1deg) translateZ(1px);
    }

    50% {
      transform: rotateZ(-0deg) translateZ(1px);
    }

    75% {
      transform: rotateZ(3deg) translateZ(1px);
    }

    100% {
      transform: rotateZ(-0deg) translateZ(1px);
    }
  }

  #plant2,
  #plant4 {
    animation: wave2 3s -1.2s infinite ease-in-out;
    transform-origin: bottom;
    transform-box: fill-box;
  }

  @keyframes wave2 {
    0% {
      transform: rotateZ(0deg) translateZ(-1px);
    }

    25% {
      transform: rotateZ(-1deg) translateZ(-1px);
    }

    50% {
      transform: rotateZ(0deg) translateZ(-1px);
    }

    75% {
      transform: rotateZ(-3deg) translateZ(-1px);
    }

    100% {
      transform: rotateZ(0deg) translateZ(-1px);
    }
  }

  #hand {
    animation: hand 3s infinite;
    transform-origin: right;
    transform-box: fill-box;
  }

  @keyframes hand {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(-10deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  #cloud {

    animation: mover 4s infinite;

    @keyframes mover {
      0% {
        transform: translate(0);
      }

      100% {
        transform: translate(-70px, 70px);
      }
    }
  }
}

.input-btn {
  button {
    margin-bottom: 5px;
  }
}

.Cdate-text {
  position: absolute;
  top: 0;
  left: 0;
}

.calendar {
  position: relative;

  .i-date {
    position: absolute;
    top: 35px;
    right: 0;
  }
}

.truck-status-no-img-uploaded {
  img {
    width: 250px;
    margin: auto;
  }
}

.card-container {
  background: #FFFFFF;
  border-radius: 10px;
  height: calc(100vh - 148px);
  overflow: auto;
  padding: 10px 20px 20px;
}

.custom-hs-master {
  div {
    max-width: unset;
  }

  .HSCode-dialog-content-area {
    width: 850px;
    max-height: 395px;

    table {
      border-spacing: 0;
      border-collapse: separate;
      margin-top: 20px;

      th {
        text-align: left;
        padding: 10px;
        white-space: nowrap;
        border-bottom: 1px solid #2541B2;
        position: relative;
        bottom: 8px;
      }

      tr.active {
        background: rgba(203, 215, 255, 0.3);

        td {
          border: 1px solid #2541B2;
          border-radius: 8px;
          border-left: 0;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        td:nth-child(1) {
          border-left: 1px solid #2541B2;
          border-radius: 8px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
        }

        td:last-child {
          border-right: 1px solid #2541B2;
          border-radius: 8px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      td {
        padding: 10px;
      }
    }
  }
}

.marker-track-red-label::before {
  // content: url('../equator-img/group.png');
  // height: 12px;
  background-image: url('../equator-img/marker-red-dot.png');
  background-size: 22px;
  display: inline-flex;
  align-items: center;
  width: 22px;
  height: 22px;
  content: "";

}

.marker-track-green-label::before {
  // content: url('../equator-img/group.png');
  // height: 12px;
  background-image: url('../equator-img/marker-green-dot.png');
  background-size: 22px;
  display: inline-flex;
  align-items: center;
  width: 22px;
  height: 22px;
  content: "";

}

.marker-track-label {
  background: #ffffff;
  padding: 5px;
  font-size: 15px !important;
  font-weight: 700;
  border-radius: 25px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  position: relative;
  top: 2px;
  padding-right: 10px;
  padding-block: 10px;
}

.network-header {
  .searchbar-area input {
    width: 150px;
  }

  .p-buttonset {
    // height: 35px;
    display: flex;
    border-radius: 5px;
    border: 1px solid #1768ac;

    .p-button {
      border: none !important;
      // padding: 0 3px;
    }
  }

  .p-button span {
    // height: 35px;
    padding: 1px 8px;
  }
}

.custom-large-table {
  .p-datatable table {
    table-layout: auto !important;
  }

  .p-paginator {
    margin-top: 10px;
  }

  .main-table {
    // margin-inline: -12px;
    // background: none;

    // input {
    //     font-size: 15px;
    // }

    // td {
    //     padding-top: 8px !important;
    // }

    // tr {
    //     background: none !important;
    // }

    // th {
    //     background: #F8F9FA !important;
    //     min-width: unset !important;
    //     white-space: nowrap;
    // }

    th {
      // min-width: unset !important;
      width: unset !important;
      // max-width: 100% !important;
    }

    td {
      min-width: unset !important;
      width: unset !important;
      // width: 25% !important;
    }

    .bill-action {
      display: flex;
      margin-left: -15px;
    }

    .action-item {
      i {
        height: 35px;
        width: 35px;
        border: 1px solid #1768ac2f;
        // border-radius: 8px;
        // background: #ffffff;
        // background: #FFFFFF;
      }
    }

    th:nth-child(1),
    td:nth-child(1) {
      min-width: 70px !important;
    }

    th:nth-child(2),
    td:nth-child(2) {
      min-width: 0px !important;
      white-space: nowrap;
    }

    th:nth-child(3),
    td:nth-child(3) {
      min-width: 0px !important;
      white-space: nowrap;
    }

    th:nth-child(4),
    td:nth-child(4) {
      min-width: 0px !important;
      white-space: nowrap;
    }

    th:nth-child(5),
    td:nth-child(5) {
      min-width: 0px !important;
      // white-space: nowrap;
    }

    th:nth-child(6),
    td:nth-child(6) {
      min-width: 0px !important;
    }

    th:nth-child(7),
    td:nth-child(7) {
      min-width: 0px !important;
      white-space: nowrap;
    }

    th:nth-child(8),
    td:nth-child(8) {
      min-width: 0px !important;
    }

    th:nth-child(9),
    td:nth-child(9) {
      min-width: 0px !important;
    }

    th:nth-child(10),
    td:nth-child(10) {
      min-width: 0px !important;
    }

    th:nth-child(11),
    td:nth-child(11) {
      min-width: 0px !important;
    }
  }
}

.ship-dashboard-port-card {

  // position: absolute;
  // right: 0;
  // bottom: 0;
  // height: 90%;
  //   width: 100%;
  .chartjs-size-monitor {
    // position: unset;
  }

  canvas {
    height: auto !important;
    width: auto !important;
    // width: 100% !important;
    // height: 90% !important;
  }

}

.rotate {
  rotate: 180deg;
}

.e-weighment-dashoard {
  height: calc(100vh - 102px) !important;

  .main-access-card-master {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 88%;
  }
}

.e-card-master {
  height: calc(100vh - 138px) !important;
}

.stock-outer {
  .MuiTabs-indicator {
    background-color: #06bee1 !important;
  }

  .PrivateTabIndicator-colorSecondary-250 {
    background-color: #06bee1 !important;
  }
}

// .MuiTabs-root {
//   .MuiTabs-indicator {
//     background-color: #06bee1 !important;
//   }
// }

.e-network-container {
  height: calc(100vh - 138px) !important;

  .p-datatable-scrollable-wrapper {
    height: calc(100% - 48px);
  }
}

.e-c-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.e-stock-modal {
  max-width: 100% !important;

  .MuiDialog-paperWidthSm {
    max-width: 100vw !important;
  }

  .e-content-area {
    max-width: 100vw !important;
  }
  .e-area {
    width: 100% !important;
  }
}

.dialog-heading {
  h2 {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    color: #202124;
    margin: 25px 0px 0px 10px;
  }

  .e-add-row {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    color: #345786;
    margin: 0px 0px 0px 10px;
    text-transform: capitalize;
  }
}

.e-col-padding {
  padding-left: 10px !important;
}

.daily-dashboard {
  .card {
    display: flex;
    align-items: start;
    justify-content: space-between;

    .e-card-section {
      max-width: 200px;
    }
  }
}

.e-main-detail {

  .e-main-detail-label {
    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #aaa;
    font-weight: 400;
  }

  .e-main-detail-value {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    padding-top: 5px;
    color: #202124;
  }
}

.e-ship-pdf-table {
  margin-top: 20px;

  .e-warehouse-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    border-radius: 4px;
    // border: 1px solid #06bee1;
    background-color: #e5e5e5;

    p {
      margin: 0px;
    }
  }

  .e-contain-pdf-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding-inline: 20px;

    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      padding-top: 5px;
      color: #202124;
      margin: 0px;
    }
  }
}

.filepond--root .filepond--credits {
  display: none !important;
}

.e-add-btn {
  width: auto;
  height: 35px;
  min-width: unset !important;
  background-color: #2541B2 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  margin-left: 8px !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  cursor: pointer;
  color: #fff;
  padding: 0 10px;
  white-space: nowrap;

  i {
    font-size: 1rem;
    color: #FFFFFF !important;
    height: unset !important;
    width: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 0px !important;
  }

  &:hover {
    background-color: #1e3283 !important;
  }
}
.e-border-none {
  border: none;
  border-bottom: 1px solid #848484;
}




// .main-vessel-ship-card {
// border: 1px solid #c0c9d7;
// background-image: linear-gradient(to bottom right, #0ea5e9, #0284c7) !important;

// p {
//   font-size: 1.5rem !important;
//   line-height: 2rem !important;
//   color: #fff !important;
//   font-weight: 500 !important;
// }

// .export-job-stats-title {
//   p {
//     font-size: .75rem !important;
//     line-height: 1rem !important;
//     text-transform: uppercase !important;
//     color: #e0f2fe !important;
//   }
// }
// }