body{
  background-color: #F8F9FA !important;

  .p-multiselect-panel{
    z-index: 1301 !important;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.login-area {
  padding-top: 130px !important;
  position: relative;
  height: 100vh;
  overflow: auto;
  
}

.login-form {
  width: 75%;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
  background: #fff;
}

.login-form h2 {
  font-size: 24px;
  color: #212529;
  font-weight: 600;
}

.login-input .MuiFormControl-root {
  width: 100%;
}

.login-input {
  width: 100%;
  position: relative;
  padding-left: 35px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.input-icn {
  position: absolute;
  left: 0;
  top: 21px;
}

.loginwith-btns button {

  margin: 0 5px;
  padding: 12px 15px;
  background-color: #fff;

}

.loginwith-btns button.google-btn,
.loginwith-btns button.otp-btn {
  width: 80%;
  margin: 20px auto;
}

.forgot {
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}

.forgot a {
  color: #9e9e9e;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
}

.login-input .MuiInput-underline:after {
  border-color: #4285f4;
}

.login-input .MuiFormLabel-root.Mui-focused {
  color: #4285f4;
  ;
}

/* .container-fluid {
    width: 100%;
    height: auto;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
} */



.input-control.ipt-with-icon {
  width: 100%;
  position: relative;
  /* padding-left: 34px; */
}

.ipt-with-icon i {
  position: absolute;
  left: 0;
  bottom: 23px;
  font-size: 19px;
}

.vsdetail-table {
  width: 100%;
}

.vsdetail-table table {
  width: 100%;
  border-collapse: collapse;
}

.vsdetail-table table tr td {
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  padding: 13px 0;
  color: #000;
  font-weight: 400;
}

.vsdetail-table table tr th {
  text-align: left;
  padding: 14px 0;
  font-size: 24px;
  font-weight: 500;
}

.vib-header h4 {
  font-weight: bold;
  color: #2196F3;
  margin: 0 0 3px 0;
}

.vib-header p {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  color: #333;
}

span.devider-full {
  width: 100%;
  height: 1px;
  background: #dfdfdf;
  display: flex;
}

.vib-detail {
  width: 100%;
  text-align: center;
  padding: 10px;
  border-right: 1px solid #dfdfdf;
}

.vib-detail.last {
  border-right: none;
}

.vib-detail h5 {
  margin: 0 0 3px 0;
  font-weight: 600;
  color: #000;
  font-size: 17px;
}

.vib-detail p {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
}

.color-green {
  color: #4CAF50 !important;
}

.color-light-green {
  color: #00BCD4 !important;
}

.color-red {
  color: #F44336 !important;
}

.warning {
  width: 100%;
  text-align: center;
  padding: 15px 0;
}

.container-fluid {
  background-color: #fff;
}

.makeStyles-button-174 {
  color: #FFF;
  border: none;
  cursor: pointer;
  margin: 1.3125rem 1px !important;
  padding: 12px 30px 10px;
  font-size: 17px !important;
  width: 100% !important;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: #999;
}

.makeStyles-primary-176 {
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
  background-color: #3d88ec !important;
}

.makeStyles-content-3 {
  padding: 30px 15px;
  margin-top: 70px;
  min-height: calc(100vh - 123px);
}

.makeStyles-mainPanelWithPerfectScrollbar-7 {
  overflow: hidden !important;
}

.addVessel-btn {
  background-color: #3d88ec !important;
  text-align: center;
  color: #ffffff;
  font-size: 25px;
  padding: 5px;
  width: 100%;
}

.buyVessel-btn {
  background-color: #499AFF !important;
  text-align: center;
  color: #ffffff;
  font-size: 25px;
  padding: 0px 5px;
}

.backVessel-btn {
  color: #000;
  font-size: 25px;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  align-self: center;

}

.Sidebar-sidebarWrapperWithPerfectScrollbar-57 {
  overflow: hidden !important;
  color: black;
}

.adminHeaderStyle {
  font-size: 1.3em;
  line-height: 1.4em;
  font-weight: 500;
  color: #3d88ec;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
}

.search-label {
  position: relative;
}

.input {
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  font-size: 15px;
  font-style: italic;
  color: #444444;
  box-sizing: border-box;
  outline: none;
}

.search-icon {
  position: absolute;
  /* top: 0px;  */
  font-size: 24px;
  color: #555555;
  right: 30px;
  margin-top: 30px;
}

body {
  font-size: 62.5%;
}

.main-container {
  width: 400px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.form-container {
  bottom: 100px;
}

legend.form-label {
  font-size: 1.5rem;
  color: #3d658f;
}

.control-label {
  font-size: 0.8rem;
  font-weight: bold;
  color: #52657a;
}

.form-control {
  font-size: 1rem;
}

.form-hint {
  font-size: 0.6rem;
  line-height: 1.4;
  margin: -5px auto 5px;
  color: #999;
}

.form-hint.error {
  color: #C00;
  font-size: 0.8rem;
}

button.btn {
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: 600;
}

.password-count {
  bottom: 16px;
  right: 10px;
  font-size: 1rem;
}

.strength-meter {
  position: relative;
  height: 3px;
  background: #DDD;
  margin: 7px 0;
  border-radius: 2px;
}

.strength-meter:before,
.strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #FFF;
  border-style: solid;
  border-width: 0 6px 0;
  position: absolute;
  width: calc(20% + 6px);
  z-index: 10;
}

.strength-meter:before {
  left: calc(20% - 3px);
}

.strength-meter:after {
  right: calc(20% - 3px);
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength='0'] {
  width: 20%;
  background: darkred;
}

.strength-meter-fill[data-strength='1'] {
  width: 40%;
  background: orangered;
}

.strength-meter-fill[data-strength='2'] {
  width: 60%;
  background: orange;
}

.strength-meter-fill[data-strength='3'] {
  width: 80%;
  background: yellowgreen;
}

.strength-meter-fill[data-strength='4'] {
  width: 100%;
  background: green;
}



/* Multi Level Filter css */

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /* -webkit-font-feature-settings: "liga"; */
  -webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
     * The idea is that it is easy to extend/override builtin styles with very little effort.
     */

.mdl-demo .dropdown-trigger>span:after {
  font-size: 12px;
  color: #555;
}

.mdl-demo .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: #555;
  white-space: pre;
  margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: middle;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl-demo .checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  transition: all 0.3s ease-in-out;
}

.mdl-demo .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #2196f3;
}
.p-dialog-header .p-dialog-header-icon.p-dialog-header-close {
  display: none !important;
}