.step-line {
    width: 50%;
    margin: 0 auto;
    position: relative;

    .highlighted-menu {
        top: 16px;
        left: 0px;
        color: #FFF;
        cursor: pointer;
        padding: 12px;
        position: absolute;
        z-index: 1;
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        border-radius: 4px;
        text-transform: uppercase;
        -webkit-font-smoothing: subpixel-antialiased;
        box-shadow: 0 4px 20px 0px #00000024, 0 7px 10px -5px #e91e6366;
        background-color: #415ba9;
        transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
        width: 50%;
        transform: translate3d(-8px, 0px, 0px);
    }
}

.step-line ul {
    list-style: none;
    margin-top: 20px;
    padding-left: 0;
    margin-bottom: 0;
    background-color: rgba(200, 200, 200, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    li {
        width: 50%;
        display: inline-block;
        position: relative;
        text-align: center;
        margin-left: 0;
    }

    a {
        color: #555555 !important;
        border: 0 !important;
        cursor: pointer;
        display: block;
        padding: 10px 15px;
        position: relative;
        font-size: 12px;
        min-width: 100px;
        text-align: center;
        transition: all .3s;
        font-weight: 500;
        line-height: 18px;
        border-radius: 30px;
        text-transform: uppercase;
        text-decoration: none;
    }
}

.title-user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-access-contianer {
    .heading-area {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading-area-right {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 33px;
            /* identical to box height */

            display: flex;
            align-items: center;

            color: #343434;
        }
    }
    .main-access-card-master {
        background: #FFFFFF;
        border-radius: 10px;
        height: calc(100vh - 162px);
        overflow: auto;
    }
    .main-access-card-master1 {
        background: #FFFFFF;
        border-radius: 10px;
        // height: calc(100vh - 162px);
        overflow: auto;
    }

    .main-access-card {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 30px;
        height: calc(100vh - 162px);
        overflow: auto;

        .user-list-accordion {
            margin-bottom: 20px;
            cursor: pointer;

            .user-item-accordion {
                box-sizing: border-box;

                background: #FFFFFF;
                border-left: 15px solid rgba(23, 104, 172, 0.84);
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
                border-radius: 11px;
                padding: 15px 10px;

                .user-profile-info {

                    .profile-pic-box {
                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }

                    .user-personal-info {
                        display: flex;
                        align-items: center;
                    }

                    .access-user-detail {
                        padding-left: 15px;

                        .access-user-name {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 21px;
                            /* identical to box height */

                            color: #343434;

                        }

                        .access-user-email {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 0px;

                            color: #7D7F80;

                        }
                    }


                }

                .access-update-button {
                    display: flex;
                    justify-content: end;
                    align-items: center;

                    button {
                        background: #2541B2;
                        border-radius: 6px;
                        border: none;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 9px;
                        /* identical to box height, or 64% */

                        text-align: center;
                        text-transform: capitalize;

                        color: #FFFFFF;
                        padding: 13px 28px;
                        cursor: pointer;
                    }

                    button:hover {
                        background: #1d3596;
                    }
                }
            }
        }

        .access-drawer {
            -webkit-transition: all 0.8s ease-in-out;
            -moz-transition: all 0.8s ease-in-out;
            -ms-transition: all 0.8s ease-in-out;
            transition: all 0.8s ease-in-out;
            max-height: 0;

            // display: none;
            // opacity: 0;
            .parent-module-area {
                visibility: hidden;
            }

            .child-module-area {
                visibility: hidden;
            }
        }

        .access-drawer.active {
            display: block;
            max-height: calc(100vh - 300px);
            overflow: auto;
            box-sizing: border-box;
            padding: 20px;
            margin-top: 15px;
            // opacity: 1;
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            border-radius: 10px;

            input {
                box-sizing: border-box;

                border: 1px solid #06BEE1;
            }

            .parent-module-area {
                border-right: 2px solid #EBEBEB;
                visibility: visible;

                label {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 18px;
                    text-align: center;

                    color: #424A65;
                    padding-left: 10px;
                }

                .parent-module-input {
                    border-bottom: 2px solid #EBEBEB;
                    margin-right: 10px;
                    padding: 8px;
                    display: flex;
                    align-items: center;
                }
            }

            .child-module-area {
                border-bottom: 2px solid #EBEBEB;
                display: flex;
                flex-wrap: wrap;
                height: 100%;
                visibility: visible;

                .child-module-input {
                    label {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 18px;
                        text-align: center;

                        color: #424A65;
                        padding-left: 10px;
                    }

                    padding: 8px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        /* styles.css */

.mb-3 {
    margin-bottom: 1rem;
  }
  
  .border {
    border: 1px solid #e5e7eb;
  }
  
  .rounded-lg {
    border-radius: 0.5rem;
  }
  
  .bg-gray-50 {
    background-color: #f9fafb;
  }
  
  .hover\:bg-gray-100:hover {
    background-color: #f3f4f6;
  }
  
  .relative {
    position: relative;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .p-2 {
    padding: 0.5rem;
  }
  
  .absolute {
    position: absolute;
  }
  
  .-left-3\.5 {
    left: -0.875rem;
  }
  
  .h-8 {
    height: 2rem;
  }
  
  .w-8 {
    width: 2rem;
  }
  
  .bg-white {
    background-color: #ffffff;
  }
  
  .text-cyan-500 {
    color: #06b6d4;
  }
  
  .shadow {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .border-cyan-200 {
    border-color: #a5f3fc;
  }
  
  .ml-7 {
    margin-left: 1.75rem;
  }
  
  .w-full {
    width: 100%;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  
  .border-t {
    border-top: 1px solid #e5e7eb;
  }
  
  .bg-white {
    background-color: #ffffff;
  }
  
  .border-l-2 {
    border-left-width: 2px;
  }
  
  .grid {
    display: grid;
  }
  
  .grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  
  .col-span-1 {
    grid-column: span 1 / span 1;
  }
  
  .p-3 {
    padding: 0.75rem;
  }
  
  .space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  
  .ml-2 {
    margin-left: 0.5rem;
  }
  
  .bg-slate-50 {
    background-color: #f8fafc;
  }
  
  .border-l-cyan-500 {
    border-left-color: #06b6d4;
  }
  
  .hover\:bg-cyan-50:hover {
    background-color: #ecfeff;
  }
  
  .text-cyan-500 {
    color: #06b6d4;
  }
  
  .h-6 {
    height: 1.5rem;
  }
  
  .w-6 {
    width: 1.5rem;
  }
  
  .p-button-label {
    font-size: 0.875rem;
  }
  
  .gap-4 {
    gap: 1rem;
  }
  
    }

}

.user-role-badge {
    background: #FF9800;
    border-radius: 34px;
    width: 36%;
    padding: 7px 25px;
    display: flex;
    justify-content: center;

    span {

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        color: #FFFFFF;

    }
}

.e-main-access {
  .MuiTabs-root {
  .MuiTabs-indicator {
    background-color: #06b6d4 !important;
    height: 2px !important;
  }
}
}