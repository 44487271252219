@media screen and (max-width: 991px) {
    .login-form {
        width: 65%;
    }
    .main-auth-area {
        height: 100vh;
    }
    .down-arrow {
        display: flex;
    }
    .main-header{
        width: 100%;
    }
    .login-outer1{
        display: none !important;
    }
    .top-menu-area,.stock-outer{
        padding-left: 0 !important;
    }
    .vessel-details-item-img{
        height: 150px !important;
    }
    .main-card,.card-lg-section {
        height: auto !important;
    }
    .stock-outer header {
        align-items: flex-start !important;
        flex-wrap: wrap-reverse !important;
        .searchbar-area{
            margin: 0 !important;
        }
    }

    .main-table{
        height: calc(100vh - 196px);
    }

    
}