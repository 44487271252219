@import url('https://fonts.googleapis.com/css2?family=Delius+Swash+Caps&display=swap');

$primaryColor: #06BEE1;
$secondaryColor: #2541B2;
$primaryFont:#202124;
$primaryBlack: #343434;
$primaryWhite: #FFFFFF;
$mainDarkBlue: #03256C;
$primaryDarkColor: #1768AC;
$primarygray: #7A7878;
$labelColor: #a39e9e;
$inputBorderColor: #D5D5D5;
$lightPurpleColor: #736cc7;
// 03256C