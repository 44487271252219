//--------------- dashboard component -------------------//
.truck-list-container {
    background-color: $primaryWhite;
    height: calc(100vh - 196px);
    overflow: auto;
    border-radius: 8px;
    box-shadow: 0px 2px 10px 8px rgba(0, 0, 0, 0.02);

    .main-card {
        height: calc(100vh - 256px);
    }
}

.truck-list-tabcontainer {
    .MuiAppBar-root {
        height: 45px !important;

        .MuiTabs-root {
            height: 45px !important;

        }
    }
}

footer {
    display: none !important;
}

.stock-outer {

    .global-searchbar-area {
        height: 35px;
        border-radius: 6px;
        border: 1px solid #03256c !important;
        background: transparent;
        display: flex;
        align-items: center;
        margin-left: 8px !important;
    }
    header {
        flex-direction: row;
        justify-content: space-between;
    }

    .MuiTabs-indicator {
        background-color: $primaryColor;
    }

    .MuiTabs-root {
        min-height: 40px !important;

        .MuiTabs-fixed {
            .MuiTabs-flexContainer {
                button {
                    min-height: 45px !important;

                    &.Mui-selected {
                        .MuiBadge-badge {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.main-tabs {
    margin: 10px 0 0;

    .MuiTabs-indicator {
        display: none;
    }

    .MuiTabs-scroller {
        margin: 0.25rem 0px !important;

        .MuiTabs-flexContainer {
            .tab-items {
                margin-left: 0px !important;
                min-width: 200px !important;
                width: auto !important;
                border: 1px solid $secondaryColor;

            }
        }
    }

    .MuiTabs-scrollButtons {
        display: none;
    }

    // @media screen and (max-width: 991px) {
    //     .MuiTabs-scrollButtons {
    //         display: block;
    //     }
    // }
}

.main-tabs {
    min-height: 40px !important;
    position: relative;

    .MuiTabs-scroller {
        height: 40px;
    }
}


.MuiBox-root {
    height: 100%;
}

.basic-header {
    .p-multiselect .p-multiselect-trigger {
        display: none;
    }
}

.main-table {
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    overflow: auto;
    color: $primaryFont !important;

    .p-paginator .p-paginator-current {
        display: flex !important;
        align-self: center;

    }


    .p-datatable-scrollable-header {
        // border-bottom: 1px solid$secondaryColor;
        // padding-bottom: 7px;
        // background-color: #fff;
    }

    .p-datatable-scrollable-wrapper {
        height: calc(100% - 39px);
        ::-webkit-scrollbar {
            display: none;
        }

        .p-datatable-scrollable-view {

            height: calc(100% - 41px);

            .p-datatable-scrollable-body {
                height: 100%;
            }
        }
    }

    table {
        * {
            color: $primaryFont;
        }

        tr {
            &:nth-child(even) {
                background-color: #f3f3f3;
            }

            &:hover {
                background-color: #e5e5e5;

            }
        }

        th {
            background-color: #fff !important;
            width: auto !important;
            min-width: 550px !important;
            color: #7A7878 !important;
            border: none !important;
            padding: 0 10px !important;
            position: relative;

            input {
                width: -webkit-fill-available !important;
            }

            &:focus {
                box-shadow: none !important;
            }

            span {
                color: #7A7878 !important;
                font-weight: 400;
            }

        }


        td {
            width: auto !important;
            min-width: 550px !important;
            color: $primaryFont !important;
            border: none !important;
            padding: 0px !important;
            font-size: 14px !important;
            height: 40px;
            font-weight: 400 !important;
            vertical-align: middle !important;
            overflow: hidden;
            text-overflow: ellipsis;
            // white-space: nowrap;
            padding: 0 10px !important;

            &>div {
                &>div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // white-space: nowrap;
                }
            }

            input {
                width: -webkit-fill-available !important;
            }

            &:focus {
                box-shadow: none !important;
            }

            a {

                display: flex;
                justify-content: space-between;
                position: relative;
                color: #1b76b1;

                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 20px;
                    background-color: #1b76b1;
                    left: 0;
                    bottom: 0;
                    transition: 0.5s;
                }

                &:hover {
                    &::after {
                        width: 30px;

                    }
                }

                span {
                    color: #1b76b1;

                }

            }

            span,
            a {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

        }
    }

    .p-datatable-tbody {
        .p-inputtext {
            border-radius: 6px;
            border: 1px solid #B0B3C8;
        }

        .table-icon {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            button {
              width: 32px;
              height: 32px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: none;
              border: none;
              outline: none;
              color: '#504973';
              font-size: 18px;
              margin-right: 8px;
              background-color: transparent !important;

              // &:hover{
              //     background-color: $lightGrayColor !important;
              // }
              &:active {
                background-color: '#1F70BA' !important;
                color: '#FFF';
              }

              .icon-view {
                font-size: 22px;
              }
            }
          }
    }
}

.table-td td {
    height: 45px !important;
}

.report-container {
    height: calc(100vh - 152px);
    overflow: auto;
}

.tab-header-design {
    display: inline-block;

    h4 {
        background-color: transparent !important;
        width: fit-content !important;
        color: $primaryColor !important;
        text-transform: uppercase;
        border-bottom: 2px solid $primaryColor !important;
        font-size: 15px;
        font-weight: 500;
        margin: 0px !important;
        padding: 11px 20px;
    }
}

.border-left-primary {
    border-left: 6px solid $primaryColor;
}

.border-left-green {
    border-left: 6px solid #2DC433;
}

.border-left-Yellow {
    border-left: 6px solid #FF9800;
}

.border-left-secondary {
    border-left: 6px solid #03256C;
}

.card-head {
    background-color: transparent !important;
    color: $primaryFont !important;
    width: 100%;
}


.p-inputswitch {
    width: 33px !important;
    height: 16px;
    // margin-left: 11px;

}

.p-inputswitch-checked {
    margin-left: 11px;

}

.p-inputswitch .p-inputswitch-slider {
    background-color: #e5e5e5;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #1768AC;

}

.p-inputswitch-checked .p-inputswitch-slider:before {
    top: 3px;
    margin: 0;
    left: 0px !important;
    height: 10px;
    width: 10px;
    background-color: #fff !important;

}

.p-inputswitch .p-inputswitch-slider:before {
    top: 3px;
    margin: 0;
    left: 4px;
    height: 10px;
    width: 10px;
    background-color: #1768AC;

}

td {

    label>span {
        min-width: 56px;
    }
}

.card-section {
    height: calc(100vh - 196px);
    overflow: auto;
    background: transparent !important;
    box-shadow: 0px 2px 10px 8px rgba(0, 0, 0, 0.02) !important;

}

.card-lg-section {
    height: calc(100vh - 149px);
    overflow: auto !important;
    background: transparent !important;
    box-shadow: 0px 2px 10px 8px rgba(0, 0, 0, 0.02) !important;

}

.p-overflow-hidden1 {
    overflow: hidden !important;

}

.bs-none {
    box-shadow: none !important;
}

.bg-white {
    background-color: #fff !important;
}

.br-10 {
    border-radius: 10px;
}

.customize-card {
    background: $primaryWhite !important;
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.warning-message {
    padding: 10px;
    background-color: #ffdddd;
    color: #d9534f;
    border: 1px solid #d9534f;
    border-radius: 4px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // font-size: medium;
    font-weight: bold;
}

.warning-message p {
    margin: 0;
    margin-right: 10;
}

.warning-message button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.warning-message button:hover {
    background-color: #c9302c;
}

.add-details {
    h4 {
        font-weight: 500;
    }

    height: calc(100vh - 245px);
    overflow-y: auto;
    overflow-x: hidden;
}

.card-body-heading {
    font-weight: 500;
    font-size: 18px;
    color: #06BEE1;
}

.add-detail-img {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    background-color: #7A7878;
    flex-shrink: 0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.add-detail-img-bg1 {
    background-color: #BEFFC180;
}

.add-detail-img-bg2 {
    background-color: #FFE1B680;
}

.add-detail-img-bg3 {
    background-color: #AAFFFF80;
}

.add-detail-lable {
    color: $primarygray;
    font-size: 12px;
}

// .add-driver {
//     width: 80%;
//     margin: auto;

//     @media (max-width: 991px) {
//         width: 100%;

//     }
// }

.driver-user-img {
    width: 150px;
    height: 150px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.add-tags {
    .tag {
        display: inline-block;
        border-radius: 5px;
        color: #fff;
        margin-right: 10px;
        padding: 4px 6px;
        font-size: 14px;
        margin-bottom: 10px;

    }

    .tag-green {
        background-color: #2ECB71;
    }

    .tag-blue {
        background-color: #06BEE1;

    }

    .tag-purple {
        background-color: #77048B;

    }
}

.p-ai-start {
    align-items: flex-start !important;
}

.group-img {
    width: 75px;
    height: 75px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    // margin-bottom: 20px;
    flex-shrink: 0;
    font-size: 30px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;

}

.member-group {

    .member-each {
        border-radius: 25px;
        background-color: #e5e5e5;
        display: inline-flex;
        align-items: center;
        padding-right: 7px;
        margin-right: 10px;
        // margin-bottom: 10px;

        .member-each-img {
            width: 30px;
            height: 30px;

            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .member-each-name {
            padding: 0 10px;
        }
    }
}

// ==========vessel details===========
.vsdetail-table {
    .vessel-details-info {
        padding-top: 20px;

        .vessel-details-header {
            font-weight: 500;
            font-size: 24px;
            color: #000;
        }

        .vessel-details-id {
            display: inline-block;
            background-color: rgba($color: #1768AC, $alpha: 0.22);
            border-radius: 10px;
            padding: 7px 20px;
            color: #03256C;
            margin: 10px 0;
            font-weight: 500;
        }

        .vessel-attachments-area {
            display: inline-flex;
            align-items: center;

            .vessel-attachments-item {
                width: 120px;
                height: 120px;
                border-radius: 5px;
                margin-right: 10px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;


                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                i {
                    font-size: 60px;
                    color: #03256C;
                }
            }
        }
    }

    .vessel-details-item-info {
        padding: 20px;
        border: 1px solid #E5E5E5;
        border-radius: 10px;

        .vessel-details-message {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 6px;
            padding: 7px 12px;
            margin-bottom: 20px;

            .icon {
                display: flex;

                i {
                    font-weight: 600;
                    font-size: 20px;
                }
            }
        }

        .vessel-details-item-rate {
            font-weight: 500;
            font-size: 24px;
            color: #000;

            // padding-bottom: 15px;
            span {
                color: $primarygray;
                font-size: 14px;
                vertical-align: baseline;
                font-weight: 400;

            }
        }

        .vessel-details-item-img {
            border-radius: 10px;
            height: 80px;
            width: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .vessel-details-item-name {
            font-weight: 500;
            border-radius: 8px;
            background-color: #f3f3f3;
            padding: 7px 15px;
        }
    }
}

// ==========vessel edit===========

.vessel-edit-header {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 15px 0 -10px;
}

.edit-vessel-section {
    padding: 20px;

    .select .MuiFormLabel-root {
        font-size: 14px !important;
    }

    .select-date .MuiFormLabel-root {
        font-size: 14px !important;

    }

    .icon-image-file,
    .icon-report-file {
        font-size: 22px;
        position: absolute;
        right: 0;
        bottom: 15px;
    }

    .icon-DateTime {
        font-size: 22px;
        position: absolute;
        right: 0;
        bottom: 7px;
    }

    .edit-vessel-ivon .icon-report-file,
    .edit-vessel-ivon .icon-DateTime {
        bottom: 10px;
    }
}

.custom-date-input {
    .icon-DateTime {
        font-size: 22px;
        position: absolute;
        right: 0;
        bottom: 7px;
    }
}

.edit-vessel-section .input-control {

    .MuiInputBase-input,
    .view-file-name {
        border-bottom: 1px solid #B0B3C8 !important;
    }

    .MuiInput-underline:before {
        border-bottom: 1px solid #B0B3C8 !important;
    }
}

// .selling-check-box{}
.order-section .request-text-box h5 {
    font-weight: 500;
    margin-bottom: 5px;
    padding: 0 5px;
}

.order-badge {
    span {
        font-size: 13px;

        i {
            font-size: 14px;

        }
    }
}

// .custom-dialog {
// padding: 20px;
// background-color: #fff;
.MuiDialog-container {
    // height: auto !important;
}

.custom-dialog-header {
    color: #343434;
    border-bottom: 1px solid #06BEE1;
    padding: 0 0 10px !important;
    // margin-bottom: 10px !important;

    h2 {
        &>div {
            font-weight: 600 !important;
            font-size: 18px !important;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.dialog-content {
    border-radius: 4px;
}

// }
.MuiDialog-paper {
    padding: 15px;
    border-radius: 10px !important;
}

// .network-dialog {
.MuiDialogContent-root {
    padding: 0 !important;
}

// .dialog-width{
//     width: 450px;
// }

.dialog-close {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #f9f9f9;
    cursor: pointer;

    i {
        color: #1768AC;
    }

    &:hover {
        background-color: #efefef;
    }

    &:active {
        background-color: #03256c !important;

        i {
            color: #FFFFFF !important;
        }
    }


}

.dialog-content-area {
    border-radius: 5px;
    padding: 10px !important;
    width: 350px !important;
    // min-height: 150px;

    .icon-view {
        font-size: 13px;
        color: #202124;

    }

    .icon-close-eye {
        color: #202124;

    }
}

.dialog-area {
    max-height: 68vh;
    overflow: hidden;
}

.CHA-dialog-content-area {
    width: 450px !important;
}

.dialog-content-area1 {
    border-radius: 5px;
    padding: 10px;
    width: 350px;
    margin: 15px 0;
    border: 1px solid #e5e5e5;

    .icon-view {
        font-size: 13px !important;
        color: #202124;

    }

    .icon-close-eye {
        color: #202124;

    }
}


.dialog-user-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    align-self: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.dialog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.dialog-footer1 {
    margin-left: -15px !important;
    margin-right: -15px !important;
    margin-bottom: -15px !important;
    margin-top: 10px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.21) !important;
    padding: 15px !important;

    .primary-btn,
    .primary-btn1 {
        height: 35px !important;
        min-height: 35px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 0px 12px !important;
        min-width: fit-content !important;
        line-height: 1 !important;
        outline: none !important;
        font-size: 14px;
        margin: 0 !important;
        margin-left: 8px !important;
        transition: 0s !important;



    }
}

.lb-canvas img {
    object-fit: contain !important;
}

.network-details {
    align-self: center;

    h3 {
        margin: 0 !important;
        font-size: 18px !important;
        font-weight: 600;
    }

    h2 {
        margin: 0 !important;
        font-size: 16px !important;
        font-weight: 500;

    }
}

// }

.buy-modal {

    .MuiPaper-root {
        width: 100%;
        max-width: 450px;
    }


}


.current-trip-driver {
    .p-accordion {
        .p-accordion-tab {
            border: 1px solid #E5E5E5;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 15px;

            .p-disabled {
                opacity: 1 !important;

                .p-accordion-header-link {
                    background: transparent !important;
                    border: none !important;
                    flex-direction: row-reverse;
                    padding: 0;
                }

                .p-accordion-toggle-icon {
                    cursor: not-allowed !important;

                    &::before {
                        color: #bebebe;
                    }
                }
            }

        }

        .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
            background: transparent !important;
            border: none !important;
            flex-direction: row-reverse;
            padding: 0;

            &:focus {
                box-shadow: none !important;
            }

        }

        .p-accordion-header:not(.p-highlight):not(.p-disabled) .p-accordion-header-link {
            background: transparent !important;
            border: none !important;
            flex-direction: row-reverse;
            padding: 0;

            &:focus {
                box-shadow: none !important;
            }

        }

        .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
            background-color: #f3f3f3;
            color: #03256C;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            margin-right: 0px;
            flex-shrink: 0;
        }

        .p-accordion-header-text {
            width: 100%;
            font-weight: normal;
        }


        .truck-status {
            color: #343434;
            background-color: rgba(255, 153, 0, 0.3);
            border-radius: 6px;
            padding: 7px 15px;
        }

        .weight-diff {
            padding: 7px 20px;
            border-radius: 25px;
            background-color: rgba(244, 67, 54, 0.3);
            color: #F44336;
        }

        .p-accordion-content {
            border: none;
            padding: 20px 0 0px;
            border-top: 1px solid #E5E5E5 !important;
            margin-top: 15px;


            .trip-user-img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: hidden;
                object-fit: cover;
                margin-right: 20px;
                flex-shrink: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .driver-info-area {
                margin-bottom: 16px;

                .driver-info-label {
                    color: #858585;
                    margin-bottom: 10px;
                }

                .driver-info-detail {
                    color: #202124;
                    // margin-bottom: 10px;


                }

                .weight-paper {
                    background-color: #EAE8FF;
                    border-radius: 5px;
                    padding: 7px;
                    font-size: 13px;

                    .wp-icon {
                        width: 20px;
                        height: 20px;
                        background-color: #fff;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    }

                    label {
                        cursor: pointer;

                        input {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.driver-timeline {


    .custom-marker {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .p-timeline-event-content {
        min-width: 120px !important;
        margin-bottom: 20px;
    }

    .timeline-area {
        height: calc(100% - 86px);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .custom-card-ftr {
        position: static !important;
        padding: 10px 10px 0 !important;
    }
}


.edit-profile-modal {

    .modal-container {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.336);
        position: fixed;
        width: 750px;
        height: 400px;
        left: calc(50% - 375px);
        top: calc(50% - 200px);
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
        z-index: 1203;

    }

    .profedit-img {
        display: flex;

        svg {
            width: 100%;
        }
    }

    .edit-prof-detail {
        h3 {
            padding: 0px 10px;
            margin: 0 0 30px;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            font-size: 20px;
        }

        p {
            padding: 0px 20px;
            margin-bottom: 30px;
            text-align: center;

        }

        .primary-btn1,
        .primary-btn2 {
            padding: 6px 30px !important;
        }
    }

    &::after {
        content: ' ';
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #00000099;
        z-index: 1202;
    }



    #form2 {
        animation: form 6s;
        transform: translate(145px, -73px);


    }

    @keyframes form {
        0% {
            transform: translate(0px);
        }

        50% {
            transform: translate(0px);
        }

        100% {
            transform: translate(145px, -73px);
        }

    }

    #formArea {
        animation: formArea 4s;
        transform: translate(20px, 20px);

    }

    @keyframes formArea {
        0% {
            transform: translate(0px);
        }

        25% {
            transform: translate(0px);
        }

        100% {
            transform: translate(20px, 20px);
        }

    }

    #frame1,
    #frame2,
    #frame3 {
        animation: frame 4s;

    }

    @keyframes frame {
        0% {
            opacity: 0;
        }

        25% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }

    }

    #plant1 {
        animation: wave2 3s infinite;
        transform-origin: bottom;
        transform-box: fill-box;

    }

    #plant2 {
        animation: wave2 3s infinite reverse;
        transform-origin: bottom;
        transform-box: fill-box;

    }


    // @keyframes wave {
    // 	0% {
    // 		transform: rotate(3deg);
    // 	}
    // 	25%{
    // 		transform: rotate(-4deg);
    // 	}
    // 	50% {
    // 		transform: rotate(2deg);
    // 	}
    // 	75% {
    // 		transform: rotate(-6deg);
    // 	}
    // 	100% {
    // 		transform: rotate(3deg);
    // 	}
    // }

    @keyframes wave2 {
        0% {
            transform: rotate(0deg);
        }

        25% {
            transform: rotate(-5deg);
        }

        50% {
            transform: rotate(5deg);
        }

        75% {
            transform: rotate(-3deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    #hand {
        animation: hand 3s infinite;
        transform-origin: right;
        transform-box: fill-box;
    }

    @keyframes hand {
        0% {
            transform: rotate(0deg);
        }

        25% {
            transform: rotate(10deg);
        }

        50% {
            transform: rotate(-10deg);
        }

        100% {
            transform: rotate(0deg);
        }

    }
}

.stock-modal{
    .MuiDialog-container {
        height: 100% !important;
        // .p-datatable-scrollable{
        //     width: auto;
        // }
    }
}


.profile-details-modal {
    .MuiDialog-container {
        height: 100% !important;
        align-items: flex-end;
    }

    .custom-dialog-header {
        border: none !important;
    }

    .profile-contact {
        padding-top: 10px;
    }

    .Profile-dialog-img {
        // width: 100%;
        width: 175px;
        height: 175px;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 40px;

        img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
        }
    }

    .MuiDialog-paper {
        max-width: 815px;
    }

    .vessel-details-header {
        font-size: 18px;
        font-weight: 600;
    }

    .add-network-btn {
        border-radius: 4px !important;
        padding: 6px 20px !important;
        display: inline-block !important;
        color: #fff !important;
        box-shadow: none !important;
        background-color: #FC873C;
    }

    .profile-contact {
        display: flex;
        align-items: center;

        .contact-icon {
            width: 30px;
            font-size: 24px;
            color: $primaryColor;

            i {
                font-size: 18px;
                font-weight: 600;
            }

            .icon-mail {
                font-size: 14px;
                font-weight: 400;
            }
        }

        .contact-detail {
            font-size: 16px;
            // color: $primaryColor;
            font-weight: 500;
        }
    }

    .profile-detail-area {
        background-color: #F8F9FD;
        border-radius: 5px;
        padding: 10px;


    }
}

.profile-content .profile-table .table-label-info span {
    margin-left: 20px;
}

.profile-table {

    .table-label {
        color: #7A7878;
        font-size: 14px;
    }

    .table-label-info {
        padding: 0 10px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        span {
            font-size: 16px;
            text-align: right;
            font-weight: 500;
            cursor: pointer;
        }
    }
}

.licence-details-modal .MuiDialog-paper {
    max-width: 800px !important;
}

.licence-upload-section {
    border-radius: 5px;
    padding: 10px !important;
    height: calc(100vh - 205px);
    overflow: auto;

    // width: 750px !important;
    .licence-warning {
        background: rgba(255, 152, 0, 0.25);
        padding: 10px 20px;
        border-radius: 10px;
        color: #202124;
        margin-bottom: 20px;
        font-weight: 400;
    }

    .licence-upload-imgs {

        .licence-img-upload-section {
            padding: 20px;
            border-radius: 10px;
            border: 2px dashed #E5E5E5;
            background-color: #FCFCFC;
            height: 100%;

            &.active {
                background-color: #F5F7FB;

            }

            .licence-upload-head {
                text-align: right;
                color: #202124;
                font-size: 15px;
                font-weight: 500;
                padding-bottom: 15px;
            }

            .licence-main-img {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 150px;
                margin-bottom: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

.truck-doc-upload-modal .MuiDialog-paper {
    max-width: 800px !important;
    width: 800px !important;
}

.truck-doc-upload-modal {
    .custom-dialog-header {
        border-bottom: none;
    }

    .truck-doc-upload-section {
        padding: 20px;
        border: 1px solid #fff;
        height: 100%;
        height: calc(100vh - 205px);
        overflow: auto;

        .filetype {
            display: none;
        }

        .upload-img-truck-area {
            margin: 0 20px;
            text-align: center;

            .edit-truck-image {
                width: 150px;
                height: 150px;
                margin: 0 auto;
                border-radius: 10px;
                position: relative;
                margin-bottom: 7px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .no-img-uploaded {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px dashed #E5E5E5;

                img {
                    width: 75px;
                    height: 75px;
                    object-fit: contain;
                }
            }

        }

        .uplaod-doc-details {
            padding: 20px;
            border-radius: 10px;
            background-color: #F8F9FD;

            .truck-details-header {
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
}

.upload-img-truck-area {
    margin: 0 20px;
    text-align: center;

    .edit-truck-image {
        width: 150px;
        height: 150px;
        margin: 0 auto;
        border-radius: 10px;
        position: relative;
        margin-bottom: 7px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .no-img-uploaded {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed #E5E5E5;

        img {
            width: 75px;
            height: 75px;
            object-fit: contain;
        }
    }

}

.assign-truck-modal .MuiDialog-paper {
    max-width: 800px !important;
    width: 800px !important;
}

.assign-truck-modal {
    .custom-dialog-header {
        border-bottom: none;
    }

    h2 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
    }

    .assign-truck-section {
        padding: 20px;
        height: 100%;
        overflow: hidden;

        .assign-truck-details {
            background-color: #F8F9FD;
            border-radius: 10px;
            padding: 16px 8px 0;
            margin-top: 20px;
            height: calc(100vh - 340px);
            overflow: auto;


            .assign-truck-box {
                background-color: #fff;
                border-radius: 10px;
                padding: 20px;
                margin-bottom: 16px;

                .assign-truck-no {
                    font-size: 18px;
                    font-weight: 500;
                }

                .assign-truck-label {
                    font-size: 14px;
                    color: #7A7878;
                }

                i {
                    color: #06BEE1;
                    margin-right: 10px;
                    font-size: 18px;
                }

                .assign-truck-tag {
                    width: 60px;
                    height: 60px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    font-weight: 600;
                }

                .primary-btn2 {
                    background-color: #2541B2;
                    width: 100%;
                    cursor: pointer;
                }

            }

        }
    }
}



.add-bill-entry {
    .main-add-bill-area {
        height: calc(100% - 50px);
        overflow: auto;
    }

    .vessel-details-item-info {
        background-color: #F8F9FD;
        padding: 15px;
        border-radius: 10px;
        margin-top: 20px;
        height: 100%;

        .vessel-id-tag {
            background-color: #06BEE1;
            color: #fff;
            border-radius: 4px;
            padding: 5px 12px;
            display: inline-block;
        }

        .vessel-header-tag {
            margin: 0;
            font-weight: 600;
            font-size: 16px;
        }
    }
}

.main-table {
    .p-datatable-thead {
        border-bottom: 1px solid #2541b2;
        height: 35px;
        background-color: #fff;
    }
}

.table-calender {
    .p-calendar {
        width: -webkit-fill-available !important;
    }

    display: flex;
    align-items: center;
    position: relative;

    i {
        position: absolute;
        right: 8px;
        font-size: 16px;
        padding-top: 4px;
    }
}

.bill-of-entry {
    .p-accordion {
        .p-accordion-tab {
            background-color: #F8F9FA;
            border-radius: 10px;
            padding: 15px;
            margin-bottom: 15px;
            position: relative;

            .p-disabled {
                opacity: 1 !important;

                .p-accordion-header-link {
                    background: transparent !important;
                    border: none !important;
                    flex-direction: row-reverse;
                    padding: 0;
                    align-items: flex-start;
                }

                .p-accordion-toggle-icon {
                    cursor: not-allowed !important;

                    &::before {
                        color: #bebebe;
                    }
                }
            }

        }

        .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
            background: transparent !important;
            border: none !important;
            flex-direction: row-reverse;
            padding: 0;
            align-items: flex-start;


            &:focus {
                box-shadow: none !important;
            }

        }

        .p-accordion-header:not(.p-highlight):not(.p-disabled) .p-accordion-header-link {
            background: transparent !important;
            border: none !important;
            flex-direction: row-reverse;
            padding: 0;
            align-items: flex-start;


            &:focus {
                box-shadow: none !important;
            }

        }

        .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
            color: #03256C;
            padding-right: 7px;
            margin-top: 10px;
            margin-right: 0px;
            flex-shrink: 0;
            transform: rotate(90deg);
        }

        .p-accordion-tab-active .p-accordion-toggle-icon {
            margin-top: 4px !important;
            padding-right: 0px !important;
            transform: rotate(-90deg) !important;
            width: 23px;
        }

        .p-accordion-header-text {
            width: 100%;
            font-weight: normal;
        }

        .p-accordion-content {
            background-color: transparent;
            border: none !important;
            padding: 0 !important;

            .bill-detail-top {
                display: flex;
                justify-content: flex-end;
                border-top: 2px solid #C4C4C4;
                padding: 10px 0;
                margin-top: 15px;
            }

            .bill-details-section {
                background-color: #fff;
                border-radius: 8px;
                padding: 10px;
                margin-top: 15px;
            }
        }

        .bill-each {
            display: flex;
            justify-content: space-between;

            .bill-no {
                width: 30px;
                height: 30px;
                border-radius: 5px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: center;
            }


            .bill-name {
                color: #202124;
                font-weight: 500;
                margin: 0 15px;
                font-size: 20px;
                padding: 5px 0;

                span {
                    color: $primaryColor;
                    font-size: 18px;
                }
            }

            .bill-rate {
                background-color: #06BEE1;
                color: #fff;
                border-radius: 4px;
                padding: 5px 10px;
                align-self: flex-start;
                font-size: 14px;
            }

            .bill-action {
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    color: #03256C;
                    cursor: pointer;
                    font-size: 14px;
                }
            }

            .bill-date {
                color: #202124;
                padding: 7px;
                background-color: #fff;
                position: absolute;
                bottom: -5px;
                right: 0px;
                border-radius: 25px;
                font-size: 12px;
            }
        }

    }

    &.inner-token-do {
        width: 100%;

        .p-accordion {
            .p-accordion-tab {
                padding: 8px !important;
                border-radius: 0px !important;
                border-bottom: 1px solid #e5e5e5 !important;

                .bill-each {
                    .bill-name {
                        margin-left: 0px !important;
                    }

                    .bill-date {
                        color: #202124;
                        padding: 7px;
                        background-color: #fff;
                        border-radius: 25px;
                        font-size: 12px;
                        position: static !important;
                        width: fit-content !important;
                    }
                }
            }
        }
    }
}

.issued-do {
    padding: 10px;
    border-radius: 5px;
    background-color: #F3F3F3;
    color: $mainDarkBlue;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;

    i {
        font-weight: 500;
    }
}

.input-lable-tag {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #F3F3F3;
    color: $mainDarkBlue;
    font-weight: 500;
    white-space: nowrap;
    margin-left: 10px;
}

.input-lable-tag-2 {
    padding: 8px 10px;
    border-radius: 5px;
    background-color: #F3F3F3;
    color: $mainDarkBlue;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    margin-left: 10px;
    line-height: 1;

}

.custom-slider {
    .p-slider-handle {
        background-color: $mainDarkBlue !important;
        border-color: $mainDarkBlue !important;
        box-shadow: none !important;
    }

    .p-slider-range {
        background-color: $mainDarkBlue !important;
    }

    &.p-slider {
        background-color: #E5E5E5 !important;
    }
}

.table-header {
    font-size: 18px;
    font-weight: 500;
    color: #03256C;
    line-height: 1;
    padding: 0px 10px;
}

.issue-do-list-area {
    border-top: 1px solid #F8F9FA;
    margin-top: 10px;

    .issue-do-list {
        padding: 10px 0;
        border-radius: 5px;
        background-color: #F8F9FD;
        margin-top: 10px;

    }

    .issue-list-icon {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        i {
            font-size: 26px;
        }
    }

    .issue-do-no {
        padding: 9px 10px;
        height: fit-content;
        border-radius: 5px;
        background: rgba(23, 104, 172, 0.15);
        color: #202124;
        font-weight: 500;
        white-space: nowrap;
        display: flex;
        align-items: center;

        span {
            line-height: 1;
        }

        i {
            font-weight: 500;
            color: $mainDarkBlue;

        }
    }
}



.token-list-area {
    border-top: 1px solid #F8F9FA;
    margin-top: 10px;

    .token-list {
        padding: 10px 0;
        border-radius: 5px;
        background-color: #FFF7EB;
        margin-top: 10px;

        .token-basic-detils {
            border: 1px solid $mainDarkBlue;
            padding: 7px;
            border-radius: 5px;
            width: 100%;
        }

    }

    .issue-list-icon {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        i {
            font-size: 26px;
        }
    }

    .token-no {
        padding: 9px 10px;
        height: fit-content;
        border-radius: 5px;
        background: #FFDCA9;
        color: #202124;
        font-weight: 500;
        white-space: nowrap;
        display: flex;
        align-items: center;

        span {
            line-height: 1;
        }

        i {
            font-weight: 500;
            color: $mainDarkBlue;

        }
    }
}

.no-kyc-tag {
    border: 1px solid #FF9800;
    color: #FF9800;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        color: #FF9800;
        font-size: 18px;
        margin-right: 6px;

    }

    button {
        padding: 4px;
        font-weight: 500;
        text-transform: none;
        color: #FF9800;
        font-size: 13px;

        &:hover {
            background-color: transparent;
        }

    }
}


.kyc-tag {
    color: #fff;
    padding: 3px 7px;
    border-radius: 5px;
    background-color: #2ECB71;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 15px;
        margin-right: 6px;

    }
}

.uploadimg {
    width: 200px;
    margin: auto;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.uploadimg-zip {
    width: 100px;
    margin: auto;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.kyc-img-area {
    background-color: #F8F9FD;
    border-radius: 8px;
    padding: 20px;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
}

.truck-details-section {
    width: 750px;

    .truck-detail {
        background-color: #FCF9F3;
        border-radius: 13px;
        padding: 10px;
        margin-bottom: 10px;

        h2 {
            font-weight: 500;
            font-size: 20px;
            margin: 0;
        }
    }

    .truck-detail-map {
        margin-bottom: 10px;

    }

    .truck-driver-details {
        display: flex;
        gap: 10px;
        align-items: center;


        .user-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
            flex-shrink: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .user-detail {
            .truck-user-name {
                color: #202124;
                font-weight: 500;
                font-size: 20px;
            }

            .truck-user-number {
                color: #06BEE1;
                font-size: 14px;
                font-weight: 15px;
            }
        }
    }

    .truck-details-area {
        padding: 10px;
        background: #F8F9FD;
        border-radius: 8px;
    }

    .truck-details-img {
        width: 350px;
        margin: auto;

        svg {
            width: 100%;
            object-fit: contain;
        }
    }
}

.gatepass-letterhead-container {
    width: 39vw;
    padding-inline: 16pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .company-letterhead-title {
        p {
            margin: 0;
            text-align: center;
            padding: 0;
            font-size: 8pt;
            line-height: 12pt;
        }

        .company-name {
            font-size: 13pt;
            font-weight: 700;
            line-height: 17pt;
        }

    }

    .letter-divider {
        margin: 0;
        margin-top: 16pt;
        border: 1px solid #000000;
        width: 100%;
        box-sizing: unset;
    }

    .company-letterhead-body {
        // margin-block: 20pt;
        width: 100%;

        .header-name {
            margin: 0;
            font-size: 10pt;
            font-weight: 700;
            text-align: center;
        }

        .letterhead-gatepass-header-info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-info-text {
                margin: 0;
                font-size: 10pt;
                font-weight: 700;
                // text-align: center;
            }
        }

        .leatterhead-other-info {
            margin-top: 16pt;

            .letterhead-info-box {
                display: flex;
                align-items: center;
            }

            .leatterhead-other-text {
                margin: 0;
                font-size: 10pt;
                line-height: 14pt;
                // font-weight: 700;
            }
        }

        .other-info-label {
            width: 30%;
        }
    }

    .company-letterhead-signature {
        margin-top: 50pt;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        p {
            margin: 0;
            padding: 0;
            font-size: 10pt;
            line-height: 14pt;
            text-indent: 20pt;
        }

        .company-name {
            font-size: 10pt;
            font-weight: 700;
            line-height: 14pt;
            margin-right: 6pt;
        }
    }
}

.p-primereact-outlined-input {
    width: 100%;

    .p-dropdown {
        border: none;
        border-bottom: 1px solid #c4c4c4 !important;
        border-radius: 0;
        width: 100%;

    }

    .p-dropdown:not(.p-disabled).p-focus {
        outline: none;
        box-shadow: none;
        border-color: transparent;
    }
}

.p-table-dropdown {
    width: 100%;
    .MuiInput-underline:before,.MuiInput-underline:after,.MuiInput-underline:hover:not(.Mui-disabled):before{
        border-bottom: none !important;
    }
    .MuiInput-underline.Mui-disabled:before,.MuiInput-underline.Mui-disabled:after{
        border-bottom-style: none !important;

    }
    .p-inputtext {
        border: none !important;
    }

    .p-dropdown {
        padding-left: 5px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid #B0B3C8 !important;
    }

    .p-table-autocomplete{
        padding-block:0.1rem;
    }

    .p-dropdown:not(.p-disabled).p-focus {
        outline: none;
        box-shadow: none;
        border-color: transparent;
    }
}

.btn-dialog-cancel {
    padding: 0.81rem 2rem;
    height: 35px;
    border-radius: 0.5rem;
    border: 1px solid #E9EDF9;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    outline: none;
    background-color: '#FFF';
    color: #212B36;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
  
    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
    }
  }