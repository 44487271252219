.final-job-list {
    padding: 15px 17px !important;

    .user-role-badge {
        padding: 7px 45px;

        span {
            white-space: nowrap;
        }
    }

    .active {
        background: rgba(203, 215, 255, 0.3);
        border: 1px solid #2541B2;
        border-radius: 8px;

        .icon-area {
            i {
                background: #FFFFFF;
            }
        }

        .action-item {
            i {
                background: #FFFFFF;
            }

            i:hover {
                background: #2541B2;
            }
        }
    }
}

.final-job-item {
    box-sizing: border-box;

    border: 1px solid #B0B3C8;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;

    .icon-area {
        i {
            background: #F8F9FA;
            border-radius: 50%;
            padding: 13px;
            color: #06BEE1;
            font-size: 18px;
        }
    }

    .item-body {
        margin-top: 15px;
    }

    .body-item {
        display: flex;
        align-items: center;

        .body-title-area {
            margin-left: 10px;
        }

        .body-title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            /* identical to box height */

            // text-align: center;
            margin: 0;
            color: #000000;
        }

        .body-subtitle {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            /* identical to box height */
            margin: 0;
            padding: 2px;
            color: #7A7878;
            white-space: nowrap;
        }
    }

    .location-range {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */

        color: #03256C;
        margin: 0;
        margin-left: 8px;
    }

    .item-footer {
        margin-top: 15px;
    }

    .icon-main {
        i {
            color: #03256C;
        }
    }

    .badge-green {
        background-color: #2DC433;
    }

    .badge-orange {
        background-color: #FF9800;
    }

    .action-item {
        i {
            color: #03256C;
            font-size: 18px;
        }

        i:hover {
            color: #FFFFFF;
        }
    }

    .item-button {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border: 1px solid #1768AC;
        border-radius: 4px;

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 13px;
            /* identical to box height */

            color: #000000;
            margin: 0;
            margin-left: 7px;
        }

        i {
            color: #03256C;
            font-size: 16px;
        }
    }


}

.detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 22px;
        margin: 0;
        color: #202124;
    }

    .user-role-badge {
        padding: 7px 45px;

        span {
            white-space: nowrap;
        }
    }
}

.tab-item {
    background: #E5E5E5;
    border-radius: 9px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .badge-count {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */

        color: #FF6257;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 7px;
        margin: 0;
    }

    .button-label {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */
        margin: 0;
        color: #202124;

    }

    i {
        transform: rotate(-90deg);
        font-size: 8px;
        color: #FFFFFF;
        background: #000000;
        padding: 10px 7px;
        height: 25px;
        width: 25px;
        border-radius: 50%;
    }
}

.detail-footer {
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 15px 20px;
    margin-top: 15px;

    label {
        font-family: 'Roboto';
        font-style: normal;
        // font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        // color: #7A7878;
    }

    .branch-info {
        span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */


            color: #415AA9;
            background: #DCEDFE;
            border-radius: 54px;
            padding: 3px 15px;
            margin-right: 8px;
        }
    }

    .branch-detail {
        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            color: #202124;
            margin: 0;
        }
    }
}

.button-tabs ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.button-tabs li {
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    border-radius: 6px;
    padding: 10px 0px 10px 8px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.button-tabs li.active {
    background: #E8F8FF;
    border: 1px solid #46C4FC;
}

.button-tabs-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    text-decoration: none;
    color: #000000;
    white-space: nowrap;
}

.final-side-icon {
    background: #BDDEFF;
    padding: 9.5px;
    border-radius: 50%;
    color: #2541B2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.count-badge {
    background: #46C4FC;
    border-radius: 39px 0px 0px 39px;
    padding: 4px 15px;
    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
}

.p-stamp-duty {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-top: 22px;
}

.GrideContainer {
    .MtNumber {
        background: #E5E5E5;
        border-radius: 4px;
    }
}

.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 31px;
        /* identical to box height */
        margin: 0;

        color: #202124;
    }
}






























/* page-2 CSS start */




.button-tabs ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.button-tabs li {
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    border-radius: 6px;
    padding: 10px 0px 10px 8px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.button-tabs li.active {
    background: #E8F8FF;
    border: 1px solid #46C4FC;
}

.button-tabs-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    text-decoration: none;
    color: #000000;
    white-space: nowrap;
}

.count-badge {
    background: #46C4FC;
    border-radius: 39px 0px 0px 39px;
    padding: 4px 15px;
    color: #FFFFFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
}

// p {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 23px;
//     line-height: 34px;
//     color: #202124;
// }


// p {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 23px;
//     line-height: 34px;
//     color: #202124;
// }


.box-one {
    width: 100%;
    padding: 15px;
    border: 1px solid #E5E5E5;
    border-radius: 7px;
    border-left: 7px solid #ff6257;
    margin-bottom: 15px;

    .dispatch-info {
        align-items: center;

        i {
            font-size: 18px;
            color: #000000;
        }

        p {
            margin-left: 10px;
            line-height: 22px;
        }
    }
}


.box-one p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin: 0;

}

.box-one span {
    background: #F44336;
    border-radius: 30px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 2px 17px;
    margin-left: 15px;
}

.box-cargo {
    border-left: 7px solid #B5432F;
}

.box-containers {
    border-left: 7px solid #FF9800;

    span {
        background: #FF9800;
    }
}

.box-dispatch {
    .container-no {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        background: #FFDCA9;
        border-radius: 4px;
        margin-right: 10px;
    }
}

.action-detail {
    display: flex;
    align-items: center;

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 0px;

        color: #03256C;
        margin-left: 10px;
    }

    .bl-date {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .action-icon {
        background: #F1F1F1;
        border-radius: 6px;
        padding: 9px 11px;
        display: flex;

        i {
            font-size: 17px;
        }
    }
}

.date-icon-area {
    i {
        color: #03256C;
        font-size: 20px;
    }
}

.menu-action {
    button {
        padding: 4px 15px !important;
    }

    button.primary-btn2 {
        padding: 5px 15px !important;
        margin-left: 5px;
    }
}

/*filing page Start */



.filing {
    display: flex;
    align-items: center;
}

.filing-icon {
    background: #2541B2;
    border-radius: 50%;
    padding: 9px;
    height: 36px;
    width: 36px;
    margin-left: 10px;

    i {
        font-size: 18px;
        color: #FFFFFF;
    }
}

.button-tabs li {
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;
    border-radius: 6px;
    padding: 10px 0px 10px 8px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.tab-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #1768AC;
    border-radius: 6px;
    padding: 3px;

}

.tab-btn {
  
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    padding: 8px;
    color: #1768AC;
    border: none;
    background: none;
    cursor: pointer;
}

.selected-tab-btn{
    background: #1768AC;
    color:#FFFFFF;
}

.bottom-bottons {
    button {
        padding: 7px 12px !important;
    }
}


.button-one {
    background: #FFFFFF;
    border: 1px solid #1768AC;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 6px;
    color: #343434;
}

.button-two {
    background: #1768AC;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 6px;
    color: #FFFFFF;
}

.CustomInput {
    width: 100%;
}


// .button-box {
//     display: flex;
//     align-items: center;
//     border: 1px solid #1768AC;
//     border-radius: 6px;
//     padding: 4px 10px;

//     .button {
//         font-family: 'Roboto';
//         font-style: normal;
//         font-weight: 400;
//         font-size: 14px;
//         line-height: 16px;
//         text-align: center;
//         text-transform: capitalize;
//         color: #1768AC;

//     }

//     .be-button {
//         font-family: 'Roboto';
//         font-style: normal;
//         font-weight: 400;
//         font-size: 14px;
//         line-height: 16px;

//         text-align: center;
//         text-transform: capitalize;

//         color: #FFFFFF;
//         background: #1768AC;
//         border-radius: 4px;
//         padding: 5px 14px;

//     }
// }