.main-container-quotation {

    // padding-left: 20px;
    h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */


        color: #202124;
    }

    .heading-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card-quotation {
        background: #FFFFFF;
        border-radius: 10px;
        height: calc(100vh - 148px);
        overflow: auto;
        padding: 10px 20px;
    }

    .add-container-item {
        background: #EAEFFF;
        border-radius: 10px;
        padding: 25px;

        .title-label {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: center;

            color: #7A7878;
        }

        .input-no-cont {
            background: #FFFFFF;
            border-radius: 3px;
            color: #343434;
        }

        // label{
        //     z-index: 1;
        //     padding-left: 7px;
        // }


    }

    .container-table {
        box-sizing: border-box;

        border: 1px solid #7A7878;
        border-radius: 6px;

        .table-add-btn {
            height: 25px;
            width: 25px;
            i{
                font-size: 13px;
            }
        }

        .p-datatable table {
            border-collapse: collapse;
            width: 100%;
            table-layout: fixed;
        }

        .main-table table th {
            min-width: unset !important;
            width: unset !important;
        }

        .main-table {


            thead {
                border-bottom: none;

                th {

                    font-family: 'Roboto' !important;
                    font-style: normal !important;
                    font-weight: 400 !important;
                    font-size: 18px !important;
                    line-height: 21px !important;
                    /* identical to box height */

                    color: #7A7878 !important;
                    // width: 250px !important;
                    padding: 0 !important;

                }

                th:nth-child(1) {
                    width: 6% !important;
                }

                th:nth-child(2) {
                    width: 20% !important;
                }

                th:nth-child(3) {
                    width: 14% !important;
                }

                th:nth-child(4) {
                    width: 14% !important;
                }

                th:nth-child(5) {
                    width: 22% !important;
                }

                th:nth-child(6) {
                    width: 22% !important;
                }

                th:nth-child(7) {
                    width: 6% !important;
                }

                th:nth-child(8) {
                    width: 8% !important;
                }

                th:nth-child(9) {
                    width: 10% !important;
                }

                th:nth-child(10) {
                    width: 7% !important;
                }

                th:nth-child(11) {
                    width: 10% !important;
                }
            }

            tbody {
                tr {
                    td {
                        font-family: 'Roboto' !important;
                        font-style: normal !important;
                        font-weight: 500 !important;
                        font-size: 18px !important;
                        line-height: 21px !important;
                        /* identical to box height */

                        color: #202124 !important;
                        // width: 250px !important;
                        border: none !important;
                    }

                    td:not(:first-child) {
                        padding: 5px !important;
                    }

                    td:first-child {
                        padding-left: 10px !important;
                        padding-right: 0;
                        padding-top: 5px !important;
                    }
                }

                // tr:hover {
                //     background: #F3F3F3;
                // }
            }
        }
    }

    .quotation-item {
        box-sizing: border-box;

        border: 1px solid #B0B3C8;
        border-radius: 8px;
        padding: 15px;

        .title-area {
            .quotation-customer {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                /* identical to box height */

                color: #000000;

            }

            .quotation-no {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;

                color: #7A7878;
            }
        }

        .body-item {
            display: flex;
            align-items: center;

            i {
                background: #F8F9FA;
                border-radius: 50%;
                padding: 15px;
                // margin-left: 10px;
                color: #06BEE1;
                font-size: 28px;
            }
        }

        .body-title-area {
            padding-left: 15px;

            .body-title {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */

                color: #343434;
            }

            .body-subtitle {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 10px;

                color: #3A588F;
            }
        }

        .quotation-item-footer {
            background: #F8F9FD;
            border-radius: 4px;
            padding: 10px;
            margin-top: 15px;

            .footer-area {
                display: flex;
                align-items: center;

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    /* identical to box height */

                    color: #03256C;
                    padding-left: 12px;
                }

                i {
                    color: #03256C;
                }
            }
        }
    }
}

.action-item {
    display: flex;
    justify-content: end;
    align-items: center;

    i {
        background: #F1F1F1;
        border-radius: 4px;
        padding: 10px;
        margin-left: 10px;
        cursor: pointer;
    }

    i:hover {
        background: #2541B2;
        color: #FFFFFF;
    }
}

.action-button {
    width: 35px;
    height: 35px;
    min-width: unset;
    background-color: #2541B2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    // margin-left: 8px;
    box-shadow: none;
    outline: none;
    border: none;

    i {
        color: #FFFFFF;
    }
}

.date-icon-append {
    right: 0;
    left: unset !important;
    top: 10px;
}