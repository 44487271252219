.daily-dashboard {
    height: calc(100vh - 165px);
    overflow: auto;
    // padding-right: 0.25rem;
    &::-webkit-scrollbar{
        display: none;
    }

    .card {
        width: 100%;
        // height: 15rem;
        border-radius: 1.25rem;
        background: #FFF;
        // margin-top: 0.94rem;
        padding: 0.25rem 0rem;
        // border: 1px solid red;
        margin-bottom: 1.25rem;
        box-shadow: 0px 4px 24px 0px rgba(28, 81, 94, 0.05);
        border: 1px solid rgba(23, 104, 172, 0.2);


        .t-box {
            border-radius: 1.5rem 0rem  1.5rem 0rem;
            background: #1768AC;
            // padding: 0.1rem 1.5rem;
            height: 2rem;
            max-width: 10rem;
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-left: -1.5rem;

            .trip-number {
                margin: 0px;
                color: #FFF;
                font-size: 0.9rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
                letter-spacing: 0.03938rem;
            }
        }

        .u-detail {
            display: flex;
            align-items: center;
            // padding: 0rem 1rem;
            // justify-content: center;
            gap: 0.94rem;

            .u-i-box {
                width: 3.125rem;
                height: 3.125rem;
                border-radius: 50%;
                background-color: #FFFFFF;
                border: 1px solid #F1F1F1;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    color: #06BEE1;
                    font-size: 1.125rem;
                    font-weight: 600;
                }

                .pdf {
                    color: #F44336;
                }
            }

            .bg-f {
                border: none;
                background-color: #1BBB811f;

                i {
                    color: #1BBB81;
                }
            }

            .bg-e {
                border: none;
                background-color: #FF98001f;

                i {
                    color: #FF9800;
                }
            }

            .bg-c {
                border: none;
                background-color: #4F56781f;

                i {
                    color: #4F5678;
                }
            }

            .bg-t {
                border: none;
                background-color: #06BEE11f;

                i {
                    color: #06BEE1;
                }
            }

            .u-i-text {
                .title {
                    margin: 0px;
                    color: #03256C;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 0.03063rem;
                }

                .sub-title {
                    color: #03256C;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: 0.035rem;
                    margin: 0px;
                    padding-top: 0.025;
                }
            }
        }

        .destination {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            gap: 1.5rem;
            margin-bottom: 0.25rem;

            .sub-title {
                color: #03256C;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                letter-spacing: 0.035rem;
                margin: 0px;
            }

            i {
                color: #06BEE1;
                font-size: 1rem;
                font-weight: 500;
                transform: rotate(90deg);
            }
        }

        .badge {
            display: flex;
            align-items: center;
            gap: 0.62rem;

            .single-badge {
                background-color: #2ECB711f;
                max-width: 100%;
                border-radius: 0.375rem;
                background: rgba(46, 203, 113, 0.10);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.25rem 0.75rem;

                .s-badge-text {
                    color: #2ECB71;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 0.03063rem;
                    margin: 0px;
                }
            }

            .bg {
                background-color: #1768AC1f;

                .color {
                    color: #1768AC;
                }
            }

            .multiple-badge {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.62rem;
                width: 6.625rem;
                padding: 0.25rem 0.75rem;
                border-radius: 0.375rem;
                background-color: #F09A4A1f;

                .s-badge-text {
                    color: #F09A4A;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 0.03063rem;
                    margin-bottom: 0px;
                }

                .total {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.125rem;
                    height: 1.125rem;
                    border-radius: 50%;
                    background-color: #F09A4A;


                    .t-number {
                        color: #FFF;
                        font-size: 0.5rem;
                        font-style: normal;
                        font-weight: 400;
                        margin: 0px;
                    }
                }

            }
        }
        .row-bg {
            background-color: rgba(23, 104, 172, 0.05);
            // margin-inline: -2.27rem;
            display: flex;
            padding: 0rem 1rem;
        }
    }

    .inner-card {
        margin: 1rem;
        border-radius: 0.625rem;
        border: 1px solid #F1F1F1;
       background-color: rgba(23, 104, 172, 0.05);
        padding: 1rem 1.25rem;
        .heading {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-bottom: 0.62rem;

            .h-line {
                width: 0.99781rem !important;
                height: 0.2rem !important;
                border-radius: 2.125rem;
                background-color: #06BEE1;
            }

            .h-text {
                color: #03256C;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                margin: 0px;
            }
        }
        .inner-card-item{
            background-color: #fff;
            padding: 0.75rem;
            border-radius: 0.625rem;
        }

        .h-bg {
            border-radius: 0.25rem;
           background-color: rgba(23, 104, 172, 0.05);
            padding: 0.38rem 0.81rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.94rem;

            .destination {
                max-width: 80%;
                width: 75%;
            }

            .distance {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6.25rem;
                background: rgba(23, 104, 172, 0.10);
                padding: 0.2rem 0.66rem;
                max-width: 20%;
                width: 6rem;

                .d-text {
                    color: #1768AC;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: 0.03063rem;
                    margin: 0px;
                }
            }
        }
    }
}