.trip-entry-container {
    .main-access-card-master {
        height: unset !important;
        overflow: hidden !important;
    }

    .trip-box {
        height: calc(100vh - 235px) !important;
        overflow: auto;
        .custom-inputs {
            label {
                font-size: 0.875rem;
                margin-bottom: 0.35rem;
                display: block;
                color: #506690;
                font-family: 'Poppins';
            }
        }

        .p-dropdown {
            border-radius: 0.35rem;
            padding: 0.125rem 0.25rem;
            border-color: #869AB8;

            .p-placeholder {
                color: rgba(74, 85, 104, 0.6);
                font-weight: 400;
            }
        }

        input.p-inputtext {
            border-radius: 0.35rem;
            padding: 0.5rem 0.75rem;
            border-color: #869AB8;

            &::placeholder {
                color: rgba(74, 85, 104, 0.6);
                font-weight: 400;
            }
        }

        .p-inputtextarea{
            border-radius: 0.35rem;
            padding: 0.5rem 0.75rem;
            border-color: #869AB8;

            &::placeholder {
                color: rgba(74, 85, 104, 0.6);
                font-weight: 400;
            }
        }

        .p-calendar {
            .p-inputtext {
                border-top-left-radius: 0.35rem !important;
                border-bottom-left-radius: 0.35rem !important;
                border-color: #869AB8;
                padding: 0.125rem 0.75rem !important;
                border-right: none;
                border-top-right-radius: 0rem !important;
                border-bottom-right-radius: 0rem !important;
            }

            button {
                background: #fff;
                border-left: none;
                border-top-right-radius: 0.35rem !important;
                border-bottom-right-radius: 0.35rem !important;

                span {
                    color: #848484;
                    font-size: 1rem;
                }
            }
        }
    }
    

    .p-selectbutton {
        &.p-buttonset {
            background-color: #f7f7f7;
            border: none;
            // border: 1px solid #06BEE1;
            padding: 0.125rem 0.25rem;
            border-radius: 0.5rem;
        }

        .p-button {
            border: none;
            span {
                background-color: transparent;
                color: #06BEE1;
            }

            &.p-highlight {
                border-color: transparent;

                span {
                    background-color: #06BEE1 !important;
                    color: #fff;
                }
            }
        }
    }

    .footer-action {
        border-top: 1px solid #e5e5e5;
    }

    .status-badge {
        background: #06BEE1 !important;
        padding-inline: 1rem !important;
    }

    .custom-large-table {
        .p-dropdown {
            border-radius: 4px !important;

            .p-inputtext {
                border: none !important;
            }
        }

        .p-inputtext {
            border-radius: 4px !important;
        }

        .p-calendar .p-inputtext {
            padding-left: 10px !important;
        }

        th:nth-child(12),
        td:nth-child(12) {
            min-width: 0px !important;
        }

        th:nth-child(13),
        td:nth-child(13) {
            min-width: 0px !important;
        }

        th:nth-child(14),
        td:nth-child(14) {
            min-width: 0px !important;
        }

        th:nth-child(15),
        td:nth-child(15) {
            min-width: 0px !important;
        }
    }

    .accordion-custom {
        &.p-accordion {
            .p-accordion-tab {
                border: 1px solid #E5E5E5;
                border-radius: 10px;
                padding: 15px;
                margin-bottom: 15px;

                .p-disabled {
                    opacity: 1 !important;

                    .p-accordion-header-link {
                        background: transparent !important;
                        border: none !important;
                        flex-direction: row-reverse;
                        padding: 0;
                    }

                    .p-accordion-toggle-icon {
                        cursor: not-allowed !important;

                        &::before {
                            color: #bebebe;
                        }
                    }
                }

            }

            .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
                background: transparent !important;
                border: none !important;
                flex-direction: row-reverse;
                padding: 0;

                &:focus {
                    box-shadow: none !important;
                }

            }

            .p-accordion-header:not(.p-highlight):not(.p-disabled) .p-accordion-header-link {
                background: transparent !important;
                border: none !important;
                flex-direction: row-reverse;
                padding: 0;

                &:focus {
                    box-shadow: none !important;
                }

            }

            .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
                background-color: #f3f3f3;
                color: #03256C;
                width: 30px;
                height: 30px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
                margin-right: 0px;
                flex-shrink: 0;
            }

            .p-accordion-header-text {
                width: 100%;
                font-weight: normal;
            }


            .truck-status {
                color: #343434;
                background-color: rgba(255, 153, 0, 0.3);
                border-radius: 6px;
                padding: 7px 15px;
            }

            .weight-diff {
                padding: 7px 20px;
                border-radius: 25px;
                background-color: rgba(244, 67, 54, 0.3);
                color: #F44336;
            }

            .p-accordion-content {
                border: none;
                padding: 0.875rem 0 0px;
                border-top: 1px solid #06BEE14f !important;
                margin-top: 15px;


                .trip-user-img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
                    object-fit: cover;
                    margin-right: 20px;
                    flex-shrink: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .driver-info-area {
                    margin-bottom: 16px;

                    .driver-info-label {
                        color: #858585;
                        margin-bottom: 10px;
                    }

                    .driver-info-detail {
                        color: #202124;
                        // margin-bottom: 10px;


                    }

                    .weight-paper {
                        background-color: #EAE8FF;
                        border-radius: 5px;
                        padding: 7px;
                        font-size: 13px;

                        .wp-icon {
                            width: 20px;
                            height: 20px;
                            background-color: #fff;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                        }

                        label {
                            cursor: pointer;

                            input {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .summary-box {
        flex-wrap: unset !important;

        .icon-summary-box {
            color: #06BEE1;
        }
    }

    .expense-item {
        .expense-item-box {

            background-color: #F8F9FD;
            border-radius: 0.25rem;
            padding: 0.5rem 1rem;

            span {
                padding: 0 !important;
            }

            p {
                background: #fff;
                padding: 0.125rem 0.5rem;
                border-radius: 1rem;
                border: 1px solid #03256C2f;
                margin: 0;
                color: #03256C;
                font-size: 0.875rem;
                font-family: 'Poppins';
                font-weight: 500;
            }
        }

        h2 {
            color: #03256C;
            font-size: 0.875rem;
            font-family: 'Poppins';
            font-weight: 500;
        }
    }
}

.trip-list-container {
    .main-table .p-datatable-scrollable-wrapper {
        height: calc(100vh - 213px) !important;
        overflow-y: hidden !important;
    }

    .p-datatable-scrollable-body {
        height: 100% !important;
        max-height: 100% !important;
        overflow-y: hidden !important;
    }
}

.trip-loading {
    .heading-area {
        padding-top: 0.25rem;

        i {
            rotate: 0deg;
            font-size: 1rem;
            color: #fff;
            height: unset !important;
            width: unset !important;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: unset !important;

            &:hover {
                background-color: transparent !important;
            }

            &.icon-add {
                padding-inline: 0.5rem;
            }
        }

        header {
            .MuiTabs-indicator {
                background-color: #06BEE1;
            }
        }
    }

    .trip-filter-box {
        .custom-inputs {
            label {
                font-size: 0.875rem;
                margin-bottom: 0.35rem;
                display: block;
                color: #506690;
                font-family: 'Poppins';
            }
        }

        .p-dropdown {
            border-radius: 0.35rem;
            padding: 0.125rem 0.25rem;
            border-color: #869AB8;

            .p-placeholder {
                color: rgba(74, 85, 104, 0.6);
                font-weight: 400;
            }
        }

        .p-calendar {
            .p-inputtext {
                border-top-left-radius: 0.35rem !important;
                border-bottom-left-radius: 0.35rem !important;
                border-color: #869AB8;
                padding: 0.125rem 0.75rem !important;
                border-right: none;
            }

            button {
                background: transparent;
                border-left: none;
                border-top-right-radius: 0.35rem !important;
                border-bottom-right-radius: 0.35rem !important;

                span {
                    color: #848484;
                    font-size: 1rem;
                }
            }
        }
    }

    .table-dd {
        width: 18rem;

        &>div {
            padding-top: 1.25rem !important;
        }

        ul.dd-ul {
            overflow: auto;
            height: 350px;
            padding-top: 0px !important;
        }

    }

    .custom-large-table{
        .p-datatable table {
            table-layout: auto !important;
        }
        .main-table{
            th:nth-child(1),
            td:nth-child(1) {
              min-width: 70px !important;
            }
        
            th:nth-child(2),
            td:nth-child(2) {
              min-width: 160px !important;
              white-space: nowrap;
            }
        
            th:nth-child(3),
            td:nth-child(3) {
              min-width: 250px !important;
              max-width: 250px !important;
            //   white-space: nowrap;
            }
        
            th:nth-child(4),
            td:nth-child(4) {
                min-width: 250px !important;
                max-width: 250px !important;
              white-space: nowrap;
            }
        
            th:nth-child(5),
            td:nth-child(5) {
                min-width: 200px !important;
                max-width: 200px !important;
              white-space: nowrap;
            }
        
            th:nth-child(6),
            td:nth-child(6) {
                min-width: 200px !important;
            //   white-space: nowrap;
    
            }
        
            th:nth-child(7),
            td:nth-child(7) {
                min-width: 200px !important;
            //   white-space: nowrap;
            }
        
            th:nth-child(8),
            td:nth-child(8) {
                min-width: 200px !important;
                // text-align: center;
            //   white-space: nowrap;
    
            }
        
            th:nth-child(9),
            td:nth-child(9) {
                min-width: 200px !important;
                // text-align: center;
    
            }
        
            th:nth-child(10),
            td:nth-child(10) {
                min-width: 200px !important;
                // text-align: center;
    
            }
        
            th:nth-child(11),
            td:nth-child(11) {
                min-width: 200px !important;
                // text-align: center;
    
            }
    
            th:nth-child(12),
            td:nth-child(12) {
                min-width: 200px !important;
                // text-align: center;
    
            }
    
            th:nth-child(13),
            td:nth-child(13) {
                min-width: 200px !important;
                // text-align: center;
    
            }
    
            th:nth-child(14),
            td:nth-child(14) {
                min-width: 200px !important;
                // text-align: center;
            }
    
            th:nth-child(15),
            td:nth-child(15) {
                min-width: 200px !important;
                // text-align: center;
            }
            th:nth-child(16),
            td:nth-child(16) {
                min-width: 200px !important;
                // text-align: center;
            }
            th:nth-child(17),
            td:nth-child(17) {
                min-width: 150px !important;
                // text-align: center;
            }
        }
    }
}

.overlay-common {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 500px;
}