@media screen and (max-width: 575px) {
    .main-header .header-number{
        padding: 30px 0 0;
        width: 100%;
    }
    .notification-section{
        width:280px;
    }
    .nav-dropdown{
        left: 5px !important;
    }
}
