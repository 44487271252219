.sub-heading {
    font-family: 'Delius Swash Caps', cursive;
}

.main-header {
    // position: absolute;
    width: 100%;
    top: 0;
    padding: 20px 30px;

    .header-name {
        font-size: 24px;
        font-weight: 600;
        text-align:center;
    }

    .header-number {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px 0;
        width: 75%;
        margin: 0 auto;

        .main-number {
            height: 40px;
            width: 40px;
            color: $secondaryColor;
            border-radius: 50%;
            border: 1px solid $secondaryColor;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .active {
            background-color: $secondaryColor;
            color: #fff;
        }

        .main-dash {
            width: 50px;
            height: 2px;
            background-color: $secondaryColor;

        }
    }
}

.form-area {
    text-align: center;
    padding: 1rem 2.25rem;
    background-color: #FFFFFF;
    min-height: 35rem;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    border-radius: 0rem 0rem 0.5rem 0rem;
    // position: relative;
    z-index: 1;
    height: 100vh;
    overflow: auto;
    img {
        height: 6rem;
    }

    .shade-background-one {
        width: 11.25rem;
        height: 11.25rem;
        border-radius: 11.25rem;

        background: brown;

        position: absolute;
        top: -6rem;
        right: -6rem;
        display: flex;
        align-items: center;
        justify-content: center;


        .shade-background-two {
            width: 8.875rem;
            height: 8.875rem;
            border-radius: 8.875rem;
            background: rosybrown;
            display: flex;
            align-items: center;
            justify-content: center;

            .shade-background-three {
                width: 6.25rem;
                height: 6.25rem;
                border-radius: 6.25rem;
                background: lavenderblush;
                backdrop-filter: blur(2px);
                display: flex;
                align-items: center;
                justify-content: center;

                .shade-background-four {
                    width: 3.8125rem;
                    height: 3.8125rem;
                    background: #FFFFFF;
                    border-radius: 6.25rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        transform: rotate(180deg);
                        font-size: 1.23063rem;
                        color: #F59F24;
                    }
                }
            }

        }
    }

    .form-heading {
        font-family: 'Inter';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.625rem;
        color: #212B36;
    }

    .forgot-box {
        color: #637381;
        font-family: 'Inter';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.875rem;
        /* 116.667% */
        text-decoration: none;
    }

    .go-back {
        color: #002145;
        text-align: center;
        /* 14 Medium */
        font-family: 'Inter';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        text-decoration: none;
    }
}

.custom-inputs {
    text-align: left;
    width: 100%;
  
    label {
    //   color: #212B36;
    //   font-size: 1rem;
    //   font-style: normal;
    //   font-weight: 500;
    //   line-height: 1.5rem;
    color: #212B36;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }
  
   
  
    .custom-inputs {
        label {
            font-size: 0.875rem;
            margin-bottom: 0.35rem;
            display: block;
            color: #506690;
            font-family: 'Poppins';
        }
    }

    .p-dropdown {
        border-radius: 0.35rem;
        padding: 0.125rem 0.25rem;
        border-color: #cccccc;

        .p-placeholder {
            color: rgba(74, 85, 104, 0.6);
            font-weight: 400;
        }
    }

    input.p-inputtext {
        border-radius: 0.35rem;
        padding: 0.5rem 0.75rem;
        border-color: #cccccc;

        &::placeholder {
            color: rgba(74, 85, 104, 0.6);
            font-weight: 400;
        }
    }

    .p-password-input{
        width: 100%;
    }

    .p-inputtextarea{
        border-radius: 0.35rem;
        padding: 0.5rem 0.75rem;
        border-color: #cccccc;

        &::placeholder {
            color: rgba(74, 85, 104, 0.6);
            font-weight: 400;
        }
    }

    .p-calendar {
        .p-inputtext {
            border-top-left-radius: 0.35rem !important;
            border-bottom-left-radius: 0.35rem !important;
            border-color: #cccccc;
            padding: 0.125rem 0.75rem !important;
            border-right: none;
            border-top-right-radius: 0rem !important;
            border-bottom-right-radius: 0rem !important;
        }

        button {
            background: #fff;
            border-left: none;
            border-top-right-radius: 0.35rem !important;
            border-bottom-right-radius: 0.35rem !important;

            span {
                color: #848484;
                font-size: 1rem;
            }
        }
    }
}

.card-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-block: 1rem;
    border-top: 1px solid #E7E7E7;
  }


.p-selectbutton {
    &.p-button-group {
        background-color: #f7f7f7 !important;
        border: none;
        // border: 1px solid #06BEE1;
        padding: 0.125rem 0.25rem;
        border-radius: 0.5rem;
    }

    .p-button {
        border: none;
        span {
            background-color: transparent;
            color: #06BEE1;
        }

        &.p-highlight {
            border-color: transparent;

            background-color: #06BEE1 !important;
            span {
                color: #fff;
            }
        }
    }

  
    i {
      margin-top: 0 !important;
      top: 56% !important;
    }
  
    &.password-field {
      position: relative;
  
      .tailer-icon {
        position: absolute;
        right: 15px;
        top: 35%;
        font-size: 24px;
        cursor: pointer;
        color: #929DA7;
      }
    }
  }

.login-btn button:hover {
    background-color: #fff !important;
    color: #06BEE1 !important;
}

.input-icn {
    position: absolute;
    left: 0;
    top: 21px;
    color: $primaryColor;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.forgot a span {
    color: $primaryColor;
}

.login-btn {
    text-align: center;
}


.login-btn button {
    background-color: $primaryColor;
    padding: 7px 30px;
    border-radius: 50px;
    color: #fff;
    font-size: 14px !important;
    height: 40px;
    font-weight: 500;
}

.login-btn button:hover {
    background-color: #1465ea;
}

.register-submit {
    padding: 7px 15px !important;
}

.register-resend {
    min-width: 35px !important;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 0 !important;

    i {
        color: #fff;
    }

    &:hover {
        i {
            color: #06BEE1;
        }
    }
}

.verify-btn {
    margin-top: 17px !important;
    background: #06BEE1;
    margin-left: 15px !important;
    padding: 5px 7px !important;
}

.login-snap {
    width: 100%;
    margin-top: 30px;
    text-align: center;
    color: #fff;
}

.login-snap p {
    width: 100%;
    margin: 0;
    color: #fff;
    font-size: 0.9rem;
    font-size: 18px;

}

.login-snap a {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    text-decoration: none;
    font-size: 18px;

}

.login-snap a:hover {
    color: #fff;

}


.login-snap1 {
    width: 100%;
    margin-top: 30px;
    text-align: center;
    // color: #fff;
}

.login-snap1 p {
    width: 100%;
    margin: 0;
    // color: #fff;
    font-size: 0.9rem;
    font-size: 18px;

}

.login-snap1 a {
    white-space: nowrap;
    color: #06BEE1;
    font-size: 0.9rem;
    font-weight: 600;
    text-decoration: none;
    font-size: 18px;

}

.login-snap1 span {
    color: #06BEE1;
    font-size: 18px;

}

.login-snap1 a:hover {
    color: #06BEE1;

}

.loginwith-btns {
    text-align: center;

    .MuiButton-root:hover {
        background-color: #fff !important;
    }

    .MuiButton-label {
        display: flex !important;
        justify-content: start !important;

    }
}

.terms {
    height: 100vh;
    overflow: auto;

    .header {
        text-align: center;
        font-size: 24px;
        color: #000;
        font-weight: 600;
        padding: 50px 0px;

    }

    ul {
        li {
            font-size: 18px;
            color: #000;
            font-weight: 500;
            padding: 20px 70px;
        }
    }
}

.down-arrow {
    display: none;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $primaryColor;
    left: 20px;
    bottom: 34px;
    justify-content: center;
    align-items: center;
    animation: bounce 2s infinite;

    a {
        color: #fff;
        text-decoration: none;
        // font-size: px;
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-15px);
    }

    60% {   
        transform: translateY(-5px);
    }
}