.wayment-container {
  background: #ffffff;
  border-radius: 10px;
  // height: calc(100vh - 100px);
  padding-inline: 1rem;
  overflow: auto;

  hr {
    margin: 0;
  }

  .heading-area {
    i {
      rotate: 180deg;
      font-size: 0.7rem;
      color: #06bee1;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 0.35rem;

      &:hover {
        background-color: #f8f9fd;
        border-radius: 50px;
      }
    }

    h2 {
      color: #202124;
      font-size: 1.25rem;
      font-family: "Poppins";
      font-weight: 500;
    }

    .status-badge {
      border-radius: 25px 25px 0px 25px;
      background: #2ecb71;
      padding: 0.5rem 1.75rem;

      p {
        color: #fff;
        font-size: 0.8125rem;
        font-family: "Roboto";
        font-weight: 500;
        letter-spacing: 0.01625rem;
        margin: 0;
      }
    }

    &.heading-area-dashboard {
      i {
        rotate: 0deg;
        font-size: 1rem;
      }
    }
  }

  .wayment-card {
    padding: 1rem;
    height: calc(100vh - 165px);
    overflow: auto;
    margin-inline: -1rem;

    .accordion-custom {
      &.p-accordion {
        .accordion-custom-header {
          padding: 1.125rem 1.875rem;
        }

        .p-accordion-header {
          i {
            color: #06bee1;
            margin-right: 1rem;
          }
        }

        .p-accordion-header .p-accordion-header-link {
          color: #1c515e;
          text-transform: uppercase;

          &:focus,
          &.p-focus {
            box-shadow: none !important;
            color: #1c515e;
          }
        }

        .p-accordion-tab {
          border: 1px solid #e5e5e5;
          border-radius: 10px;
          // padding: 1.125rem 0rem;
          margin-bottom: 15px;
          position: relative;
          background-color: #f5f7fa;

          .sr-no {
            position: absolute;
            left: -0.9rem;
            bottom: 0.55rem;
            width: 2rem;
            height: 2rem;
            border: 1px solid #06bee1;
            background-color: #fff;
            border-radius: 50%;
            font-size: 1rem;
            color: #03256c;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .p-disabled {
            opacity: 1 !important;

            .p-accordion-header-link {
              background: transparent !important;
              border: none !important;
              flex-direction: row-reverse;
              padding: 0;
            }

            .p-accordion-toggle-icon {
              cursor: not-allowed !important;

              &::before {
                color: #bebebe;
              }
            }
          }
        }

        .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
          background: transparent !important;
          border: none !important;
          flex-direction: row-reverse;
          padding: 0;

          &:focus,
          &.p-focus {
            box-shadow: none !important;
          }
        }

        .p-accordion-header:not(.p-highlight):not(.p-disabled) .p-accordion-header-link {
          background: transparent !important;
          border: none !important;
          flex-direction: row-reverse;
          padding: 0;

          &:focus,
          &.p-focus {
            box-shadow: none !important;
          }
        }

        .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
          background-color: #f3f3f3;
          color: #03256c;
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          // margin-right: 0px;
          flex-shrink: 0;
          // margin-right: 0.875rem;
          // display: none;
        }

        .p-accordion-header-text {
          p {
            margin: 0;
            font-weight: normal;
          }

          width: 100%;
        }

        .truck-status {
          color: #343434;
          background-color: rgba(255, 153, 0, 0.3);
          border-radius: 6px;
          padding: 7px 15px;
        }

        .weight-diff {
          padding: 7px 20px;
          border-radius: 25px;
          background-color: rgba(244, 67, 54, 0.3);
          color: #f44336;
        }

        .p-accordion-content {
          border: none;
          padding: 0.5rem 0 0px;
          border-top: 1px solid #06bee14f !important;
          // margin-top: 15px;

          .trip-user-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
            margin-right: 20px;
            flex-shrink: 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .driver-info-area {
            margin-bottom: 16px;

            .driver-info-label {
              color: #858585;
              margin-bottom: 10px;
            }

            .driver-info-detail {
              color: #202124;
              // margin-bottom: 10px;
            }

            .weight-paper {
              background-color: #eae8ff;
              border-radius: 5px;
              padding: 7px;
              font-size: 13px;

              .wp-icon {
                width: 20px;
                height: 20px;
                background-color: #fff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
              }

              label {
                cursor: pointer;

                input {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .wayment-form {
    margin-inline: -1rem;
    padding-block: 1rem;
    padding-inline: 1rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 222px);
    justify-content: space-between;
    overflow: auto;

    .p-dropdown {
      border-radius: 0;
      // box-shadow: none;
      padding: 0.125rem 0rem;
      border: none;
      border-bottom: 1px solid;
      border-color: #5066904f;

      .p-placeholder {
        color: rgba(74, 85, 104, 0.6);
        font-weight: 400;
        padding-inline: 0.1rem;
      }

      &:focus,
      &.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #a5f3fc;
        border-color: #06b6d4;
      }
    }

    .MuiInputBase-root::before {
      border-color: #5066904f;
      // border-radius: 0;
      // // padding: 0.15rem 0rem;
      // height: 2.65rem;
      // border: none;
      // &::placeholder {
      //     color: rgba(74, 85, 104, 0.6);
      //     font-weight: 400;
      //     margin: 0;
      //     line-height: normal;
      // }
      // input{
      //     border: none;
      // }
    }

    input.p-inputtext {
      border-radius: 0;
      padding: 0.5rem 0.75rem;
      border: none;
      border-bottom: 1px solid;
      border-color: #5066904f;

      &::placeholder {
        color: rgba(74, 85, 104, 0.6);
        font-weight: 400;
      }

      &:focus,
      &.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #a5f3fc;
        border-color: #06b6d4;
      }
    }

    .p-inputtextarea {
      border-radius: 0;
      padding: 0.5rem 0.75rem;
      border: none;
      border-bottom: 1px solid;
      border-color: #5066904f;

      &::placeholder {
        color: rgba(74, 85, 104, 0.6);
        font-weight: 400;
      }
    }

    .p-calendar {
      .p-inputtext {
        border-top-left-radius: 0.35rem !important;
        border-bottom-left-radius: 0.35rem !important;
        border: none;
        border-bottom: 1px solid;
        border-color: #5066904f;
        padding: 0.125rem 0.75rem !important;
        border-right: none;
        border-top-right-radius: 0rem !important;
        border-bottom-right-radius: 0rem !important;
      }

      button {
        background: #fff;
        border-left: none;
        border-top-right-radius: 0.35rem !important;
        border-bottom-right-radius: 0.35rem !important;

        span {
          color: #848484;
          font-size: 1rem;
        }
      }
    }

    .p-selectbutton {
      &.p-buttonset {
        background-color: #f7f7f7 !important;
        border: none;
        // border: 1px solid #06BEE1;
        padding: 0.125rem 0.25rem;
        border-radius: 0.5rem;
      }

      .p-button {
        border: none;

        span {
          background-color: transparent;
          color: #06bee1;
        }

        &.p-highlight {
          border-color: transparent;
          background-color: transparent !important;

          span {
            background-color: #06bee1 !important;
            color: #fff;
          }
        }

        &:focus,
        &.p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #a5f3fc;
          border-color: #06b6d4;
        }
      }
    }

    .wayment-dispatch-badge {
      background-color: #e5e5e5;
      border-radius: 4px;
      padding: 0.188rem 0.45rem;
      position: absolute;
      right: 0;
      bottom: 10px;

      p {
        color: #03256c;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }
  }

  .toggle-wayment {
    button {
      background-color: #f7f7f7;
      // border: n;
      border: 1px solid #e5e5e5;
      padding: 0.125rem 0.75rem;
      height: 2.35rem;
      color: #06bee1;

      // border-radius: 0.5rem;
      &.Mui-selected {
        background-color: #06bee1;
        color: #fff;
      }
    }
  }

  .wayment-info {
    .w-info-live-weight {
      border-radius: 0.35rem;
      border: 1px solid #ff4e41;
      background: rgba(255, 78, 65, 0.04);
      padding: 0.65rem;

      h3 {
        color: #151515;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }

      p {
        color: #ff4e41;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
      }
    }

    .w-info-sub-weight {
      border-radius: 0.35rem;
      border: 1px solid #e5e5e5;
      background: #fafafa5f;
      padding: 0.65rem;

      h3 {
        color: #151515;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }

      p {
        color: #3a588f;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
      }
    }

    .w-info-weight {
      border-radius: 2rem;
      border: 1px solid rgba(244, 67, 54, 0.2);
      background: rgba(244, 67, 54, 0.02);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.25rem;

      i {
        font-size: 3rem;
        color: #f44336;
        padding: 0.19rem;
      }

      p {
        color: #f44336;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }

    .w-info-total {
      border-radius: 0.625rem;
      background: rgba(6, 190, 225, 0.02);
      // border: 1px solid rgba(6, 190, 225, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      padding: 0.75rem 0;

      .w-info-subweight {
        border-radius: 0.625rem;

        padding: 1rem;
        margin: 0.75rem;

        p {
          color: #7a7878;
          text-align: center;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0;
        }

        .w-subweight-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;

          p {
            color: #7a7878;
            text-align: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
          }

          i {
            width: 1.375rem;
            height: 1.375rem;
            flex-shrink: 0;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #7a7878;
          }
        }

        &.active {
          background: rgba(244, 67, 54, 0.05);

          .w-subweight-title {
            i {
              color: #f44336;
            }

            p {
              color: #7a7878;
            }
          }

          p {
            color: #f44336;
          }
        }

        &.active-top {
          background: rgba(6, 190, 225, 0.1);
        }
      }
    }
  }

  .wayment-footer-btn {
    border-radius: 0.35rem !important;
    border: 1px solid #e0e0e0;
    background: rgba(224, 224, 224, 0.1);
    position: relative;
    padding: 0rem 0.875rem !important;
    height: 2.35rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      // box-shadow: none;
      outline: 0 none !important;
      outline-offset: 0 !important;
      box-shadow: 0 0 0 0.2rem #a5f3fc !important;
      border-color: #06b6d4 !important;
    }

    label {
      color: #1c515e;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      text-align: center;
    }

    .w-footer-checkbox {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;

      .p-checkbox-box {
        background: transparent;
        border: none;
        box-shadow: none;
        border-radius: 50%;

        &.p-highlight {
          background-color: #06bee1;
          border: none;
        }
      }

      .p-checkbox-icon {
        font-size: 0.65rem;
      }
    }

    &.active {
      border: 1px solid #06bee1;
      background: rgba(6, 190, 225, 0.1);
    }
  }
}

.wayment-large-table {
  .p-datatable table {
    table-layout: auto !important;
  }

  .main-table {

    th:nth-child(1),
    td:nth-child(1) {
      min-width: 70px !important;
    }

    th:nth-child(2),
    td:nth-child(2) {
      min-width: 120px !important;
      white-space: nowrap;
    }

    th:nth-child(3),
    td:nth-child(3) {
      min-width: 100px !important;
      //   white-space: nowrap;
    }

    th:nth-child(4),
    td:nth-child(4) {
      min-width: 120px !important;
      white-space: nowrap;
    }

    th:nth-child(5),
    td:nth-child(5) {
      min-width: 120px !important;
      white-space: nowrap;
    }

    th:nth-child(6),
    td:nth-child(6) {
      min-width: 180px !important;
      //   white-space: nowrap;
    }

    th:nth-child(7),
    td:nth-child(7) {
      min-width: 160px !important;
      //   white-space: nowrap;
    }

    th:nth-child(8),
    td:nth-child(8) {
      min-width: 120px !important;
      text-align: center;
      //   white-space: nowrap;
    }

    th:nth-child(9),
    td:nth-child(9) {
      min-width: 120px !important;
      text-align: center;
    }

    th:nth-child(10),
    td:nth-child(10) {
      min-width: 120px !important;
      text-align: center;
    }

    th:nth-child(11),
    td:nth-child(11) {
      min-width: 100px !important;
      text-align: center;
    }

    th:nth-child(12),
    td:nth-child(12) {
      min-width: 100px !important;
      text-align: center;
    }

    th:nth-child(13),
    td:nth-child(13) {
      min-width: 100px !important;
      text-align: center;
    }

    th:nth-child(14),
    td:nth-child(14) {
      min-width: 150px !important;
      text-align: center;
    }
  }
}

.wayment-pdf-container {
  width: 250px;
  max-width: 250px;
  // height: 755px;
  margin: 0 auto;

  .wayment-font {
    font-size: 9px;
    font-family: Arial, Helvetica, sans-serif;
    color: #151515;
    text-align: center;
    margin: 0px;
    line-height: 20px;
  }

  img {
    height: 35px;
  }

  h4 {
    margin: 0;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 600;
  }

  p {
    margin: 0;
  }

  hr {
    margin-block: 3px;
  }

  .wayment-info {
    display: flex;
    justify-content: space-between;
    align-items: start;

    p {
      font-size: 9px;
      font-family: Arial, Helvetica, sans-serif;
      color: #151515;
      text-transform: uppercase;
      padding-block: 0px;
      line-height: 20px;
    }
  }

  .wayment-info-header {
    justify-content: center;
  }

  .divider {
    margin-block: 3px;
    border-top: 1px dashed #151515;
    height: 1px;
  }
}

/*WB Dashboard CSS*/
.wayment-owner-dashboard {
  background: #fff;
  border-radius: 10px;
  height: calc(100vh - 147px);
  padding-inline: 1rem;
  overflow: auto;
  margin-top: 0.65rem;
  // border: 1px solid red;

  hr {
    margin: 0;
  }

  .heading-area {
    i {
      font-size: 1rem;
      color: #06bee1;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 0.35rem;

      &:hover {
        background-color: transparent;
        border-radius: 50px;
      }
    }

    h2 {
      color: #202124;
      font-size: 1.25rem;
      font-family: "Poppins";
      font-weight: 500;
    }

    .status-badge {
      border-radius: 25px 25px 0px 25px;
      background: #2ecb71;
      padding: 0.5rem 1.75rem;

      p {
        color: #fff;
        font-size: 0.8125rem;
        font-family: "Roboto";
        font-weight: 500;
        letter-spacing: 0.01625rem;
        margin: 0;
      }
    }

    .wb-filter-select {
      &.p-selectbutton {
        display: flex;

        .p-button {
          span {
            background-color: transparent;
            color: #06bee1;
          }

          &.p-highlight {
            span {
              color: #fff;
            }
          }
        }
      }
    }

    .wb-filter-dt {
      margin-inline: 1rem;
      width: 20rem;

      .p-inputtext {
        padding-inline: 0.5rem !important;
        border-radius: 0.5rem;
        height: 37px;
        text-align: center;
      }

      .p-datepicker {
        .p-highlight {
          color: #fff;
          margin-bottom: 0.5rem;
        }
      }
    }

    .wb-filter-btn {
      display: flex;

      .wb-search-btn {
        background-color: #06bee1;
        border-color: #06bee1;
        border-radius: 50%;
        height: 2.25rem;
        width: 2.25rem;
        i {
          color: #FFFFFF;
          &:hover {
            background-color: #0b7d94;
          }
        }
      }

      .wb-reset-btn {
        color: #06bee1;
        border-color: #06bee1;
        background-color: #fff;
        border-radius: 50%;
        height: 2.25rem;
        width: 2.25rem;
      }
    }
  }

  .wb-counter-box {
    background-color: #fcfeff;
    border: 1px solid #e5e5e5;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-block: 1rem;
    height: 120px;

    i {
      font-size: 1.25rem;
      color: #06bee1;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 0.35rem;

      &:hover {
        background-color: #f8f9fd;
        border-radius: 50px;
      }
    }

    p {
      color: #202124;
      font-size: 1rem;
      font-family: "Poppins";
      font-weight: 400;
      margin: 0;
    }

    h2 {
      color: #202124;
      font-size: 1.5rem;
      font-family: "Poppins";
      font-weight: 500;
      margin: 0;
    }

    .job-d-badge {
      border: 1px solid rgba(13, 148, 136, .2);
      background: #f0fdfa;
      display: inline-flex;
      border-radius: .375rem;

      p {
        font-family: "Poppins";
        font-weight: 400;
        font-size: .875rem !important;
        line-height: 1rem;
        color: #0f766e !important;
        margin: 0;
        padding: .35rem .5rem;
      }
      span {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 0.75rem !important;
        line-height: 1.70rem;
        color: #0f766e !important;
        padding-right: 0.35rem;
      }
    }
  }

  .wb-owner-chart {
    height: 30rem;
    // width: 10rem;
    // background-color: #fff;
    // box-shadow: 0px 2px 10px 8px rgba(0, 0, 0, 0.02);
    // background-color: #fefefe;
    border: 1px solid #e5e5e5;
    border-radius: 1rem;
    position: relative;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    i {
      font-size: 1.125rem;
      color: #06bee1;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 0.35rem;

      &:hover {
        background-color: #f8f9fd;
        border-radius: 50px;
      }
    }

    p {
      color: #202124;
      font-size: 1rem;
      font-family: "Poppins";
      font-weight: 500;
      margin: 0;
    }
  }

  .custom-large-wb-table {
    height: calc(100vh - 185px);

    .p-datatable table {
      table-layout: auto !important;
    }

    .p-paginator {
      margin-top: 10px;
    }

    .main-table {
      // height: auto;
      // margin-inline: -12px;
      // background: none;

      // input {
      //     font-size: 15px;
      // }

      // td {
      //     padding-top: 8px !important;
      // }

      // tr {
      //     background: none !important;
      // }

      // th {
      //     background: #F8F9FA !important;
      //     min-width: unset !important;
      //     white-space: nowrap;
      // }

      th {
        // min-width: unset !important;
        width: unset !important;
        // max-width: 100% !important;
      }

      td {
        min-width: unset !important;
        width: unset !important;
        // width: 25% !important;
      }

      .bill-action {
        display: flex;
        margin-left: -15px;
      }

      .action-item {
        i {
          height: 35px;
          width: 35px;
          border: 1px solid #1768ac2f;
          // border-radius: 8px;
          // background: #ffffff;
          // background: #FFFFFF;
        }
      }

      th:nth-child(1),
      td:nth-child(1) {
        min-width: 70px !important;
      }

      th:nth-child(2),
      td:nth-child(2) {
        min-width: 180px !important;
        white-space: nowrap;
      }

      th:nth-child(3),
      td:nth-child(3) {
        min-width: 180px !important;
        white-space: nowrap;
      }

      th:nth-child(4),
      td:nth-child(4) {
        min-width: 180px !important;
        white-space: nowrap;
      }

      th:nth-child(5),
      td:nth-child(5) {
        min-width: 180px !important;
        // white-space: nowrap;
      }

      th:nth-child(6),
      td:nth-child(6) {
        min-width: 180px !important;
      }

      th:nth-child(7),
      td:nth-child(7) {
        min-width: 180px !important;
        white-space: nowrap;
      }

      th:nth-child(8),
      td:nth-child(8) {
        min-width: 180px !important;
      }

      th:nth-child(9),
      td:nth-child(9) {
        min-width: 180px !important;
      }

      th:nth-child(10),
      td:nth-child(10) {
        min-width: 180px !important;
      }

      th:nth-child(11),
      td:nth-child(11) {
        min-width: 180px !important;
      }

      th:nth-child(12),
      td:nth-child(12) {
        min-width: 180px !important;
      }

      th:nth-child(13),
      td:nth-child(13) {
        min-width: 180px !important;
      }
    }
  }
}

.p-datepicker {
  .p-highlight {
    color: #fff;
    margin-bottom: 0.5rem;
  }
}

.wb-slip-pdf-container {
  margin: 0 auto;
  padding: 0rem 1.5rem;

  .filter-item {
    margin-bottom: 0.75rem;
  }

  .wb-slip-font {
    font-size: 9px;
    font-family: Arial, Helvetica, sans-serif;
    color: #151515;
    text-align: center;
    margin: 0px;
    line-height: 20px;
  }

  img {
    height: 35px;
  }

  h4 {
    margin: 0;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-weight: 700;
  }

  p {
    margin: 0;
    font-size: 75%;
    font-weight: 600;
  }

  hr {
    margin-block: 0.5rem;
  }

  table {
    font-size: 75%;
    table-layout: auto;
    width: 100%;
  }

  table {
    border-collapse: collapse;
    // border-spacing: 2px;
  }

  th {
    white-space: nowrap;
  }

  th,
  td {
    border-width: 1px;
    padding: 0.5em;
    position: relative;
    text-align: left;
    vertical-align: top;
    font-weight: 500;
  }

  th,
  td {
    // border-radius: 0.25em;
    border-style: solid;
  }

  th {
    background: #eee;
    border-color: #bbb;
  }

  td {
    border-color: #ddd;
  }

  // table{
  //     th{
  //         font-size: 9px;
  //         font-family: Arial, Helvetica, sans-serif;
  //         color: #151515;
  //         text-align: center;
  //         margin: 0px;
  //         line-height: 20px;
  //     }
  //     td{
  //         font-size: 9px;
  //         font-family: Arial, Helvetica, sans-serif;
  //         color: #151515;
  //         text-align: center;
  //         margin: 0px;
  //         line-height: 20px;
  //     }
  // }
}

.noti-container {
  height: 400px;
  padding: 0 !important;
  border-radius: 0 !important;
  border-top-left-radius: 13px !important;
  border-bottom-left-radius: 13px !important;
  background-color: #fff !important;
}

.qr-dialog {
  div {
    padding-block: 11.25rem !important;
  }
}

.wayment-total-main {
  background: rgba(230, 245, 249, 0.7);
  border-radius: 6px;
  padding: 0.5rem 0.65rem;
  margin-block: 1rem;

  .wayment-total-main-label {
    font-size: 0.9rem;
    color: #151515;
    font-weight: 500;
    text-align: center;
    margin: 0px;
    line-height: 1.5rem;
    text-transform: uppercase;
  }

  .wayment-total-main-value {
    font-size: 1.25rem;
    color: #2ecb71;
    font-weight: 500;
    text-align: center;
    margin: 0px;
    line-height: normal;
  }
}

.wayment-total-other {
  background: #f3f3f3;
  border-radius: 2rem;
  padding: 0.5rem 0.65rem;
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;

  .wayment-total-other-label {
    font-size: 0.8rem;
    color: #151515;
    font-weight: 500;
    // text-align: center;
    margin: 0px;
    line-height: normal;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .wayment-total-other-value {
    font-size: 0.875rem;
    color: #151515;
    font-weight: 400;
    // text-align: center;
    margin: 0px;
    line-height: normal;
  }
}

.wayment-total-weight {
  background: rgba(65, 90, 169, 0.02);
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 0.65rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;

  .wayment-total-weight-label {
    font-size: 0.875rem;
    color: #151515;
    font-weight: 500;
    // text-align: center;
    margin: 0px;
    line-height: 1.5rem;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .wayment-total-weight-value {
    font-size: 1.125rem;
    color: #3a588f;
    font-weight: 500;
    // text-align: center;
    margin: 0px;
    line-height: normal;
  }

  div {
    padding-inline: 1.25rem;
  }

  .wayment-total-weight-divider {
    border-left: 1px solid rgba(65, 90, 169, 0.2);
    border-right: 1px solid rgba(65, 90, 169, 0.2);
  }
}

.p-datepicker table td>span.p-highlight {
  color: #fff !important;
  margin-inline: 0.25rem !important;
}

.wayment-slip-filter {
  border-radius: 1rem;
  padding: 0.5rem 1rem;

  .p-overlaypanel-content {
    .p-inputtext {
      font-size: 0.9rem;

      .p-placeholder {
        color: rgba(74, 85, 104, 0.6);
      }
    }

    .p-multiselect {
      font-size: 0.9rem;
      font-family: "Roboto";
      font-weight: 400;

      .p-placeholder {
        color: rgba(74, 85, 104, 0.6);
      }
    }

    .p-calendar {
      .p-inputtext {
        height: 40px;
        border-top-right-radius: 0.35rem !important;
        border-bottom-right-radius: 0.35rem !important;
        border-right: 1px solid #cccccc;
      }

      button {
        background: #fff;
        border-left: none;
        border-top-right-radius: 0.35rem !important;
        border-bottom-right-radius: 0.35rem !important;

        span {
          color: #848484;
          font-size: 1rem;
        }
      }
    }
  }
}