.custom-sidebar {
    height: auto !important;
    top: 10px !important;
    bottom: 15px !important;
    border-radius: 10px;
    z-index: 1032 !important;
    height: calc(100vh - 101px) !important;
    overflow: auto;

    ul {
        li {
            color: $primaryWhite !important;
            white-space: nowrap;
        }
    }
}

.custom-sidebar1 {
    background: transparent !important;
}

.custom-sidebar {
    background: #343434 !important;
}

.sidebarbottom {
    p{

        width: 260px;
    }
    text-align: center;
    i{
        font-size: 22px;
    }
}

.sidebar-logo {
    width: 100%;
    padding: 0 !important;
    height: 63px !important;
    overflow: hidden;

    // border-bottom: 1px solid #fff;

    .sidebar-logo-mini {
        width: 100% !important;
        height: 100% !important;
        background-color: #f3f3f3 !important;
        // height: fit-content !important;
        margin: 0 auto !important;
        max-height: fit-content !important;
        border-radius: 10px !important;
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        align-items: center;
        white-space: nowrap;

        img {
            // height: 100%;
            // width: 100%;
            object-fit: contain;
            transition-delay: 0s;
        }

        .logo-img {
            width: 155px;
            object-fit: contain;
            transition-delay: 0.1s;
            // padding: 40px;
        }
    }
}

.sidebar-user {
    width: 40px;
    height: 40px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

}

.top-menu-area {
    padding-left: 15px;
    position: static !important;
    width: 100% !important;
    background: transparent !important;

    .main-top-bar {
        background-color: #f3f3f3;
        padding: 6px;
        border-radius: 10px;
        position: static;
        width: 100%;
    }
}

.makeStyles-content-3 {
    margin-top: 0 !important;
}

.tab-items {
    width: auto !important;
    min-width: auto !important;
    margin: 0 10px !important;
    color: $secondaryColor !important;
    border-radius: 6px !important;
    padding: 3px 6px !important;
    height: auto !important;
    min-height: auto !important;
    max-height: none !important;

}

.Mui-selected.tab-items {
    background-color: $secondaryColor !important;
    color: #fff !important;
}

.minimize-btn {
    padding: 0px !important;

    button {
        background-color: transparent !important;
        box-shadow: none !important;
    }
}


.basic-header {

    .icon-pdf,
    .icon-xls {
        font-size: 22px;
    }

    p.Mui-error {
        position: absolute;
        top: -12px;
        background-color: #f8f9fa;
        left: -5px;
    }
}

.p-overlaypanel:before {
    border-color: transparent !important;
}

.PopperNoti {
    z-index: 1001;
    position: absolute;
    will-change: transform;
    top: 55px !important;
    left: 0px;
    transform: translate3d(967px, 5px, 0px);
    background-color: white;
}

.notification-section {
    width: 300px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 2px 8px 3px rgba(0, 0, 0, 0.14) !important;
    border: none;


    .notification-number {
        border-radius: 4px;
        background-color: #09245B;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-shrink: 0;

        font-size: 14px;
        color: #fff;

    }

    .notification-dropdown {
        width: 100%;
        margin: 0 10px;
        border-radius: 5px;
        border: 1px solid #03256C;

        .p-inputtext {
            padding: 4px !important;
        }

        .p-dropdown:not(.p-disabled).p-focus {
            box-shadow: none !important;
        }
    }

    .notification-info {
        background-color: #F3F3F3;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-shrink: 0;
        border-radius: 2px;
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #000;
    }

    .notification-area {
        list-style: none;
        margin: 0;
        padding: 0;
        max-height: 300px;
        overflow: auto;
        margin-top: 10px;




        .notification-details {

            .notification-each {
                position: relative;
                padding: 10px;
                padding-bottom: 4px;
                background-color: #f3f3f3;
                margin-bottom: 10px;
                border-radius: 8px;


                .notification-each-icon {
                    width: 50px;
                    height: 50px;
                    flex-shrink: 0;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    overflow: hidden;

                    i {
                        font-size: 22px;
                    }


                    .icon-notification-fill {
                        color: #000000;
                    }

                    .icon-buyNotify {
                        color: #2ECB71;
                    }
                    
                    .icon-sentNotify {
                        color: #FF9800;
                    }

                    .icon-locationNotify {
                        color: #F44336;
                    }

                    .icon-vessel {
                        color: #06BEE1;
                    }

                    .icon-truckNotify {
                        color: #77048B;
                    }

                    .icon-transporterNotify {
                        color: #D0856C;
                    }


                }

                .notification-each-text {
                    color: #343434;
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 500;

                    span {
                        color: $primaryColor;
                    }

                }

                .notification-cross-icon {
                    color: #000000;
                    font-size: 18px;
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    cursor: pointer;
                }

                .notification-each-date {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 12px;
                    font-weight: 500;
                    color: #7a7878;
                    margin-top: 4px;

                }
            }


        }
    }


}

.frequest-section {
    padding: 15px !important;

    .notification-info {
        background-color: #F3F3F3;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-shrink: 0;
        border-radius: 4px;
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        // cursor: pointer;
        color: #000000;
    }

    .frequest-header {
        font-size: 14px;
        font-weight: 500;
    }

    li {
        padding-left: 25px;
        padding-right: 10px;
        border-bottom: 1px solid #E5E5E5;
        position: relative;


        .frequest-dot {
            position: absolute;
            top: 12px;
            left: 10px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #1768AC;
        }
    }

    .frequest-list {
        .frequest-details {
            font-size: 14px;
            color: #343434;
            line-height: 1.3;

            span {
                color: #1768AC;
            }
        }

        .frequest-date {
            font-size: 12px;
            color: #7A7878;
        }

        .frequest-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #ddd;
            flex-shrink: 0;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .up-arrow-request {
                position: absolute;
                left: 3px;
                bottom: -3px;
                width: 35px;
                
            }

            .down-arrow-request {
                position: absolute;
                left: -3px;
                bottom: 2px;
                width: 30px;
                
            }
        }
    }

}

.noti-info-area {
    padding: 15px;

    .noti-info-dot {
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }
}

.popup-arrow.p-overlaypanel {
    &::after {
        left: unset !important;
        right: 7px !important;
    }

    &::before {
        left: unset !important;
        right: 5px !important;
    }
}


.popup-arrow.p-overlaypanel::before,
.popup-arrow.p-overlaypanel::after {
    left: 25px !important;
    right: unset !important;

}

// .popup-arrow.p-overlaypanel:after{
//     border-bottom-color: #f3f3f3;
// }


.MuiDrawer-paper {
    position: relative !important;
}

.set-up-profile {
    position: relative;
    background: rgba(23, 104, 172, 0.12);
    border-radius: 10px;
    padding: 15px 20px;
    margin-bottom: 10px;
    overflow: hidden;

    .prof-detail {
        position: relative;
        z-index: 3;
    }

    .prof-text {
        font-size: 18px;
        font-weight: 500;
    }


    .prof-bg-img1 {
        position: absolute;
        z-index: 2;
        bottom: -70px;
        left: -70px;

        img {
            width: 100%;
        }
    }

    .prof-bg-img2 {
        position: absolute;
        z-index: 2;
        top: -20px;
        bottom: -20px;
        left: 23%;

        img {
            width: 100%;
        }
    }

    .prof-bg-img3 {
        position: absolute;
        z-index: 2;
        top: -70px;
        left: 40%;

        img {
            width: 100%;
        }
    }

    .prof-bg-img4 {
        position: absolute;
        z-index: 2;
        top: -70px;
        right: 28%;

        img {
            width: 100%;
        }
    }

    .prof-bg-img5 {
        position: absolute;
        z-index: 2;
        bottom: -100px;
        right: 15%;

        img {
            width: 100%;
        }
    }

    .prof-bg-img6 {
        position: absolute;
        z-index: 2;
        bottom: -100px;
        right: -70px;

        img {
            width: 100%;
        }
    }
}